/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the context for the currently selected form.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a context.
 */
import * as React from 'react';
import { FormContext, FormDefinitionContext, FormTypeContext, FormDefinitionSingleResponse, IDataSingleResponse, LookupCollectionResponse, LookupsContext, RouteLoading, useFormDefinitionByCode, useLookupsByFormDefinitionId, FormTypeSingleResponse, useFormTypeById, FormDefinitionCollectionResponse, FormTypeCollectionResponse, useFormDefinitions, useFormTypes, FormTypesContext, FormDefinitionsContext } from '@ngt/forms';
import { IPISignOffForm, PISignOffDefinitionSingleResponse, PISignOffSingleResponse, InvestigatorSingleResponse, PISignOffBatchSingleResponse, PISignOffValidationCollectionResponse, InvestigatorCollectionResponse } from '../../api/dtos';
import { SWRConfiguration } from 'swr/dist/types';
import {
    CoordinatingGroupContext,
    CoordinatingGroupSingleResponse,
    CountryContext,
    CountrySingleResponse,
    InstitutionContext,
    InstitutionSingleResponse,
    MasterGroupSingleResponse,
    PatientContext,
    PatientSingleResponse,
    useCoordinatingGroupById,
    useCountryById,
    useInstitutionById,
    useMasterGroupById,
    usePatientByStudyNumber,
    MasterGroupContext
} from '@ngt/forms-trials';
import useInvestigatorById from '../../hooks/data/useInvestigatorById';
import useInvestigators from '../../hooks/data/useInvestigators';
import usePISignOffFormByPISignOffIdAndRepeat from '../../hooks/data/usePISignOffFormByPISignOffIdAndRepeat';
import usePISignOffByPISignOffBatchIdAndPatientId from '../../hooks/data/usePISignOffByPISignOffBatchIdAndPatientId';
import { useNavigate } from 'react-router-dom';
import usePISignOffDefinitionByCode from '../../hooks/configuration/usePISignOffDefinitionByCode';
import PISignOffBatchContext from '../../contexts/data/PISignOffBatchContext';
import PISignOffContext from '../../contexts/data/PISignOffContext';
import InvestigatorContext from '../../contexts/data/InvestigatorContext';
import InvestigatorsContext from '../../contexts/data/InvestigatorsContext';
import PISignOffDefinitionContext from '../../contexts/configuration/PISignOffDefinitionContext';
import PISignOffValidationsContext from '../../contexts/data/PISignOffValidationsContext';
import { IPISignOffFormMapping } from '../../utilities/IPISignOffFormMapping';
import { useMemo } from 'react';
import usePISignOffBatchById from '../../hooks/data/usePISignOffBatchById';
import usePISignOffBatchByPISignOffDefinitionIdAndRepeat from '../../hooks/data/usePISignOffBatchByPISignOffDefinitionIdAndRepeat';
import usePISignOffFormValidationByPISignOffId from '../../hooks/data/usePISignOffFormValidationByPISignOffId';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */


/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

export interface IPISignOffResolverProps {
    piSignOffDefinitionCode: string | null;
    piSignOffRepeat: number | null;
    patientStudyNumber: string;
    resolveBeforeLoad?: boolean | null;

    patientConfiguration?: Partial<SWRConfiguration<PatientSingleResponse, PatientSingleResponse>>;
    institutionConfiguration?: Partial<SWRConfiguration<InstitutionSingleResponse, InstitutionSingleResponse>>;
    countryConfiguration?: Partial<SWRConfiguration<CountrySingleResponse, CountrySingleResponse>>;
    coordinatingGroupConfiguration?: Partial<SWRConfiguration<CoordinatingGroupSingleResponse, CoordinatingGroupSingleResponse>>;
    masterGroupConfiguration?: Partial<SWRConfiguration<MasterGroupSingleResponse, MasterGroupSingleResponse>>;
    piSignOffBatchConfiguration?: Partial<SWRConfiguration<PISignOffBatchSingleResponse, PISignOffBatchSingleResponse>>;
    piSignOffConfiguration?: Partial<SWRConfiguration<PISignOffSingleResponse, PISignOffSingleResponse>>;
    formDefinitionsConfiguration?: Partial<SWRConfiguration<FormDefinitionCollectionResponse, FormDefinitionCollectionResponse>>;
    formTypesConfiguration?: Partial<SWRConfiguration<FormTypeCollectionResponse, FormTypeCollectionResponse>>;
    investigatorConfiguration?: Partial<SWRConfiguration<InvestigatorSingleResponse, InvestigatorSingleResponse>>;
    piSignOffDefinitionConfiguration?: Partial<SWRConfiguration<PISignOffDefinitionSingleResponse, PISignOffDefinitionSingleResponse>>;
    piSignOffFormValidationsConfiguration?: Partial<SWRConfiguration<PISignOffValidationCollectionResponse, PISignOffValidationCollectionResponse>>;
    investigatorsConfiguration?: Partial<SWRConfiguration<InvestigatorCollectionResponse, InvestigatorCollectionResponse>>;
}
/*
 * ---------------------------------------------------------------------------------
 * Constants
 * ---------------------------------------------------------------------------------
 */

const PISignOffResolver: React.FunctionComponent<IPISignOffResolverProps> = ({
    piSignOffDefinitionCode,
    piSignOffRepeat,
    patientStudyNumber,
    resolveBeforeLoad,
    children,

    patientConfiguration,
    institutionConfiguration,
    countryConfiguration,
    coordinatingGroupConfiguration,
    masterGroupConfiguration,
    piSignOffBatchConfiguration,
    piSignOffConfiguration,
    piSignOffDefinitionConfiguration,
    investigatorConfiguration,
    formDefinitionsConfiguration,
    formTypesConfiguration,
    piSignOffFormValidationsConfiguration,
    investigatorsConfiguration
}) => {

    const navigate = useNavigate();

    const piSignOffDefinitionContext = usePISignOffDefinitionByCode(piSignOffDefinitionCode ?? undefined, piSignOffDefinitionConfiguration);

    const formDefinitionsContext = useFormDefinitions(formDefinitionsConfiguration);
    const formTypesContext = useFormTypes(formTypesConfiguration);

    const patientContext = usePatientByStudyNumber(patientStudyNumber, undefined, undefined, patientConfiguration);

    const institutionContext = useInstitutionById(patientContext?.data?.institutionId, undefined, undefined, institutionConfiguration);

    const countryContext = useCountryById(institutionContext?.data?.countryId, undefined, undefined, countryConfiguration);

    const coordinatingGroupContext = useCoordinatingGroupById(institutionContext?.data?.coordinatingGroupId, undefined, undefined, coordinatingGroupConfiguration);

    const masterGroupContext = useMasterGroupById(institutionContext?.data?.masterGroupId, undefined, undefined, masterGroupConfiguration);

    const piSignOffBatchContext = usePISignOffBatchByPISignOffDefinitionIdAndRepeat(piSignOffDefinitionContext?.data?.id, piSignOffRepeat ?? undefined, undefined, undefined, piSignOffBatchConfiguration);

    const piSignOffContext = usePISignOffByPISignOffBatchIdAndPatientId(piSignOffBatchContext.data?.id, patientContext?.data?.id, undefined, undefined, piSignOffConfiguration);


    const piSignOffFormValidationsContext = usePISignOffFormValidationByPISignOffId(piSignOffContext?.data?.id, undefined, piSignOffFormValidationsConfiguration);

    const investigatorContext = useInvestigatorById(piSignOffContext?.data?.investigatorId, undefined, undefined, investigatorConfiguration);
    const investigatorsContext = useInvestigators(investigatorsConfiguration);

    const loading =
        (formDefinitionsContext?.loading) ||
        (formTypesContext?.loading) ||
        (patientStudyNumber && patientContext?.loading) ||
        (patientContext?.data?.id && institutionContext?.loading) ||
        (institutionContext?.data?.countryId && countryContext?.loading) ||
        (institutionContext?.data?.coordinatingGroupId && coordinatingGroupContext?.loading) ||
        (institutionContext?.data?.masterGroupId && masterGroupContext?.loading) ||
        (piSignOffBatchContext.data?.id && piSignOffRepeat && piSignOffBatchContext?.loading) ||
        (piSignOffBatchContext?.data?.id && patientContext?.data?.id && piSignOffContext?.loading) ||
        (piSignOffDefinitionCode && piSignOffDefinitionContext?.loading) ||
        (piSignOffContext?.data?.investigatorId && investigatorContext?.loading) ||
        (investigatorsContext?.loading) ||
        (piSignOffContext?.data?.id && piSignOffFormValidationsContext?.loading);

    const hasError = !!(
        !piSignOffRepeat ||
        !piSignOffDefinitionCode ||
        formDefinitionsContext?.error ||
        formTypesContext?.error ||
        patientContext?.error ||
        institutionContext?.error ||
        countryContext?.error ||
        coordinatingGroupContext?.error ||
        masterGroupContext?.error ||
        piSignOffBatchContext?.error ||
        piSignOffContext?.error ||
        piSignOffDefinitionContext?.error ||
        investigatorContext?.error ||
        investigatorsContext?.error ||
        piSignOffFormValidationsContext?.error
    );

    if (resolveBeforeLoad && hasError) {
        navigate("/error");
    }

    return (
        <MasterGroupContext.Provider value= { masterGroupContext } >
            <CoordinatingGroupContext.Provider value={ coordinatingGroupContext }>
                <CountryContext.Provider value={ countryContext }>
                    <InstitutionContext.Provider value={ institutionContext }>
                        <PatientContext.Provider value={patientContext}>
                            <PISignOffBatchContext.Provider value={piSignOffBatchContext}>
                                <PISignOffContext.Provider value={ piSignOffContext }>
                                    <PISignOffDefinitionContext.Provider value={ piSignOffDefinitionContext }>
                                        <FormTypesContext.Provider value={ formTypesContext }>
                                            <FormDefinitionsContext.Provider value={ formDefinitionsContext }>
                                                <PISignOffValidationsContext.Provider value={piSignOffFormValidationsContext}>
                                                    <InvestigatorContext.Provider value={investigatorContext}>
                                                        <InvestigatorsContext.Provider value={investigatorsContext}>
                                                            {
                                                                !resolveBeforeLoad || !loading ?
                                                                    children :
                                                                    <RouteLoading />
                                                            }
                                                        </InvestigatorsContext.Provider>
                                                    </InvestigatorContext.Provider>
                                                </PISignOffValidationsContext.Provider>
                                            </FormDefinitionsContext.Provider>
                                        </FormTypesContext.Provider>
                                    </PISignOffDefinitionContext.Provider>
                                </PISignOffContext.Provider>
                            </PISignOffBatchContext.Provider>
                        </PatientContext.Provider>
                    </InstitutionContext.Provider>
                </CountryContext.Provider>
            </CoordinatingGroupContext.Provider>
        </MasterGroupContext.Provider>
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default PISignOffResolver;
