import { QueryStatus } from "../api/dtos";

// export const convertQueryStatusToString - function that takes a QueryStatus parameter (if provided) and returns a string representation of it.
export const convertQueryStatusToString = (status?: QueryStatus) => {
    // Set a default return value for an exceptional case of undefined rather than a QueryStatus enum type
    if (!status) {
        return 'Unknown';
    }

    // Return the explicitly set string depending on which of the QueryStatus is passed
    switch (status) {
        case QueryStatus.Issued:
            return 'Issued';
        case QueryStatus.Responded:
            return 'Responded';
        case QueryStatus.Closed:
            return 'Closed';
        case QueryStatus.Cancelled:
            return 'Cancelled';
        default:
            return 'Unknown';
    }
}
