import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import React, { FunctionComponent, useCallback } from 'react';
import { Form, IFormLabel, GroupedField, TextDisplay } from '@ngt/forms';
import { IPISignOffDefinition, IPISignOffBatch } from '../../api/dtos';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { BasicAlert, IInstitution, IPatient } from '@ngt/forms-trials';
import { LoadingButton } from '@mui/lab';

interface ICompletePISignOffDialogBatchProps {
    piSignOffBatch?: IPISignOffBatch;
    open: boolean;
    pending: boolean;
    onClose: () => void;
    onSubmit: () => Promise<void>;
}

const useStyles = makeStyles((theme: Theme) => ({
    content: {
        padding: 0
    }
}));

const groupStyleProps = {
    xs: 12,
    sm: 12,
    md: 7,
    lg: 7,
    xl: 7
};

const CompletePISignOffBatchDialog: FunctionComponent<ICompletePISignOffDialogBatchProps> = ({
    open,
    onClose,
    onSubmit,
    piSignOffBatch,
    pending
}) => {
    const classes = useStyles();

    const handleSubmit = useCallback(async () => {
        await onSubmit();
        onClose();
    }, [onClose, onSubmit])

    return (
        <Dialog open={open} onClose={onClose} scroll="body">
            <DialogTitle>
                Complete PI Sign-Off
            </DialogTitle>
            <DialogContent className={classes.content} dividers>

                <BasicAlert
                    title="Please Note"
                    description={
                        <>
                            <p>If, for whatever reason the BCT wishes to close the current sign-off before each patient has been signed off, then there are two options:</p>
                            <ol type="a">
                                <li>
                                    Select “Complete PI sign-off” button
                                    <ul>
                                        <li>Each patient that is not complete will be marked as “closed”.</li>
                                        <li>The PI sign-off itself will be marked as “complete”.</li>
                                    </ul>
                                </li>
                                <li>
                                    Select “Close PI sign-off” button
                                    <ul>
                                        <li>Each patient that is not complete will be marked as “closed”.</li>
                                        <li>The PI sign-off itself will be marked as “closed”.  This option will treat the sign-off like it was started but closed and never finished.</li>
                                    </ul>
                                </li>
                            </ol>
                            <p>
                                In either case, if an individual patient sign off has been completed, it will remain as completed within that sign-off.  
                            </p>
                            <p>
                                When a new sign-off is created in the future, only patients with previously completed state in a completed sign-off will be used for comparing the current data to previously signed data.
                            </p>
                        </>
                    }
                    icon={faInfoCircle}
                    severity="info"
                />
            </DialogContent>
            <DialogActions>
                <LoadingButton onClick={onClose} color="secondary" loading={pending}>
                    Cancel
                </LoadingButton>
                <LoadingButton onClick={handleSubmit} color="primary" loading={pending}>
                    Complete PI Sign-Off
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};

export default CompletePISignOffBatchDialog;
