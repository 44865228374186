/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the component that provides the basis for all routes.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a context.
 */
import * as React from 'react';

/*
 * Used to type properties and get access to routes. 
 */
import { RouteProps } from 'react-router';


/*
 * Used to get access to application routing based on paths.
 */
import { Route, useParams } from 'react-router-dom';
import { SWRConfiguration } from 'swr/dist/types';
import { IPISignOffForm, PISignOffDefinitionSingleResponse, PISignOffBatchSingleResponse, PISignOffCollectionResponse, InvestigatorCollectionResponse } from '../../api/dtos';
import PISignOffExtensionContext from '../../contexts/PISignOffExtensionContext';
import { useContext } from 'react';
import { CoordinatingGroupCollectionResponse, CountryCollectionResponse, InstitutionCollectionResponse, MasterGroupCollectionResponse,  PatientCollectionResponse} from '@ngt/forms-trials';
import PISignOffBatchResolver from '../resolver/PISignOffBatchResolver';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */


/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

/**
 * This interface defines the properties for the OpmsFormByIdRoute component.
 */
export interface IPISignOffBatchRouteProps {
    piSignOffDefinitionCode?: string;
    piSignOffRepeat?: number;

    /**
     * This property determines whether the associated data should be loaded before loading the route.
     */
    resolveBeforeLoad?: boolean | null;

    patientsConfiguration?: Partial<SWRConfiguration<PatientCollectionResponse, PatientCollectionResponse>>;
    institutionsConfiguration?: Partial<SWRConfiguration<InstitutionCollectionResponse, InstitutionCollectionResponse>>;
    countriesConfiguration?: Partial<SWRConfiguration<CountryCollectionResponse, CountryCollectionResponse>>;
    coordinatingGroupsConfiguration?: Partial<SWRConfiguration<CoordinatingGroupCollectionResponse, CoordinatingGroupCollectionResponse>>;
    masterGroupsConfiguration?: Partial<SWRConfiguration<MasterGroupCollectionResponse, MasterGroupCollectionResponse>>;
    piSignOffBatchConfiguration?: Partial<SWRConfiguration<PISignOffBatchSingleResponse, PISignOffBatchSingleResponse>>;
    piSignOffsConfiguration?: Partial<SWRConfiguration<PISignOffCollectionResponse, PISignOffCollectionResponse>>;
    piSignOffDefinitionConfiguration?: Partial<SWRConfiguration<PISignOffDefinitionSingleResponse, PISignOffDefinitionSingleResponse>>;
    investigatorsConfiguration?: Partial<SWRConfiguration<InvestigatorCollectionResponse, InvestigatorCollectionResponse>>;
}

/*
 * ---------------------------------------------------------------------------------
 * Components
 * ---------------------------------------------------------------------------------
 */

/**
 * This component provides context for the form management system.
 * @param param0 component properties.
 */
const PISignOffBatchRoute: React.FunctionComponent<IPISignOffBatchRouteProps> = ({
    piSignOffDefinitionCode,
    piSignOffRepeat,
    resolveBeforeLoad,
    children,

    patientsConfiguration,
    institutionsConfiguration,
    countriesConfiguration,
    coordinatingGroupsConfiguration,
    masterGroupsConfiguration,
    piSignOffBatchConfiguration,
    piSignOffsConfiguration,
    piSignOffDefinitionConfiguration,
    investigatorsConfiguration,

    ...routeProps
}) => {
    const params = useParams<Record<string, string>>();
    const piSignOffExtensionContext = useContext(PISignOffExtensionContext);
    const paramPISignOffDefinitionCode = params[piSignOffExtensionContext.routeParameters.piSignOffDefinitionCode];
    const paramPISignOffRepeatStr = params[piSignOffExtensionContext.routeParameters.piSignOffRepeat];
    let paramPISignOffRepeat = null;


    try {
        if (paramPISignOffRepeatStr) {
            paramPISignOffRepeat = parseInt(paramPISignOffRepeatStr);
        }
    }
    catch (error) {
        console.error(`Invalid PI Sign-Off Repeat in route: ${paramPISignOffRepeatStr}`)
    }

    return (
        <PISignOffBatchResolver
            piSignOffDefinitionCode={paramPISignOffDefinitionCode ?? piSignOffDefinitionCode ?? null}
            piSignOffRepeat={paramPISignOffRepeat ?? piSignOffRepeat ?? null}
            resolveBeforeLoad={resolveBeforeLoad}
            countriesConfiguration={countriesConfiguration}
            coordinatingGroupsConfiguration={coordinatingGroupsConfiguration}
            institutionsConfiguration={institutionsConfiguration}
            masterGroupsConfiguration={masterGroupsConfiguration}
            patientsConfiguration={patientsConfiguration}
            piSignOffsConfiguration={piSignOffsConfiguration}
            piSignOffBatchConfiguration={piSignOffBatchConfiguration}
            piSignOffDefinitionConfiguration={piSignOffDefinitionConfiguration}
            investigatorsConfiguration={investigatorsConfiguration}
        >
            {children}
        </PISignOffBatchResolver>
    );
}

/*
    * ---------------------------------------------------------------------------------
    * Default Export
    * ---------------------------------------------------------------------------------
    */

export default PISignOffBatchRoute;