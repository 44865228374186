/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/*
 * Required to use React components.
 */
import * as React from 'react';


/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { IPISignOff, IPISignOffDefinition, IPISignOffPatient, IInvestigator } from '../../api/dtos';
import { IDtoClass, Condition as ConditionBase, IConditionProps as IConditionBaseProps, IConditionParameters as IConditionBaseParameters, IFormDefinition, IFormType, FormDefinitionContext, FormTypeContext} from '@ngt/forms';
import { useContext } from 'react';
import PISignOffContext from '../../contexts/data/PISignOffContext';
import InvestigatorContext from '../../contexts/data/InvestigatorContext';
import PISignOffDefinitionContext from '../../contexts/configuration/PISignOffDefinitionContext';
import { CoordinatingGroupContext, CountryContext, ICoordinatingGroup, ICountry, IInstitution, IMasterGroup, InstitutionContext, MasterGroupContext, PatientContext } from '@ngt/forms-trials';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

type OmitCondition<T> = Omit<T, 'condition'>


export interface IConditionParameters<TValues extends object = any> extends IConditionBaseParameters<TValues> {
    patient?: IPISignOffPatient | null;
    institution?: IInstitution | null;
    country?: ICountry | null;
    coordinatingGroup?: ICoordinatingGroup | null;
    masterGroup?: IMasterGroup | null;
    piSignOff?: IPISignOff | null;
    formDefinition?: IFormDefinition | null;
    reviewer?: IInvestigator | null;
    piSignOffDefinition?: IPISignOffDefinition | null;
    formType?: IFormType | null;
}

export interface ICondition<TValues extends object = any> {
    (params: IConditionParameters<TValues>): boolean
}

/**
 * This interface defines the properties for the Condition component.
 */
export interface IConditionProps<TValues extends Object = any> extends OmitCondition<IConditionBaseProps<TValues>> {
    type: IDtoClass<TValues>;
    condition: (params: IConditionParameters<TValues>) => boolean
}

/*
 * ---------------------------------------------------------------------------------
 * Components
 * ---------------------------------------------------------------------------------
 */

/**
 * This component provides context for the patient management system.
 * @param param0 component properties.
 */
const Condition = <TValues extends Object = any>({
    condition,
    children,
    ...rest
}: IConditionProps<TValues>) => {

    const { data: patient } = useContext(PatientContext);
    const { data: institution } = useContext(InstitutionContext);
    const { data: country } = useContext(CountryContext);
    const { data: masterGroup } = useContext(MasterGroupContext);
    const { data: coordinatingGroup } = useContext(CoordinatingGroupContext);
    const { data: piSignOff } = useContext(PISignOffContext);
    const { data: formDefinition } = useContext(FormDefinitionContext);
    const { data: reviewer } = useContext(InvestigatorContext);
    const { data: piSignOffDefinition } = useContext(PISignOffDefinitionContext);
    const { data: formType } = useContext(FormTypeContext);

    const formCondition: IConditionBaseProps<TValues>["condition"] = React.useCallback((params) => {
        return condition({
            ...params,
            patient: patient as IPISignOffPatient | null,
            institution,
            country,
            masterGroup,
            coordinatingGroup,
            piSignOff,
            formDefinition,
            reviewer,
            piSignOffDefinition,
            formType
        });
    }, [condition,
        patient,
        institution,
        country,
        masterGroup,
        coordinatingGroup,
        piSignOff,
        formDefinition,
        reviewer,
        piSignOffDefinition,
        formType ])

    return (
        <ConditionBase
            {...rest}
            condition={formCondition}
        >
            {children}
        </ConditionBase>
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default Condition;