import React, { FunctionComponent, useCallback, useContext } from 'react';
import {  FormDefinitionsContext, FormErrorHandler, FormTypesContext, GroupedFields, IFormDefinition, IFormType, InputOnlyField } from '@ngt/forms';
import PISignOffContext from '../../contexts/data/PISignOffContext';
import { IQuery, PISignOffPermission, PISignOffStatus, QueryStatus } from '../../api/dtos';
import QueryGrid from './QueryGrid';
import {  FormContext, FormErrorBoundaryMode, useScopedField } from '@ngt/forms-core';
import { Accordion, AccordionDetails, AccordionSummary, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/pro-duotone-svg-icons';
import CountDisplay from '../form/input/display/CountDisplay';
import useContextPermissions from '../../hooks/utility/useContextPermissions';


interface IContextFormQueryGridProps {
    headerRows?: boolean;
    name: string;
    FieldProps?: {
        errorMode?: FormErrorBoundaryMode;
    };
}

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        "&.MuiAccordion-root:before": {
            padding: theme.spacing(0, 2),
            margin: theme.spacing(0, 2),
            backgroundColor: 'rgba(0, 0, 0, 0.12)'
         },
        '&.Mui-expanded': {
            margin: theme.spacing(2, 0)
        }
    },
    summary: {
        '& > .MuiAccordionSummary-content': {
            justifyContent: 'space-between'
        },

        backgroundColor: theme.palette.primary.main,
        margin: theme.spacing(0, 2),
        color: 'white'
    },
    subtitle: {
        color: 'white'
    },
    content: {
        display: 'block',
        padding: theme.spacing(0),
        margin: theme.spacing(0, 2),
    }
}));

const subscription = { value: true };

const piSignOffStatusSubscription = {};

const ContextFormQueryGrid: FunctionComponent<IContextFormQueryGridProps> = ({
    headerRows,
    name,
    FieldProps: { errorMode } = {}
}) => {
    const classes = useStyles();

    const { state: { value: queries }, context: { setValue: saveQueries } } = useScopedField<IQuery[]>(name, subscription);
    const { context: { setValue: saveStatus } } = useScopedField<PISignOffStatus>('piSignOffStatus', piSignOffStatusSubscription);
    const { context: { setValue: saveIsDataChangeRequest } } = useScopedField<boolean>('isDataChangeRequest', piSignOffStatusSubscription);
    const { context: { setValue: saveDataChangeRequestType } } = useScopedField<QueryStatus>('dataChangeRequestType', piSignOffStatusSubscription);
    const { data: formTypes } = useContext(FormTypesContext);
    const { data: piSignOff } = useContext(PISignOffContext);
    const { data: formDefinitions } = useContext(FormDefinitionsContext);
    const { submit, reset } = useContext(FormContext) ?? {}

    const { data: [canView, canIssue, canRespond, canClose, canCancel] } = useContextPermissions([PISignOffPermission.ViewQuery, PISignOffPermission.IssueQuery, PISignOffPermission.RespondToQuery, PISignOffPermission.CloseQuery, PISignOffPermission.CancelQuery]);


    const onIssue = useCallback(async (relatedQuery: IQuery | undefined, query: IQuery, formType: IFormType, formDefinition: IFormDefinition) => {
        
        const newQueries = [...(queries ?? [])];

        if (relatedQuery) {
            const index = newQueries.indexOf(relatedQuery);

            newQueries[index] = { ...relatedQuery, status: QueryStatus.Closed };

            const newIndex = index + 1;
            if (newIndex < 0 || newIndex > newQueries.length - 1) {
                newQueries.push(query);
            }
            else {
                newQueries.splice(newIndex, 0, query);
            }
        }
        else {
            newQueries.push(query);
        }

        saveQueries(newQueries);

        saveStatus(PISignOffStatus.InProgress);
        
        saveDataChangeRequestType(QueryStatus.Issued);
        saveIsDataChangeRequest(true);

        await submit?.();
    }, [queries, saveQueries, submit, saveStatus]);

    const onRespond = useCallback(async (relatedQuery: IQuery | undefined, query: IQuery, formType: IFormType, formDefinition: IFormDefinition) => {
        if (relatedQuery) {
            const newQueries = [...(queries ?? [])];

            const index = newQueries.indexOf(relatedQuery);

            newQueries[index] = query;

            await saveQueries(newQueries);

            saveStatus(PISignOffStatus.InProgress);
            
            saveDataChangeRequestType(QueryStatus.Responded);
            saveIsDataChangeRequest(true);

            await submit?.();
        }
    }, [queries, saveQueries, submit, saveStatus]);


    const onClose = useCallback(async (relatedQuery: IQuery | undefined, query: IQuery, formType: IFormType, formDefinition: IFormDefinition) => {
        if (relatedQuery) {
            const newQueries = [...(queries ?? [])];

            const index = newQueries.indexOf(relatedQuery);

            newQueries[index] = query;

            await saveQueries(newQueries);

            saveStatus(PISignOffStatus.InProgress);

            saveDataChangeRequestType(QueryStatus.Closed);
            saveIsDataChangeRequest(true);

            await submit?.();
        }
    }, [queries, saveQueries, submit, saveStatus]);

    const onCancel = useCallback(async (relatedQuery: IQuery | undefined, query: IQuery, formType: IFormType, formDefinition: IFormDefinition) => {
        if (relatedQuery) {
            const newQueries = [...(queries ?? [])];

            const index = newQueries.indexOf(relatedQuery);

            newQueries[index] = query;

            await saveQueries(newQueries);

            saveStatus(PISignOffStatus.InProgress);

            saveDataChangeRequestType(QueryStatus.Cancelled);
            saveIsDataChangeRequest(true);

            await submit?.();
        }
    }, [queries, saveQueries, submit, saveStatus]);


    const onFailure = useCallback(async (relatedQuery: IQuery | undefined, query: IQuery, formType: IFormType, formDefinition: IFormDefinition) => {
        reset?.();
    }, [reset]);

    return (
        <FormErrorHandler
            errorMode={errorMode}
        >
            <Accordion
                elevation={0}
                classes={{
                    root: classes.container
                }}
            >
                <AccordionSummary
                    expandIcon={<FontAwesomeIcon fixedWidth icon={faChevronDown} color="white"/>}
                    className={classes.summary}
                >
                    <Typography variant="h2" className={classes.subtitle}>
                        Data Change Requests
                    </Typography>
                    <InputOnlyField
                        name={name}
                        component={CountDisplay}
                        entity="Data Change Request"
                    />
                </AccordionSummary>
                <AccordionDetails className={classes.content}>
                    <QueryGrid
                        disabled={piSignOff?.status === PISignOffStatus.Cancelled || piSignOff?.status === PISignOffStatus.Complete}
                        onIssue={onIssue}
                        onRespond={onRespond}
                        onClose={onClose}
                        onCancel={onCancel}
                        piSignOff={piSignOff!}
                        queries={queries ?? []}
                        formTypes={formTypes ?? []}
                        formDefinitions={formDefinitions ?? []}
                        headerRows={headerRows}
                        onFailure={onFailure}
                        hideSection
                        hideReport
                        allowInsert
                        canView={canView}
                        canIssue={canIssue}
                        canRespond={canRespond}
                        canClose={canClose}
                        canCancel={canCancel}
                    />
                </AccordionDetails>
            </Accordion>
        </FormErrorHandler>
    )
};

export default ContextFormQueryGrid;
