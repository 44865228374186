import { PISignOffStatus } from "../api/dtos";

export const convertPISignOffStatusToString = (status?: PISignOffStatus) => {
    if (!status) {
        return 'No Reviews';
    }

    switch (status) {
        case PISignOffStatus.New:
            return 'New';
        case PISignOffStatus.InProgress:
            return 'In Progress';
        case PISignOffStatus.Complete:
            return 'Complete';
        case PISignOffStatus.Cancelled:
            return 'Cancelled';
        case PISignOffStatus.Pending:
            return 'Pending';
        default:
            return 'Unknown';
    }
}

export const convertPISignOffBatchStatusToString = (status?: PISignOffStatus) => {
    if (!status) {
        return 'No Reviews';
    }

    switch (status) {
        case PISignOffStatus.New:
            return 'New';
        case PISignOffStatus.InProgress:
            return 'In Progress';
        case PISignOffStatus.Complete:
            return 'Complete';
        case PISignOffStatus.Cancelled:
            return 'Closed';
        case PISignOffStatus.Pending:
            return 'Pending';
        default:
            return 'Unknown';
    }
}