import React, { FunctionComponent, useCallback, useContext } from 'react';
import { FormDefinitionsContext, FormTypesContext, IFormDefinition, IFormType } from '@ngt/forms';
import PISignOffContext from '../../contexts/data/PISignOffContext';
import { IQuery, PISignOffPermission, PISignOffStatus, QueryStatus } from '../../api/dtos';
import QueryGrid from './QueryGrid';
import QueriesContext from '../../contexts/data/QueriesContext';
import useContextPermissions from '../../hooks/utility/useContextPermissions';

interface IContextQueryGridProps {
    headerRows?: boolean;
}

const ContextQueryGrid: FunctionComponent<IContextQueryGridProps> = ({
    headerRows
}) => {
    const { data: queries, save: saveQueries } = useContext(QueriesContext);
    const { data: formTypes } = useContext(FormTypesContext);
    const { data: piSignOff } = useContext(PISignOffContext);
    const { data: formDefinitions } = useContext(FormDefinitionsContext);

    const { data: [canView, canIssue, canRespond, canClose, canCancel] } = useContextPermissions([PISignOffPermission.ViewQuery, PISignOffPermission.IssueQuery, PISignOffPermission.RespondToQuery, PISignOffPermission.CloseQuery, PISignOffPermission.CancelQuery]);



    const onIssue = useCallback(async (relatedQuery: IQuery | undefined, query: IQuery, formType: IFormType, formDefinition: IFormDefinition) => {

        const newQueries = [...(queries ?? [])];

        if (relatedQuery) {
            const index = newQueries.indexOf(relatedQuery);

            newQueries[index] = { ...relatedQuery, status: QueryStatus.Closed };

            const newIndex = index + 1;
            if (newIndex < 0 || newIndex > newQueries.length - 1) {
                newQueries.push(query);
            }
            else {
                newQueries.splice(newIndex, 0, query);
            }
        }
        else {
            newQueries.push(query);
        }

        await saveQueries(newQueries);
    }, [queries, saveQueries]);

    const onRespond = useCallback(async (relatedQuery: IQuery | undefined, query: IQuery, formType: IFormType, formDefinition: IFormDefinition) => {
        if (relatedQuery) {
            const newQueries = [...(queries ?? [])];

            const index = newQueries.indexOf(relatedQuery);

            newQueries[index] = query;

            await saveQueries(newQueries);
        }
    }, [queries, saveQueries]);


    const onClose = useCallback(async (relatedQuery: IQuery | undefined, query: IQuery, formType: IFormType, formDefinition: IFormDefinition) => {
        if (relatedQuery) {
            const newQueries = [...(queries ?? [])];

            const index = newQueries.indexOf(relatedQuery);

            newQueries[index] = query;

            await saveQueries(newQueries);
        }
    }, [queries, saveQueries]);

    return (
        <QueryGrid
            disabled={piSignOff?.status === PISignOffStatus.Cancelled || piSignOff?.status === PISignOffStatus.Complete}
            onIssue={onIssue}
            onRespond={onRespond}
            onClose={onClose}
            onCancel={onClose}
            piSignOff={piSignOff!}
            queries={queries ?? []}
            formTypes={formTypes ?? []}
            formDefinitions={formDefinitions ?? []}
            headerRows={headerRows}
            canView={canView}
            canIssue={canIssue}
            canRespond={canRespond}
            canClose={canClose}
            canCancel={canCancel}
            notifications
        />
    )
};

export default ContextQueryGrid;
