import { useCallback, useContext, useMemo } from 'react';
import useSWR, { SWRConfiguration, mutate } from 'swr';
import { FormsContext, BoundMutator, Forms } from '@ngt/forms';
import { PISignOffPermission, PostHasPermissions, PostHasPermissionsResponse } from '../../api/dtos';


// @ts-ignore: declared but not used 'url'
const fetcher = async (
    url: string,
    forms: Forms,
    masterGroupId?: number,
    coordinatingGroupId?: number,
    countryId?: number,
    institutionId?: number,
    patientId?: number,
    investigatorIds?: string,
    piSignOffId?: number,
    investigatorId?: number,
    formDefinitionId?: number,
    formId?: number,
    fileId?: number,
    queryId?: number,
    ...permissions: PISignOffPermission[]
) => {
    const response = await forms.serviceStackClient.post(new PostHasPermissions({
        context: {
            masterGroupId,
            coordinatingGroupId,
            countryId,
            institutionId,
            patientId,
            investigatorIds: investigatorIds?.split('|')?.map(x => parseInt(x, 10)) ?? [],
            piSignOffId,
            investigatorId,
            formDefinitionId,
            formId,
            fileId,
            queryId
        },
        permissions
    }));

    return response;
}

const usePermissions = (
    permissions: PISignOffPermission[],
    masterGroupId?: number,
    coordinatingGroupId?: number,
    countryId?: number,
    institutionId?: number,
    patientId?: number,
    investigatorIds?: number[],
    piSignOffId?: number,
    investigatorId?: number,
    formDefinitionId?: number,
    formId?: number,
    fileId?: number,
    queryId?: number,
    configuration?: SWRConfiguration<PostHasPermissionsResponse, PostHasPermissionsResponse>
) => {
    const forms = useContext(FormsContext);

    const convertedInvestigatorIds = useMemo(() => {
        if (!investigatorIds) {
            return undefined;
        }

        return investigatorIds.join('|');
    }, [investigatorIds])

    const cacheKey = useMemo(() => {
        if (!permissions) {
            return null;
        }

        return [`${forms.baseRoute}/permissions`, forms, masterGroupId, coordinatingGroupId, countryId, institutionId, patientId, convertedInvestigatorIds, piSignOffId, investigatorId, formDefinitionId, formId, fileId, queryId, ...permissions]
    }, [forms, masterGroupId, coordinatingGroupId, countryId, institutionId, patientId, convertedInvestigatorIds, piSignOffId, investigatorId, formDefinitionId, formId, fileId, queryId, ...permissions]) // eslint-disable-line react-hooks/exhaustive-deps

    const { data, error } = useSWR<PostHasPermissionsResponse, PostHasPermissionsResponse>(cacheKey, fetcher, configuration);

    const boundMutate: BoundMutator<PostHasPermissionsResponse> = useCallback((data, shouldRevalidate) => {
        return mutate(cacheKey, data, shouldRevalidate);
    }, [cacheKey])

    const result = useMemo(() => {
        return {
            data: data?.permissions,
            error: error?.responseStatus,
            loading: data === undefined && error === undefined,
            mutate: boundMutate
        };
    }, [boundMutate, data, error])

    return result;
}

export default usePermissions;