/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the context for the currently selected form.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a context.
 */
import * as React from 'react';
import { 
    FormDefinitionCollectionResponse, 
    RouteLoading, 
    useFormDefinitions,
    FormDefinitionsContext, 
} from '@ngt/forms';
import { PISignOffBatchCollectionResponse, PISignOffDefinitionCollectionResponse } from '../../api/dtos';
import { SWRConfiguration } from 'swr/dist/types';
import { Navigate, useNavigate } from 'react-router-dom';
import PISignOffDefinitionsContext from '../../contexts/configuration/PISignOffDefinitionsContext';
import usePISignOffBatches from '../../hooks/data/usePISignOffBatches';
import usePISignOffDefinitions from '../../hooks/configuration/usePISignOffDefinitions';
import PISignOffBatchesContext from '../../contexts/data/PISignOffBatchesContext';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */


/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

export interface IPISignOffBatchesResolverProps {
    resolveBeforeLoad?: boolean | null;

    piSignOffBatchesConfiguration?: Partial<SWRConfiguration<PISignOffBatchCollectionResponse, PISignOffBatchCollectionResponse>>;
    piSignOffDefinitionsConfiguration?: Partial<SWRConfiguration<PISignOffDefinitionCollectionResponse, PISignOffDefinitionCollectionResponse>>;
    formDefinitionsConfiguration?: Partial<SWRConfiguration<FormDefinitionCollectionResponse, FormDefinitionCollectionResponse>>;
}
/*
 * ---------------------------------------------------------------------------------
 * Constants
 * ---------------------------------------------------------------------------------
 */

const PISignOffFormByPISignOffBatchIdAndPatientStudyNumberAndRepeatResolver: React.FunctionComponent<IPISignOffBatchesResolverProps> = ({
    resolveBeforeLoad,
    children,

    formDefinitionsConfiguration,
    piSignOffBatchesConfiguration,
    piSignOffDefinitionsConfiguration
}) => {

    const formDefinitionsContext = useFormDefinitions(formDefinitionsConfiguration);
    const piSignOffBatchesContext = usePISignOffBatches(piSignOffBatchesConfiguration);
    const piSignOffDefinitionsContext = usePISignOffDefinitions(piSignOffDefinitionsConfiguration);
    const navigate = useNavigate();


    const loading = formDefinitionsContext?.loading || piSignOffBatchesContext?.loading || piSignOffDefinitionsContext?.loading;

    const hasError = !!(formDefinitionsContext?.error || piSignOffBatchesContext?.error || piSignOffDefinitionsContext?.error);

    if (resolveBeforeLoad && hasError) {
        navigate("/error");
    }

    return (
        <PISignOffBatchesContext.Provider value={piSignOffBatchesContext}>
            <PISignOffDefinitionsContext.Provider value={ piSignOffDefinitionsContext }>
                <FormDefinitionsContext.Provider value={ formDefinitionsContext }>
                    {
                        !resolveBeforeLoad || !loading ?
                            children :
                            <RouteLoading />
                    }
                </FormDefinitionsContext.Provider>
            </PISignOffDefinitionsContext.Provider>
        </PISignOffBatchesContext.Provider>
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default PISignOffFormByPISignOffBatchIdAndPatientStudyNumberAndRepeatResolver;
