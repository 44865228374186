import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { FunctionComponent, useCallback, useState } from 'react';
import { Form, IFormLabel, GroupedField, TextDisplay, ProgressButton } from '@ngt/forms';
import { IPISignOffDefinition, IPISignOff } from '../../api/dtos';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { BasicAlert, IInstitution, IPatient } from '@ngt/forms-trials';

interface IUpdatePISignOffFormDialogProps {
    open: boolean;
    onClose: () => void;
    onSubmit: () => Promise<any>;
}

const useStyles = makeStyles((theme: Theme) => ({
    content: {
        padding: 0
    }
}));

const groupStyleProps = {
    xs: 12,
    sm: 12,
    md: 7,
    lg: 7,
    xl: 7
};

const UpdatePISignOffFormDialog: FunctionComponent<IUpdatePISignOffFormDialogProps> = ({
    open,
    onClose,
    onSubmit
}) => {
    const classes = useStyles();

    const [updating, setUpdating] = useState(false);

    const handleSubmit = useCallback(async () => {
        setUpdating(true);
        await onSubmit();
        onClose();
        setUpdating(false);
    }, [onClose, onSubmit, setUpdating])

    return (
        <Dialog open={open} onClose={onClose} scroll="body">
            <DialogTitle>
                Update PI Sign Off Report
            </DialogTitle>
            <DialogContent className={classes.content} dividers>
                <BasicAlert
                    title="Please Note"
                    description={
                        <>
                            <p>Are you sure you wish to update this report?</p>
                        </>
                    }
                    icon={faInfoCircle}
                    severity="info"
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="secondary" disabled={updating}>
                    Cancel
                </Button>
                <ProgressButton onClick={handleSubmit} color="primary" loading={updating}>
                    Update
                </ProgressButton>
            </DialogActions>
        </Dialog>
    );
};

export default UpdatePISignOffFormDialog;
