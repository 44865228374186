import React, { ChangeEvent, FunctionComponent, useCallback, useContext, useMemo, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { CollapsibleTable, useUser, FormDefinitionsContext } from '@ngt/forms';
import { DateTime } from 'luxon';
import { Chip, Grid, TextField, Typography, Button, CircularProgress, Tooltip } from '@mui/material';
import { Autocomplete } from '@mui/lab';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faExclamationTriangle } from '@fortawesome/pro-duotone-svg-icons';
import { parse, stringify } from 'qs';
import { GridColDef, GridRowParams } from '@mui/x-data-grid';
import { makeStyles } from '@mui/styles';
import useInvestigators from '../hooks/data/useInvestigators';
import usePISignOffBatches from '../hooks/data/usePISignOffBatches';
import usePISignOffDefinitions from '../hooks/configuration/usePISignOffDefinitions';
import PISignOffExtensionContext from '../contexts/PISignOffExtensionContext';
import PISignOffBatchesContext from '../contexts/data/PISignOffBatchesContext';
import { IPISignOff, IPISignOffBatch, PISignOffPermission, PISignOffStatus } from '../api/dtos';
import { convertPISignOffBatchStatusToString } from '../utilities/PISignOffStatus';
import CreatePISignOffBatchDialog from '../components/dialogs/CreatePISignOffBatchDialog';
import PISignOffDefinitionsContext from '../contexts/configuration/PISignOffDefinitionsContext';
import useContextPermissions from '../hooks/utility/useContextPermissions';


interface IPISignOffBatchesProps {
    rowsPerPageOptions?: number[];
    initialPageSize?: number;
}

const useStyles = makeStyles((theme: any) => ({
    container: {
        padding: theme.spacing(0, 2),
        paddingBottom: theme.spacing(2)
    },
    title: {
        padding: theme.spacing(2, 0, 1),
        display: 'flex',
        justifyContent: 'space-between'
    },
    createButton: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around'
    },
    cell: {
        fontSize: '1rem',
        cursor: 'pointer',

        '&:focus': {
            outline: 'none !important'
        }
    },
    new: {
        color: theme.palette.info.main
    },
    inProgress: {
        color: theme.palette.warning.main
    },
    complete: {
        color: theme.palette.success.main
    },
    cancelled: {
        color: theme.palette.grey[500]
    },
    pending: {
        color: theme.palette.grey[500]
    },
    unknown: {
        color: theme.palette.error.main
    },
    failed: {
        color: theme.palette.error.main
    }
}));

const xs = 12;
const sm = 12;
const md = 6;
const lg = 4;
const xl = 4;

const PISignOffBatches: FunctionComponent<IPISignOffBatchesProps> = ({
    rowsPerPageOptions,
    initialPageSize
}) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const location = useLocation();

    const { piSignOffColumnsFn, createPISignOffBatchRouteFn } = useContext(PISignOffExtensionContext);

    const user = useUser();
    const { data: piSignOffBatches, loading: piSignOffBatchesLoading } = useContext(PISignOffBatchesContext);
    const { data: piSignOffDefinitions, loading: piSignOffDefinitionsLoading } = useContext(PISignOffDefinitionsContext);
    const { data: formDefinitions } = useContext(FormDefinitionsContext);
    const { data: [canCreatePISignOff] } = useContextPermissions([PISignOffPermission.CreatePISignOff]);

    const getStatusClassName = useCallback((status?: number) => {
        switch (status) {
            case PISignOffStatus.New:
                return classes.new;
            case PISignOffStatus.InProgress:
                return classes.inProgress;
            case PISignOffStatus.Complete:
                return classes.complete;
            case PISignOffStatus.Cancelled:
                return classes.cancelled;
            case PISignOffStatus.Pending:
                return classes.pending;
            case PISignOffStatus.Failed:
                return classes.failed;
            default:
                return classes.unknown;
        }
    }, [classes]);

    const onPISignOffClick = useCallback((params: GridRowParams) => {
        if ((params.row as IPISignOff).status !== PISignOffStatus.Pending) {
            const piSignOffDefinition = piSignOffDefinitions?.find(x => x.id = params.row.piSignOffDefinitionId);

            return navigate(createPISignOffBatchRouteFn(params.row as IPISignOffBatch, piSignOffDefinition!));
        }
    }, [history, createPISignOffBatchRouteFn, piSignOffDefinitions]);

    const signOffColumns: GridColDef[] = useMemo(() => {
        if (!piSignOffColumnsFn) {
            return [
                {
                    field: 'piSignOffDefinitionId',
                    headerName: 'Date Entered',
                    renderCell: params => <>
                            <Typography variant="subtitle1" component="span">{piSignOffDefinitions?.find(definition => definition.id === params.row.piSignOffDefinitionId)?.name} ({params.row.repeat})</Typography>
                            &nbsp;&ndash;&nbsp;
                            <Typography variant="subtitle2" component="span">{params.row.enteredDate ? DateTime.fromISO(params.row.enteredDate).toFormat('dd/MM/yyyy') : undefined}</Typography>
                        </>,
                    width: 800
                },
                {
                    field: 'status',
                    headerName: 'Status',
                    renderCell: params => <>
                        <Typography variant="subtitle2" component="span" className={getStatusClassName(params.row.status)}>
                            {
                                params.row.status === PISignOffStatus.Pending ?
                                    <><CircularProgress size="0.8rem" /> </> :
                                    null
                            }
                            {convertPISignOffBatchStatusToString(params.row.status)}
                            {
                                params.row.status === PISignOffStatus.Pending ?
                                    <> ({params.row.created}/{params.row.count})</> :
                                    null
                            }
                        </Typography>
                    </>,
                    width: 200
                }
            ]
        }

        return piSignOffColumnsFn(user) ?? [];
    }, [user, piSignOffColumnsFn, classes, piSignOffDefinitions]);

    const getClassName = useCallback(() => {
        return classes.cell
    }, [classes.cell]);

    const [createPISignOffBatchDialogOpen, setCreatePISignOffDialogOpen] = useState(false);
    const disableCreation = useMemo(() => {
        if (piSignOffBatchesLoading) {
            return true;
        }

        if (!piSignOffBatches ||
            piSignOffBatches.length === 0) {
                return false;
            }

        if (piSignOffBatches.some(x => x.status !== PISignOffStatus.Complete && x.status !== PISignOffStatus.Cancelled)) {
            return true;
        }

        return false;
    }, [piSignOffBatches, piSignOffBatchesLoading])

    const onCreatePISignOffDialogOpen = useCallback(() => {
        setCreatePISignOffDialogOpen(true);
    }, [setCreatePISignOffDialogOpen])

    const onCreatePISignOffBatchDialogClose = useCallback(() => {
        setCreatePISignOffDialogOpen(false);
    }, [setCreatePISignOffDialogOpen]);

    return (
        <>
            
            <div className={classes.container}>
                <div className={classes.title}>
                    <Typography variant="h1">
                        Principal Investigator Sign-Offs
                    </Typography>
                    {
                        canCreatePISignOff && (
                            <div className={classes.createButton}>
                                <Tooltip title="A new Sign-Off can't be created while there is still an open Sign-off.">
                                    <div>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            onClick={onCreatePISignOffDialogOpen}
                                            disabled={disableCreation}
                                        >
                                            Create Sign-Off
                                        </Button>
                                    </div>
                                </Tooltip>
                            </div>
                        )
                    }
                </div>

                <CollapsibleTable
                    title="Sign-Offs"
                    entityName="Sign-Off"
                    rows={piSignOffBatches ?? []}
                    loading={piSignOffBatchesLoading ?? piSignOffDefinitionsLoading }
                    columns={signOffColumns as any}
                    autoHeight
                    rowsPerPageOptions={rowsPerPageOptions}
                    initialPageSize={initialPageSize}
                    hideFooter={!rowsPerPageOptions}
                    onRowClick={onPISignOffClick}
                    disableColumnFilter
                    getCellClassName={getClassName}
                    headerHeight={0}
                />
            </div>

            <CreatePISignOffBatchDialog
                open={createPISignOffBatchDialogOpen}
                onClose={onCreatePISignOffBatchDialogClose}
                piSignOffDefinitions={piSignOffDefinitions}
                institutions={undefined}
                investigators={undefined}
                formDefinitions={formDefinitions ?? undefined}
            />
        </>
    );
}

export default PISignOffBatches;