import { useCallback, useContext, useMemo } from 'react';
import useSWR, { SWRConfiguration, mutate } from 'swr';
import { FormsContext, BoundMutator } from '@ngt/forms';
import { PISignOffDefinitionSingleResponse, PISignOffDefinitionGetSingleByCode } from '../../api/dtos';

// @ts-ignore: declared but not used 'url'
const fetcher = async (url: string, forms: Forms, code?: string) => {
    const response = await forms.serviceStackClient.get(new PISignOffDefinitionGetSingleByCode({ code }));

    return response;
}

const usePISignOffDefinitionById = (code?: string, configuration?: SWRConfiguration<PISignOffDefinitionSingleResponse, PISignOffDefinitionSingleResponse>) => {
    const forms = useContext(FormsContext);

    const { data, error } = useSWR<PISignOffDefinitionSingleResponse, PISignOffDefinitionSingleResponse>(code ? [`${forms.baseRoute}/pi-signoff-definition/single/code`, forms, code] : null, fetcher, configuration);

    const boundMutate: BoundMutator<PISignOffDefinitionSingleResponse> = useCallback((data, shouldRevalidate) => {
        return mutate([`${forms.baseRoute}/pi-signoff-definition/single/code`, forms, code] , data, shouldRevalidate);
    }, [forms, code])

    const result = useMemo(() => {
        return {
            data: data?.configuration,
            error: error?.responseStatus,
            loading: data === undefined && error === undefined,
            mutate: boundMutate
        };
    }, [boundMutate, data, error])

    return result;
}

export default usePISignOffDefinitionById;