import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import React, { FunctionComponent, useCallback } from 'react';
import { Form, IFormLabel, GroupedField, TextDisplay } from '@ngt/forms';
import { IPISignOffDefinition, IPISignOff } from '../../api/dtos';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { BasicAlert, IInstitution, IPatient } from '@ngt/forms-trials';

interface ICancelPISignOffDialogProps {
    piSignOff?: IPISignOff;
    piSignOffDefinition?: IPISignOffDefinition;
    //reviewer?: IReviewer;
    institution?: IInstitution;
    patient?: IPatient;
    open: boolean;
    onClose: () => void;
    onSubmit: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
    content: {
        padding: 0
    }
}));


const labels: IFormLabel[] = [
    {
        name: 'institution.name',
        label: 'Institution',
        detailedLabel: 'Institution'
    },
    {
        name: 'patient.studyNumber',
        label: 'Patient',
        detailedLabel: 'Patient'
    },
    {
        name: 'piSignOffDefinition.name',
        label: 'PI Sign-Off Type',
        detailedLabel: 'PI Sign-Off Type'
    }//,
    // {
    //     name: 'reviewer.name',
    //     label: 'Reviewer',
    //     detailedLabel: 'Reviewer'
    // }
];

const groupStyleProps = {
    xs: 12,
    sm: 12,
    md: 7,
    lg: 7,
    xl: 7
};

const CancelPISignOffDialog: FunctionComponent<ICancelPISignOffDialogProps> = ({
    open,
    onClose,
    onSubmit,
    institution,
    piSignOff,
    //reviewer,
    patient,
    piSignOffDefinition
}) => {
    const classes = useStyles();

    const handleSubmit = useCallback(() => {
        onSubmit();
        onClose();
    }, [onClose, onSubmit])

    return (
        <Dialog open={open} onClose={onClose} scroll="body">
            <DialogTitle>
                Close PI Sign-Off
            </DialogTitle>
            <DialogContent className={classes.content} dividers>
                <Form
                    initialValues={{
                        patient,
                        institution,
                        piSignOff,
                        //reviewer,
                        piSignOffDefinition
                    }}
                    labels={labels}
                >
                    <GroupedField
                        name="institution.name"
                        component={TextDisplay}
                        {...groupStyleProps}
                    />
                    <GroupedField
                        name="patient.studyNumber"
                        component={TextDisplay}
                        {...groupStyleProps}
                    />
                    <GroupedField
                        name="piSignOffDefinition.name"
                        component={TextDisplay}
                        {...groupStyleProps}
                    />
                    {/* <GroupedField
                        name="reviewer.name"
                        component={TextDisplay}
                        GroupStyleProps={groupStyleProps}
                    /> */}
                    <BasicAlert
                        title="Please Note"
                        description={
                            <>
                                <p>Closing the PI Sign-off will mark the review as cancelled.</p>
                                <p>Please ensure that this PI Sign-off is no longer required before closing.</p>
                            </>
                        }
                        icon={faInfoCircle}
                        severity="info"
                    />
                </Form>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="secondary">
                    Cancel
                </Button>
                <Button onClick={handleSubmit} color="primary">
                    Close PI Sign-Off
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default CancelPISignOffDialog;
