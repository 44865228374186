import { Theme, Typography } from '@mui/material';
import React, { FunctionComponent, useContext } from 'react';
import ContextPISignOffQueriesBreadcrumbs from '../components/breadcrumbs/ContextPISignOffQueriesBreadcrumbs';
import ContextQueryGrid from '../components/query/ContextQueryGrid';
import { InstitutionContext, PatientContext } from '@ngt/forms-trials';
import ContextPatientInformation from '../components/patient/ContextPatientInformation';
import { makeStyles } from '@mui/styles';

interface IQueriesProps {
}

const useStyles = makeStyles((theme: Theme) => ({
    title: {
        padding: theme.spacing(2, 2, 0)
    },
    subtitle: {
        padding: theme.spacing(0, 2, 2)
    },
    patientInformation: {
        padding: theme.spacing(2, 2, 0)
    },
    buttonGroup: {
        padding: theme.spacing(2),
        textAlign: 'right'
    }
}));

const QuerySummary: FunctionComponent<IQueriesProps> = () => {
    const classes = useStyles();

    return (
        <>
            <ContextPISignOffQueriesBreadcrumbs />
            <div className={classes.patientInformation}>
                <ContextPatientInformation />
            </div>
            <Typography variant="h1" className={classes.title}>
                Query Summary
            </Typography>
            <ContextQueryGrid headerRows />
        </>
    );
};

export default QuerySummary;
