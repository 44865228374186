import React, { FunctionComponent, useContext } from 'react';
import { InstitutionContext, PatientContext } from '@ngt/forms-trials';
import PISignOffContext from '../../contexts/data/PISignOffContext';
import InvestigatorContext from '../../contexts/data/InvestigatorContext';
import PatientInformation from './PatientInformation';

interface IContextPatientInformationProps {
}



const ContextPatientInformation: FunctionComponent<IContextPatientInformationProps> = () => {

    const { data: patient } = useContext(PatientContext);
    const { data: institution } = useContext(InstitutionContext);
    const { data: piSignOff } = useContext(PISignOffContext);
    const { data: investigator } = useContext(InvestigatorContext);

    return (
        <PatientInformation
            patient={patient}
            institution={institution}
            investigator={investigator}
            piSignOff={piSignOff}
        />
    );
};

export default ContextPatientInformation;
