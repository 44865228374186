import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { IPISignOff, IQuery, QueryStatus } from '../../api/dtos';
import { Typography, Portal, Slide, Paper, IconButton, Divider, Theme, Box } from '@mui/material';
import { asyncDebounce, Form, GroupedField, IFormDefinition, IFormLabel, IFormType, IGroupedFieldStyleProps, ILookup, IValidationError, Select, SubmitButton, TextArea, useSnackbar, ValidationErrorType } from '@ngt/forms';
import { makeStyles } from '@mui/styles';
import {  faTimes } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IFormContext, IFormSubmit, IFormSubmitFailed, IFormValidate, ValidateOn } from '@ngt/forms-core';
import { AlertTitle } from '@mui/lab';
import { IGroupedFieldStyleColumns } from '@ngt/forms/dist/components/form/field/grouped/GroupedField';


export interface IQueryDialogProps {
    piSignOff?: IPISignOff;
    propertyName?: string;
    formId?: number;
    query?: IQuery;
    formType?: IFormType;
    formDefinition?: IFormDefinition;
    formTypes?: IFormType[];
    formDefinitions?: IFormDefinition[];
    onRespond: (relatedQuery: IQuery | undefined, query: IQuery, formType: IFormType, formDefinition: IFormDefinition) => Promise<any> | void;
    onIssue: (relatedQuery: IQuery | undefined, query: IQuery, formType: IFormType, formDefinition: IFormDefinition) => Promise<any> | void;
    onClose: (relatedQuery: IQuery | undefined, query: IQuery, formType: IFormType, formDefinition: IFormDefinition) => Promise<any> | void;
    onCancel: (relatedQuery: IQuery | undefined, query: IQuery, formType: IFormType, formDefinition: IFormDefinition) => Promise<any> | void;
    mode: QueryStatus;

    onFailure?: (relatedQuery: IQuery | undefined, query: IQuery, formType: IFormType, formDefinition: IFormDefinition) => Promise<any> | void;
    notifications?: boolean;
    hideSection?: boolean;
    hideReport?: boolean;

    dialogOpen?: boolean;
    onDialogClose: () => void;
}


export type DialogState = Omit<IQueryDialogProps, 'onIssue' | 'onClose' | 'onCancel' | 'onRespond' | 'onDialogClose' | 'piSignOff' | 'queryRecipients' | 'formTypes' | 'formDefinitions' | 'hideReport' | 'hideSection' | 'notifications' | 'onFailure'>

const useStyles = makeStyles((theme: Theme) => ({
    submit: {
        display: 'flex',
        margin: theme.spacing(1)
    },
    container: {
        position: 'fixed',
        zIndex: '2',
        bottom: theme.spacing(0),
        right: theme.spacing(0),
        width: 400
    },
    title: {
        display: 'flex',
        alignItems: 'center',

        '& > *:first-of-type': {
            padding: theme.spacing(2),
            flex: '1 1 auto'
        },

        '& > *:last-child': {
            marginRight: theme.spacing(2),
        }
    }
}));

const labels: IFormLabel[] = [
    {
        name: 'formTypeId',
        label: 'Section',
        detailedLabel: 'Section'
    },
    {
        name: 'formDefinitionId',
        label: 'Report',
        detailedLabel: 'Report'
    },
    {
        name: 'recipientId',
        label: 'Recipient',
        detailedLabel: 'Recipient'
    },
    {
        name: 'question',
        label: 'Data Change Request',
        detailedLabel: 'Dear Investigator, Please provide the details of specific data point(s) and the correction(s) required'
    },
    {
        name: 'response',
        label: 'Response',
        detailedLabel: 'Response'
    },
    {
        name: 'comment',
        label: 'Comment',
        detailedLabel: 'Please include any details regarding closure of the request'
    }
];

const defaultInputColumnSizes: IGroupedFieldStyleColumns = {
    xs: 12,
    sm: 12,
    md: 12,
    lg: 12,
    xl: 12
}

const defaultLabelColumnSizes: IGroupedFieldStyleColumns = {
    xs: 12,
    sm: 12,
    md: 12,
    lg: 12,
    xl: 12
}

const groupStyleProps: IGroupedFieldStyleProps = {
    labelColumn: defaultLabelColumnSizes,
    inputColumn: defaultInputColumnSizes
};

const QueryDialog: FunctionComponent<IQueryDialogProps> = ({
    query,
    formDefinitions,
    formTypes,
    piSignOff,
    propertyName,
    formId,
    formType,
    formDefinition,
    onIssue,
    onRespond,
    onClose,
    onCancel,
    mode,
    dialogOpen,
    onDialogClose,
    notifications,
    onFailure,
    hideSection,
    hideReport
}) => {
    const classes = useStyles();
    const [initialQuery, setInitialQuery] = useState<IQuery>({ status: mode } as IQuery);

    useEffect(() => {
        const data = {
            ...(mode === QueryStatus.Issued ? {} : query),
            id: mode === QueryStatus.Issued ?
                undefined :
                query?.id,
            formTypeId: formType?.id,
            formDefinitionId: query?.formDefinitionId ?? formDefinition?.id,
            formId: query?.formId ?? formId,
            parentQueryId: mode === QueryStatus.Issued ?
                query?.id :
                undefined,
            status: mode,
            enteredBy: mode === QueryStatus.Issued ?
                undefined :
                query?.enteredBy,
            modifiedBy: mode === QueryStatus.Issued ?
                undefined :
                query?.modifiedBy,
            enteredDate: mode === QueryStatus.Issued ?
                undefined :
                query?.enteredDate,
            modifiedDate: mode === QueryStatus.Issued ?
                undefined :
                query?.modifiedDate,
            propertyName: query?.propertyName ?? propertyName,
            repeat: mode === QueryStatus.Issued ?
                undefined :
                query?.repeat,
            question: mode === QueryStatus.Issued ?
                undefined :
                query?.question,
            response: mode === QueryStatus.Issued ?
                undefined :
                query?.response,
            comment: mode === QueryStatus.Issued ?
                undefined :
                query?.comment,
            piSignOffId: query?.piSignOffId ?? piSignOff?.id,
        } as unknown as IQuery;

        setInitialQuery(data);
    }, [query, setInitialQuery, mode, formDefinition, piSignOff, propertyName, formType, formId]);

    const validate = useMemo(() => {
        const v: IFormValidate<IQuery, IValidationError> = async (formState) => {
            let errors: Record<string, IValidationError[]> = {};

            if (mode === QueryStatus.Issued) {
                if (!formState.value?.question || formState.value?.question.trim() === '') {
                    errors['question'] = [
                        {
                            code: 'DCR-003',
                            message: 'A data change request must be entered.',
                            detailedMessage: 'A data change request must be entered.',
                            property: 'question',
                            type: ValidationErrorType.Normal
                        }
                    ];
                }
            }

            if (mode === QueryStatus.Responded) {
                if (!formState.value?.response || formState.value?.response.trim() === '') {
                    errors['response'] = [
                        {
                            code: 'DCR-004',
                            message: 'A response must be entered.',
                            detailedMessage: 'A response must be entered.',
                            property: 'question',
                            type: ValidationErrorType.Normal
                        }
                    ];
                }
            }

            return errors;
        }

        const debouncedValidate = asyncDebounce(v, 500)

        return debouncedValidate;
    }, [mode])

    const { enqueueSnackbar } = useSnackbar();

    const lookups: ILookup[] = useMemo(() => {
        return [
            {
                propertyName: 'formTypeId',
                items: formTypes?.map((ft, i) => ({
                    id: ft.id,
                    value: ft.name,
                    order: i
                }))
            },
            {
                propertyName: 'formDefinitionId',
                items: formDefinitions?.map((fd, i) => ({
                    id: fd.id,
                    value: fd.name,
                    order: i
                }))
            }
        ] as ILookup[];
    }, [formDefinitions, formTypes]);

    // const labels: IFormLabel[] = useMemo(() => { 
    //     return [
    //         {
    //             name: 'formTypeId',
    //             label: 'Section',
    //             detailedLabel: 'Section'
    //         },
    //         {
    //             name: 'formDefinitionId',
    //             label: 'Report',
    //             detailedLabel: 'Report'
    //         },
    //         {
    //             name: 'recipientId',
    //             label: 'Recipient',
    //             detailedLabel: 'Recipient'
    //         },
    //         {
    //             name: 'question',
    //             label: 'Data Change Request',
    //             detailedLabel: () => { switch (mode) {
    //                 case QueryStatus.Issued:
    //                     return 'Dear Investigator, Please provide the details of specific data point(s) and the correction(s) required';
    //                 case QueryStatus.Responded:
    //                     return 'Responed mode';
    //                 case QueryStatus.Closed:
    //                     return 'Closed mode';
    //                 case QueryStatus.Cancelled:
    //                     return 'Cancelled mode';
    //                 default:
    //                     return 'Dear Investigator, Please provide the details of specific data point(s) and the correction(s) required';
    //             }}
    //         },
    //         {
    //             name: 'response',
    //             label: 'Response',
    //             detailedLabel: 'Response'
    //         }
    //     ] as IFormLabel[];
    // }, [mode]);

    const onSubmit: IFormSubmit<IQuery, IValidationError> = useCallback(async (formState) => {

        switch (mode) {
            case QueryStatus.Issued:
                await onIssue(query, formState.value!, formType!, formDefinition!);
                break;
            case QueryStatus.Responded:
                await onRespond(query, formState.value!, formType!, formDefinition!);
                break;
            case QueryStatus.Closed:
                await onClose(query, formState.value!, formType!, formDefinition!);
                break;
            case QueryStatus.Cancelled:
                await onCancel(query, formState.value!, formType!, formDefinition!);
                break;
        }

        setInitialQuery({} as IQuery);
        onDialogClose();

        if (notifications) {
            enqueueSnackbar(
                <>
                    <AlertTitle>
                        Data Change Request Saved
                    </AlertTitle>
                    The data change request has been successfully saved.
                </>,
                { variant: 'success' }
            );
        }
    }, [onClose, onIssue, onRespond, enqueueSnackbar, setInitialQuery, onCancel, onDialogClose, mode, query, formType, formDefinition, notifications])


    const onSubmitFailed: IFormSubmitFailed<IQuery, IValidationError> = useCallback(async (formState) => {
        if (onFailure) {
            onFailure(query, formState.value!, formType!, formDefinition!)
        }



        if (notifications) {
            enqueueSnackbar(
                <>
                    <AlertTitle>
                        Data Change Request Not Saved
                    </AlertTitle>
                    An error occurred while attempting to save the data change request.
                </>,
                { variant: 'error-critical' }
            );
        }
    }, [enqueueSnackbar, onFailure, query, formType, formDefinition, notifications]);

    const onSubmitClick = useCallback((event?: React.MouseEvent<HTMLButtonElement, MouseEvent>, formActions?: IFormContext<IQuery, IValidationError>) => {

        event?.stopPropagation();
        event?.preventDefault();
        formActions?.submit();
    }, []);

    return (
        <>
            <Portal>
                <Slide
                    direction="up"
                    in={dialogOpen}
                >
                    <Paper className={classes.container}>
                        <div className={classes.title}>
                            <Typography variant="h3" {...{xs: 12}}>
                                {mode === QueryStatus.Issued && !query?.id && "Create a New Data Change Request"}
                                {mode === QueryStatus.Issued && !!query?.id && "Request More Information"}
                                {mode === QueryStatus.Responded && "Respond To Data Change Request"}
                                {mode === QueryStatus.Closed && "Close Data Change Request"}
                                {mode === QueryStatus.Cancelled && "Cancel Data Change Request"}
                            </Typography>
                            <IconButton size="small" onClick={onDialogClose}>
                                <FontAwesomeIcon icon={faTimes} />
                            </IconButton>
                        </div>
                        <Divider />
                        <Box>
                            <Form
                                initialValues={initialQuery}
                                labels={labels}
                                lookups={lookups}
                                onSubmit={onSubmit}
                                onSubmitFailed={onSubmitFailed}
                                onValidate={validate}
                                validateOn={ValidateOn.onChange}
                            >
                                {
                                    !hideSection && (
                                        <GroupedField
                                            name="formTypeId"
                                            component={Select}
                                            GroupStyleProps={groupStyleProps}
                                            disabled
                                        />
                                    )
                                }
                                {
                                    !hideReport && (
                                        <GroupedField
                                            name="formDefinitionId"
                                            component={Select}
                                            GroupStyleProps={groupStyleProps}
                                            disabled
                                        />
                                    )
                                }
                                <GroupedField
                                    name="question"
                                    component={TextArea}
                                    variant="standard"
                                    GroupStyleProps={groupStyleProps}
                                    rows={4}
                                    maxRows={4}
                                    disabled={mode === QueryStatus.Responded || mode === QueryStatus.Closed || mode === QueryStatus.Cancelled}
                                />
                                {
                                    (mode === QueryStatus.Responded || mode === QueryStatus.Closed) && (
                                        <GroupedField
                                            name="response"
                                            component={TextArea}
                                            variant="standard"
                                            GroupStyleProps={groupStyleProps}
                                            rows={4}
                                            maxRows={4}
                                            disabled={mode === QueryStatus.Closed}
                                        />
                                    )
                                }
                                {
                                    (mode === QueryStatus.Closed || mode === QueryStatus.Cancelled) && (
                                        <GroupedField
                                            name="comment"
                                            component={TextArea}
                                            variant="standard"
                                            GroupStyleProps={groupStyleProps}
                                            rows={4}
                                            maxRows={4}
                                        />
                                    )
                                }
                                <Box className={classes.submit}>
                                    <SubmitButton fullWidth color="primary" variant="contained" className={classes.submit} onClick={onSubmitClick as any}>
                                        Submit
                                    </SubmitButton>
                                </Box>
                            </Form>
                        </Box>
                    </Paper>
                </Slide>
            </Portal>
        </>
    );
};

export default QueryDialog;
