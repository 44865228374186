/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the component that provides the basis for all routes.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a context.
 */
import * as React from 'react';

/*
 * Used to type properties and get access to routes. 
 */
import { RouteProps } from 'react-router';


/*
 * Used to get access to application routing based on paths.
 */
import { Route } from 'react-router-dom';
import { FormDefinitionCollectionResponse } from '@ngt/forms';
import { SWRConfiguration } from 'swr/dist/types';
import { PISignOffBatchCollectionResponse, PISignOffDefinitionCollectionResponse } from '../../api/dtos';

import PISignOffBatchesResolver from '../resolver/PISignOffBatchesResolver';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */


/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

/**
 * This interface defines the properties for the OpmsFormByIdRoute component.
 */
export interface IPISignOffBatchesRouteProps{
    resolveBeforeLoad?: boolean | null;

    piSignOffBatchesConfiguration?: Partial<SWRConfiguration<PISignOffBatchCollectionResponse, PISignOffBatchCollectionResponse>>;
    piSignOffDefinitionsConfiguration?: Partial<SWRConfiguration<PISignOffDefinitionCollectionResponse, PISignOffDefinitionCollectionResponse>>;
    formDefinitionsConfiguration?: Partial<SWRConfiguration<FormDefinitionCollectionResponse, FormDefinitionCollectionResponse>>;
}

/*
 * ---------------------------------------------------------------------------------
 * Components
 * ---------------------------------------------------------------------------------
 */

/**
 * This component provides context for the form management system.
 * @param param0 component properties.
 */
const PISignOffBatchesRoute: React.FunctionComponent<IPISignOffBatchesRouteProps> = ({
    resolveBeforeLoad,
    children,

    formDefinitionsConfiguration,
    piSignOffBatchesConfiguration,
    piSignOffDefinitionsConfiguration,

    ...routeProps
}) => {
    return (
        <PISignOffBatchesResolver
            formDefinitionsConfiguration={formDefinitionsConfiguration}
            piSignOffBatchesConfiguration={piSignOffBatchesConfiguration}
            piSignOffDefinitionsConfiguration={piSignOffDefinitionsConfiguration}
            resolveBeforeLoad={resolveBeforeLoad}
        >
            {children}
        </PISignOffBatchesResolver>
    );
}

/*
    * ---------------------------------------------------------------------------------
    * Default Export
    * ---------------------------------------------------------------------------------
    */

export default PISignOffBatchesRoute;