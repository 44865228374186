import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { IQuery, QueryStatus } from '../../api/dtos';
import { TableRow, TableCell, Typography, Button, Tooltip, Theme } from '@mui/material';
import { IFormDefinition, IFormType } from '@ngt/forms';
import { makeStyles } from '@mui/styles';
import { convertQueryStatusToString } from '../../utilities/QueryStatus';
import { faCommentAltPlus, faCommentAltTimes, faCommentsAlt } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DialogState } from './QueryDialog';

export interface IQueryGridRowProps {
    query: IQuery;
    formTypes: IFormType[];
    formDefinitions: IFormDefinition[];
    disabled?: boolean;
    setDialogState: (state: DialogState) => void;
    canIssue?: boolean;
    canRespond?: boolean;
    canClose?: boolean;
    canCancel?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
    cell: {
        verticalAlign: 'top'
    },
    pre: {
        whiteSpace: 'pre-wrap',
        margin: theme.spacing(0)
    },
    button: {
        minWidth: '0',
        padding: theme.spacing(1),
        marginLeft: theme.spacing(1),

        '&:first-of-type': {
            margin: theme.spacing(0)
        }
    },
    alert: {
        padding: theme.spacing(2)
    }
}));

const QueryGridRow: FunctionComponent<IQueryGridRowProps> = ({
    query,
    disabled,
    formTypes,
    formDefinitions,
    setDialogState,
    canIssue,
    canClose,
    canCancel,
    canRespond
}) => {
    const classes = useStyles()

    const formDefinition = useMemo(() => {
        return formDefinitions?.find(x => x.id === query.formDefinitionId)
    }, [formDefinitions, query]);

    const formType = useMemo(() => {
        return formTypes?.find(x => x.id === formDefinition?.type)
    }, [formTypes, formDefinition]);

    const onCreateNewClick = useCallback(() => {
        setDialogState({
            mode: QueryStatus.Issued,
            dialogOpen: true,
            formDefinition: formDefinition,
            formType: formType,
            formId: query.formId,
            query: query,
            propertyName: query.propertyName
        })
    }, [setDialogState, formDefinition, formType, query]);

    const onRespondClick = useCallback(() => {
        setDialogState({
            mode: QueryStatus.Responded,
            dialogOpen: true,
            formDefinition: formDefinition,
            formType: formType,
            formId: query.formId,
            query: query,
            propertyName: query.propertyName
        })
    }, [setDialogState, formDefinition, formType, query])

    const onCloseClick = useCallback(() => {
        setDialogState({
            mode: QueryStatus.Closed,
            dialogOpen: true,
            formDefinition: formDefinition,
            formType: formType,
            formId: query.formId,
            query: query,
            propertyName: query.propertyName
        })
    }, [setDialogState, formDefinition, formType, query])

    const onCancelClick = useCallback(() => {
        setDialogState({
            mode: QueryStatus.Cancelled,
            dialogOpen: true,
            formDefinition: formDefinition,
            formType: formType,
            formId: query.formId,
            query: query,
            propertyName: query.propertyName
        })
    }, [setDialogState, formDefinition, formType, query])

    return (
        <TableRow>
            <TableCell
                className={classes.cell}
                width={120}
            >
                <Typography>
                    {convertQueryStatusToString(query.status)}
                </Typography>
            </TableCell>
            <TableCell
                className={classes.cell}
            >
                {
                    query.question && (
                        <pre
                            className={classes.pre}
                        >
                            <Typography>
                                {query.question}
                            </Typography>
                        </pre>
                    )
                }
            </TableCell>
            <TableCell
                className={classes.cell}
            >
                {
                    query.response && (
                        <pre
                            className={classes.pre}
                        >
                            <Typography>
                                {query.response}
                            </Typography>
                        </pre>
                    )
                }
            </TableCell>
            <TableCell
                className={classes.cell}
            >
                {
                    query.comment && (
                        <pre
                            className={classes.pre}
                        >
                            <Typography>
                                {query.comment}
                            </Typography>
                        </pre>
                    )
                }
            </TableCell>
            {
                (!disabled && (canIssue || canRespond || canClose || canCancel)) && (
                    <TableCell
                        className={classes.cell}
                        width={120}
                    >
                        {
                            query.status === QueryStatus.Responded && canIssue && (
                                <Tooltip title="Request more information">
                                    <Button color="primary" variant="contained" className={classes.button} onClick={onCreateNewClick}>
                                        <FontAwesomeIcon icon={faCommentAltPlus} size="lg" fixedWidth />
                                    </Button>
                                </Tooltip>
                            )
                        }
                        {
                            query.status === QueryStatus.Issued && canRespond && (
                                <Tooltip title="Respond to the data change request">
                                    <Button color="primary" variant="contained" className={classes.button} onClick={onRespondClick}>
                                        <FontAwesomeIcon icon={faCommentsAlt} size="lg" fixedWidth />
                                    </Button>
                                </Tooltip>
                            )
                        }
                        {
                            query.status === QueryStatus.Responded && canClose && (
                                <Tooltip title="Close the data change request">
                                    <Button color="primary" variant="contained" className={classes.button} onClick={onCloseClick}>
                                        <FontAwesomeIcon icon={faCommentAltTimes} size="lg" fixedWidth />
                                    </Button>
                                </Tooltip>
                            )
                        }
                        {
                            query.status === QueryStatus.Issued && canCancel && (
                                <Tooltip title="Cancel the data change request">
                                    <Button color="primary" variant="contained" className={classes.button} onClick={onCancelClick}>
                                        <FontAwesomeIcon icon={faCommentAltTimes} size="lg" fixedWidth />
                                    </Button>
                                </Tooltip>
                            )
                        }
                    </TableCell>
                )
            }
        </TableRow>
    );
};

export default QueryGridRow;
