import { useCallback, useContext, useMemo } from 'react';
import useSWR, { SWRConfiguration, mutate } from 'swr';
import { BoundMutator, Forms, FormsContext } from '@ngt/forms';
import { IInvestigator, InvestigatorGetSingleById, InvestigatorPostCreate, InvestigatorPostSave, InvestigatorPostValidate, InvestigatorSingleResponse } from '../../api/dtos';

// @ts-ignore: declared but not used 'url'
const fetcher = async <TInvestigator extends IInvestigator = IInvestigator>(url: string, forms: Forms, id?: number, data?: TInvestigator, metadata?: Record<string, any>) => {
    if (!id) {
        return await create<TInvestigator>(forms, data, metadata);
    }

    return await get(forms, id)
}

const create = async <TInvestigator extends IInvestigator = IInvestigator>(forms: Forms, data?: TInvestigator, metadata?: Record<string, any>) => {

    const response = await forms.serviceStackClient.post(new InvestigatorPostCreate({ data, metadata }));

    return response;
}

const get = async (forms: Forms, id: number) => {
    const response = await forms.serviceStackClient.get(new InvestigatorGetSingleById({ id }));

    return response;
}

// @ts-ignore: declared but not used 'url'
const save = async <TInvestigator extends IInvestigator = IInvestigator>(forms: Forms, data: TInvestigator, metadata: Record<string, any> | undefined) => {
    const response = await forms.serviceStackClient.post(new InvestigatorPostSave({ data, metadata }));

    return response;
}

const validate = async <TInvestigator extends IInvestigator = IInvestigator>(forms: Forms, data: TInvestigator, metadata: Record<string, any> | undefined) => {

    const response = await forms.serviceStackClient.post(new InvestigatorPostValidate({ data, metadata }));

    return response;
}

const useInvestigatorById = <TInvestigator extends IInvestigator = IInvestigator>(id?: number | null, createData?: TInvestigator, createMetadata?: Record<string, any>, configuration?: SWRConfiguration<InvestigatorSingleResponse, InvestigatorSingleResponse>) => {
    const forms = useContext(FormsContext);

    const cacheKey = useMemo(() => {
        if (!id && !createData && !createMetadata) {
            return null;
        }

        if (!id) {
            return [`${forms.baseRoute}/investigator/single/id`, forms, null, createData, createMetadata]
        }

        return [`${forms.baseRoute}/investigator/single/id`, forms, id, null, null]
    }, [forms, id, createData, createMetadata])

    const { data, error } = useSWR<InvestigatorSingleResponse, InvestigatorSingleResponse>(cacheKey, fetcher, configuration);

    const boundMutate: BoundMutator<InvestigatorSingleResponse> = useCallback((newData, shouldRevalidate) => {
        return mutate(cacheKey, newData, shouldRevalidate);
    }, [cacheKey]);

    const boundSave = useCallback(async (saveData: TInvestigator, metadata?: Record<string, any>, shouldRevalidate?: boolean) => {
        const response = await save<TInvestigator>(forms, saveData, metadata);

        if (cacheKey) {
            // !!cacheKey[2] = has an id.
            const revalidate = shouldRevalidate ?? !!cacheKey[2];

            const updated = await boundMutate(response, revalidate);

            return updated?.data;
        }

        return response?.data;
    }, [boundMutate, forms, cacheKey]);

    const boundValidate = useCallback(async (validateData: TInvestigator, metadata?: Record<string, any>) => {
        const response = await validate<TInvestigator>(forms, validateData, metadata);

        return response?.validationResult;
    }, [forms]);


    const result = useMemo(() => {
        return {
            data: data?.data as TInvestigator | undefined | null,
            error: error?.responseStatus,
            loading: data === undefined && error === undefined,
            mutate: boundMutate,
            save: boundSave,
            validate: boundValidate
        };
    }, [boundMutate, data, error, boundValidate, boundSave])

    return result;
}

export default useInvestigatorById;