import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { FunctionComponent, useCallback, useState } from 'react';
import { Form, IFormLabel, GroupedField, TextDisplay, ProgressButton, IValidationError } from '@ngt/forms';
import { IPISignOffDefinition, IPISignOff, IQuery, IPISignOffForm, PISignOffStatus } from '../../api/dtos';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { BasicAlert, IInstitution, IPatient } from '@ngt/forms-trials';
import { SubmitButton } from '@ngt/forms';
import { IFormContext } from '@ngt/forms-core';

interface IApprovePISignOffFormDialogProps {
    open: boolean;
    onClose: () => void;
    onSubmit: () => Promise<any>;
}

const useStyles = makeStyles((theme: Theme) => ({
    content: {
        padding: 0
    }
}));

const groupStyleProps = {
    xs: 12,
    sm: 12,
    md: 7,
    lg: 7,
    xl: 7
};

const ApprovePISignOffFormDialog: FunctionComponent<IApprovePISignOffFormDialogProps> = ({
    open,
    onClose,
    onSubmit
}) => {
    const classes = useStyles();

    const [submitting, setSubmitting] = useState(false);

    const handleSubmit = useCallback(async (event?: React.MouseEvent<HTMLButtonElement, MouseEvent>, formActions?: IFormContext<IPISignOffForm, IValidationError>) => {
        setSubmitting(true);

        await formActions?.setFieldValue('status', PISignOffStatus.Complete, false, false, false, false);
        await formActions?.submit();

        onClose();
        setSubmitting(false);
    }, [onClose, onSubmit, setSubmitting])

    return (
        <Dialog open={open} onClose={onClose} scroll="body">
            <DialogTitle>
                Approve PI Sign Off Report
            </DialogTitle>
            <DialogContent className={classes.content} dividers>
                <BasicAlert
                    title="Please Note"
                    description={
                        <>
                            <p>Confirms that the data entered in the eCRF are attributable, legible, original, accurate, complete and contemporaneous.</p>
                        </>
                    }
                    icon={faInfoCircle}
                    severity="info"
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="secondary" disabled={submitting}>
                    Cancel
                </Button>
                <SubmitButton onClick={handleSubmit} color="primary" loading={submitting}>
                    Approve
                </SubmitButton>
            </DialogActions>
        </Dialog>
    );
};

export default ApprovePISignOffFormDialog;
