/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */
import { IFormsExtension, IFormDefinition, IValidationResult, IUserDto, Forms } from '@ngt/forms';


/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as React from 'react';
import PISignOffExtensionContext from './contexts/PISignOffExtensionContext';
import { GridColDef } from '@mui/x-data-grid';
import { IInvestigator, IPISignOff, IPISignOffBatch, IPISignOffDefinition, IPISignOffForm } from './api/dtos';
//import { IPatientPISignOffForm } from './api/dtos';
import { ICountry, IInstitution, IPatient } from '@ngt/forms-trials';


/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */
export interface IPISignOffExtensionRouteParameterOptions {
    piSignOffBatchId: string;
    piSignOffDefinitionId: string;
    piSignOffDefinitionCode: string;
    piSignOffRepeat: string;
    piSignOffId: string;
}

export interface IPISignOffExtensionOptions {
    routeParameters: IPISignOffExtensionRouteParameterOptions;

    /**
     * Columns for the PI Sign-Offs table.
     */
    piSignOffColumnsFn?: (user: any | undefined | null) => GridColDef[];

    patientPISignOffColumnsFn?: (user: any | undefined | null, countries: ICountry[], institutions: IInstitution[], investigators: IInvestigator[]) => GridColDef[];

    createPISignOffBatchesRouteFn: (search?: string) => string;

    createPISignOffBatchRouteFn: (piSignOffBatch: IPISignOffBatch, piSignOffDefinition: IPISignOffDefinition, search?: string) => string;

    createPISignOffRouteFn: (piSignOffBatch: IPISignOffBatch, piSignOffDefinition: IPISignOffDefinition, patient: IPatient, piSignOff: IPISignOff) => string;

    createPISignOffFormRouteFn: (piSignOffBatch: IPISignOffBatch, piSignOffDefinition: IPISignOffDefinition, patient: IPatient, piSignOff: IPISignOff, formDefinition: IFormDefinition, formValidation?: IValidationResult, form?: IPISignOffForm) => string;

    createPISignOffQueriesRouteFn: (piSignOffBatch: IPISignOffBatch, piSignOffDefinition: IPISignOffDefinition, patient: IPatient, piSignOff: IPISignOff) => string;
}


/*
 * ---------------------------------------------------------------------------------
 * Classes
 * ---------------------------------------------------------------------------------
 */

/**
 * This class handles the global context used by the OPMS.
 */
export class PISignOffExtension implements IFormsExtension {
    public ProviderComponent: React.ComponentType<{
        children: React.ReactNode;
    }> | undefined;

    public ContentComponent: React.ComponentType<{
        children: React.ReactNode;
    }> | undefined;

    /**
     * Creates a new OnlinePatientManagement using the provided configuration options.
     * @param options Configuration Options
     */
    constructor(options: IPISignOffExtensionOptions) {
        this.initialiseProviderComponent(options);
        this.initialiseContentComponent(options);
    }

    private initialiseProviderComponent(options: IPISignOffExtensionOptions) {

        this.ProviderComponent = ({ children }) => {
            return (
                <PISignOffExtensionContext.Provider value={options}>
                    {children}
                </PISignOffExtensionContext.Provider>
            );
        };
    }

    private initialiseContentComponent(options: IPISignOffExtensionOptions) {

        this.ContentComponent = ({ children }) => {
            return (
                <>
                    {children}
                </>
            );
        };
    }
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default PISignOffExtension;