/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/*
 * Required to use React components.
 */
import React, { FunctionComponent, useCallback, useContext, useMemo, useState } from 'react';


/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */


// import { default as FormBase, IFormProps as IFormBaseProps } from '@ngt/forms-core';
import { IFormSubmitValidationFailed, IFormSubmitFailed, IFormSubmit, IFormContext, useScopedField, useFormState } from '@ngt/forms-core';
import { ContextForm, IContextFormProps, IValidationError, IValidationResult, SubmitButton, useSnackbar, titleCase, FormDefinitionContext, ValidationErrorType, FormContext, FieldProvider, GroupedErrorDisplay, FormsContext, SnackbarVariant } from '@ngt/forms';
import { IPISignOffForm, PISignOffPermission, PISignOffStatus, QueryStatus, IQuery } from '../../api/dtos';
import { AlertTitle } from '@mui/lab';
import pluralize from 'pluralize';
import { Button, Theme, Tooltip } from '@mui/material';
import PISignOffContext from '../../contexts/data/PISignOffContext';
import PISignOffExtensionContext from '../../contexts/PISignOffExtensionContext';
import { useNavigate } from 'react-router-dom';
import useContextPermissions from '../../hooks/utility/useContextPermissions';
import { PatientContext } from '@ngt/forms-trials';
import PISignOffDefinitionContext from '../../contexts/configuration/PISignOffDefinitionContext';
import PISignOffBatchContext from '../../contexts/data/PISignOffBatchContext';
import UpdatePISignOffFormDialog from '../dialogs/UpdatePISignOffFormDialog';
import ApprovePISignOffFormDialog from '../dialogs/ApprovePISignOffFormDialog'
import AssignPISignOffDialog from '../dialogs/AssignPISignOffDialog';
import QueryDialog from '../query/QueryDialog';
import QueryDialogContext from '../../contexts/utility/QueryDialogContext';
import { makeStyles } from '@mui/styles';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

///**
// * This interface defines the properties for the Form component.
// */
//export interface IFormProps<TData extends Record<string, any> = Record<string, any>> extends UseFormOptions<TData> {
//    onSubmit: SubmitHandler<TData>
//    children?: React.ReactNode;
//}

/**
 * This interface defines the properties for the Form component.
 */
export interface IContextPISignOffFormProps<TData extends IPISignOffForm = IPISignOffForm, TValidationResult extends IValidationResult = IValidationResult> extends IContextFormProps<TData, TValidationResult> {
    queriesName?: string;
}

const errorVariantMapping: Record<ValidationErrorType, SnackbarVariant> = {
    [ValidationErrorType.Warning]: 'warning',
    [ValidationErrorType.Low]: 'error-low',
    [ValidationErrorType.Normal]: 'error-normal',
    [ValidationErrorType.High]: 'error-high',
    [ValidationErrorType.Critical]: 'error-critical'
}

const errorTextMapping: Record<ValidationErrorType, string> = {
    [ValidationErrorType.Warning]: 'warning',
    [ValidationErrorType.Low]: 'low priority error',
    [ValidationErrorType.Normal]: 'error',
    [ValidationErrorType.High]: 'high priority error',
    [ValidationErrorType.Critical]: 'critical error'
}
/*
 * ---------------------------------------------------------------------------------
 * Components
 * ---------------------------------------------------------------------------------
 */
const useStyles = makeStyles((theme: Theme) => ({
    buttonGroup: {
        padding: theme.spacing(2),
        textAlign: 'right'
    },
    margin: {
        marginLeft: theme.spacing(2)
    },
    submitMargin: {
        marginLeft: theme.spacing(2)
    }
}));

const subscription = { value: true };

interface ICompleteButton {
    entityName: string;
    queriesName?: string;
}

const CompleteButton: FunctionComponent<ICompleteButton> = ({
    entityName,
    queriesName
}) => {
    const classes = useStyles();

    const [updateOpen, setUpdateOpen] = useState(false);
    const [submitOpen, setSubmitOpen] = useState(false);
    const { data: piSignOff } = useContext(PISignOffContext);

    const { state: { value: queries } } = useScopedField<IQuery[]>(queriesName ?? '', subscription);
    const showApproveButton = useMemo(() => {
        return queries?.find(q => q.status === QueryStatus.Issued || q.status === QueryStatus.Responded) === undefined;
    }, [queries])

    const onUpdateDialogOpen = useCallback(() => {
        setUpdateOpen(true);
    }, [setUpdateOpen]);

    const onUpdateDialogClose = useCallback(() => {
        setUpdateOpen(false);
    }, [setUpdateOpen]);

    const onSubmitDialogOpen = useCallback(() => {
        setSubmitOpen(true);
    }, [setSubmitOpen]);

    const onSubmitDialogClose = useCallback(() => {
        setSubmitOpen(false);
    }, [setSubmitOpen]);

    const [assignOpen, setAssignOpen] = useState(false);

    const onAssignDialogOpen = useCallback(() => {
        setAssignOpen(true);
    }, [setAssignOpen]);

    const onAssignDialogClose = useCallback(() => {
        setAssignOpen(false);
    }, [setAssignOpen]);

    const formContext = useContext(FormContext);

    const form = formContext.data;

    const update = (formContext as any).update;

    const { enqueueSnackbar } = useSnackbar();


    const onUpdateClick = useCallback(async () => {
        const entityTitleCase = titleCase(entityName);
        const entityLowerCase = entityName?.toLowerCase();

        try {
            await update(form, undefined);
            enqueueSnackbar(
                <>
                    <AlertTitle>
                        {entityTitleCase} Updated
                    </AlertTitle>
                    The {entityLowerCase} was successfully updated.
                </>,
                { variant: 'success' }
            );
        }
        catch {
            enqueueSnackbar(
                <>
                    <AlertTitle>
                        {entityTitleCase} Not Updated
                    </AlertTitle>
                    An error occured while attempting to update the {entityLowerCase}.
                </>,
                { variant: 'error-critical' }
            );
        }
    }, [form, update, entityName]);

    const { contextQueryDialogState, setContextQueryDialogState } = useContext(QueryDialogContext);

    const onSubmitClick = useCallback((event?: React.MouseEvent<HTMLButtonElement, MouseEvent>, formActions?: IFormContext<IPISignOffForm, IValidationError>) => {
        formActions?.setFieldValue('status', PISignOffStatus.Complete, false, false, false, false);
    }, []);

    const onRequestDataChangeClick = useCallback(() => {
        setContextQueryDialogState({
            mode: QueryStatus.Issued,
            dialogOpen: true,
            formDefinition: undefined,
            formId: undefined,
            formType: undefined,
            propertyName: undefined,
            query: undefined
        });
    }, [setContextQueryDialogState]);

    const { state: { value } } = useScopedField<PISignOffStatus>('status', subscription, false);

    const { data: [canCompletePISignOffForm, canUpdatePISignOffForm] } = useContextPermissions([PISignOffPermission.CompletePISignOffForm, PISignOffPermission.UpdatePISignOff]);

    if (value === PISignOffStatus.Cancelled) {
        return null;
    }

    if (!canUpdatePISignOffForm &&
        !(canCompletePISignOffForm && value !== PISignOffStatus.Complete)) {
        return null;
    }

    return (
        <>
            {
                canUpdatePISignOffForm && (
                    <UpdatePISignOffFormDialog
                        open={updateOpen}
                        onClose={onUpdateDialogClose}
                        onSubmit={onUpdateClick}
                    />
                )
            }
            {
                canUpdatePISignOffForm && piSignOff?.formCount == 1 && (
                    <AssignPISignOffDialog
                        onClose={onAssignDialogClose}
                        open={assignOpen}
                    />
                )
            }
            {
                canCompletePISignOffForm && piSignOff?.formCount == 1 && (
                    <ApprovePISignOffFormDialog
                        open={submitOpen}
                        onClose={onSubmitDialogClose}
                        onSubmit={onSubmitClick as any}
                    />
                )
            }
            <div className={classes.buttonGroup}>
                {
                    canUpdatePISignOffForm && (piSignOff?.status === PISignOffStatus.New || piSignOff?.status === PISignOffStatus.InProgress)  && piSignOff?.formCount == 1 && (
                        <Button onClick={onAssignDialogOpen} variant="contained" color="primary">
                            Assign
                        </Button>
                    )
                }
                {
                    canUpdatePISignOffForm && (piSignOff?.status === PISignOffStatus.New || piSignOff?.status === PISignOffStatus.InProgress) && (
                        <Button onClick={onUpdateDialogOpen} className={classes.margin} variant="contained" color="primary">
                            Update
                        </Button>
                    )
                }
                {
                    value !== PISignOffStatus.Complete && canCompletePISignOffForm && (
                        <Button onClick={onRequestDataChangeClick} className={classes.submitMargin} variant="contained" color="primary">
                            Request Data Change
                        </Button>
                    )
                }
                {
                    value !== PISignOffStatus.Complete && canCompletePISignOffForm && piSignOff?.formCount == 1 && showApproveButton && (
                        <Button onClick={onSubmitDialogOpen} className={classes.submitMargin} variant="contained" color="primary">
                            Approve
                        </Button>
                    )
                }
                {
                    value !== PISignOffStatus.Complete && canCompletePISignOffForm && piSignOff?.formCount == 1 && !showApproveButton && (
                        <Tooltip title={<>All data change requests must be actioned before the <span style={{whiteSpace: 'nowrap'}}>sign-off</span> can be approved.</>}>
                            <span>
                                <Button onClick={onSubmitDialogOpen} className={classes.submitMargin} variant="contained" color="primary" disabled>
                                    Approve
                                </Button>
                            </span>
                        </Tooltip>
                    )
                }
                {
                    value !== PISignOffStatus.Complete && canCompletePISignOffForm && piSignOff?.formCount != 1 && (
                        <SubmitButton onClick={onSubmitClick} variant="contained" color="primary">
                            Mark Complete
                        </SubmitButton>
                    )
                }
            </div>
        </>
    )
}

const ContextPISignOffForm = <TData extends IPISignOffForm = IPISignOffForm, TValidationResult extends IValidationResult = IValidationResult>({
    entity,
    onSubmitFailed,
    onSubmitValidationFailed,
    children,
    onAfterSubmit,
    queriesName,
    onSubmit,
    ...formProps
}: IContextPISignOffFormProps<TData, TValidationResult>) => {
    const navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar();
    const { data: formDefinition } = useContext(FormDefinitionContext);
    const { data: form, save } = useContext(FormContext);
    const { data: piSignOffBatch } = useContext(PISignOffBatchContext);
    const { data: piSignOff } = useContext(PISignOffContext);
    const { data: piSignOffDefinition } = useContext(PISignOffDefinitionContext);
    const { data: patient } = useContext(PatientContext);
    const { createPISignOffRouteFn } = useContext(PISignOffExtensionContext);
    const { createPISignOffBatchRouteFn } = useContext(PISignOffExtensionContext);
    const typedForm: TData = form as any;

    const singleForm = piSignOff?.formCount === 1;

    const entityName = useMemo(() => {
        return entity ?? formDefinition?.name ?? 'Unknown';
    }, [formDefinition, entity]);

    const onAfterSubmitToUse: IFormSubmit<TData, IValidationError> = useCallback(async (formState, formActions) => {
        if (onAfterSubmit) {
            return onAfterSubmit(formState, formActions)
        }
        
        if (patient && piSignOff && piSignOffBatch && piSignOffDefinition && formState.value?.status === PISignOffStatus.Complete) {
            navigate(createPISignOffRouteFn(piSignOffBatch, piSignOffDefinition, patient, piSignOff))
        }
    }, [createPISignOffRouteFn, patient, piSignOff, onAfterSubmit, history, piSignOffBatch, piSignOffDefinition])

    const onSubmitValidationFailure: IFormSubmitValidationFailed<TData, IValidationError> = useCallback(async (formState, formActions, validationError: boolean) => {
        

        const isDataChangeRequest = (formState?.value as any)?.isDataChangeRequest as boolean | undefined;
        const dataChangeRequestType = (formState?.value as any)?.dataChangeRequestType as QueryStatus | undefined;
        formActions?.setFieldValue('isDataChangeRequest', undefined, false, false, false, false);
        formActions?.setFieldValue('dataChangeRequestType', undefined, false, false, false, false);

        const dataChangeRequestTitleAction = 
            dataChangeRequestType === QueryStatus.Issued ? "Submission Failed" : 
            dataChangeRequestType === QueryStatus.Cancelled ? "Cancellation Failed" : 
            dataChangeRequestType === QueryStatus.Closed ? "Close Failed" : 
            dataChangeRequestType === QueryStatus.Responded ? "Response Failed" : 
            "";

        const dataChangeRequestAction = 
            dataChangeRequestType === QueryStatus.Issued ? "submit" : 
            dataChangeRequestType === QueryStatus.Cancelled ? "cancel" : 
            dataChangeRequestType === QueryStatus.Closed ? "close" : 
            dataChangeRequestType === QueryStatus.Responded ? "respond to" : 
            "";

        formActions?.setFieldValue('status', typedForm?.status, false, false, false, false);

        if (onSubmitValidationFailed) {
            return onSubmitValidationFailed(formState, formActions, validationError);
        }

        const entityTitleCase = titleCase(entityName);
        const entityLowerCase = entityName?.toLowerCase();

        const { errors } = formState;

        if (validationError) {
            if (isDataChangeRequest) {
                enqueueSnackbar(
                    <>
                        <AlertTitle>
                            Data Change Request {dataChangeRequestTitleAction}
                        </AlertTitle>
                        An error occurred while attempting to validate the data change request.
                    </>,
                    { variant: 'error-critical' }
                );
            }
            else if (singleForm) {
                enqueueSnackbar(
                    <>
                        <AlertTitle>
                            Participant eCRF Not Approved
                        </AlertTitle>
                        An error occurred while attempting to validate the participant eCRF.
                    </>,
                    { variant: 'error-critical' }
                );
            }
            else {
                enqueueSnackbar(
                    <>
                        <AlertTitle>
                            {entityTitleCase} Not Saved
                        </AlertTitle>
                        An error occurred while attempting to validate the {entityLowerCase}.
                    </>,
                    { variant: 'error-critical' }
                );
            }
        }
        else {

            const criticalErrors = Object
                .keys(errors)
                .reduce((array: IValidationError[], key: string) => {
                    const propertyErrors = errors[key]?.reduce((propertyArray: IValidationError[], e: IValidationError) => {
                        if (e.type !== ValidationErrorType.Warning) {
                            return [...propertyArray, e]
                        }

                        return propertyArray;
                    }, [])

                    return [...array, ...propertyErrors]
                }, []);

            if (isDataChangeRequest) {
                enqueueSnackbar(
                    <>
                        <AlertTitle>
                            Data Change Request {dataChangeRequestTitleAction}
                        </AlertTitle>
                        Please correct the {criticalErrors.length} blocking {pluralize('error', criticalErrors.length)} and {dataChangeRequestAction} the data change request again.
                    </>,
                    { variant: 'error-critical' }
                );
            }
            else if (singleForm) {
                enqueueSnackbar(
                    <>
                        <AlertTitle>
                            Participant eCRF Not Approved
                        </AlertTitle>
                        Please correct the {criticalErrors.length} blocking {pluralize('error', criticalErrors.length)} and approve the participant eCRF again.
                    </>,
                    { variant: 'error-critical' }
                );
            }
            else {
                enqueueSnackbar(
                    <>
                        <AlertTitle>
                            {entityTitleCase} Not Saved
                        </AlertTitle>
                        Please correct the {criticalErrors.length} blocking {pluralize('error', criticalErrors.length)} and submit the {entityLowerCase} again.
                    </>,
                    { variant: 'error-critical' }
                );
            }
        }
    }, [enqueueSnackbar, entityName, onSubmitValidationFailed, typedForm, singleForm]);

    const onFormSubmitFailure: IFormSubmitFailed<TData, IValidationError> = useCallback(async (formState, formActions) => {

        const isDataChangeRequest = (formState?.value as any)?.isDataChangeRequest as boolean | undefined;
        const dataChangeRequestType = (formState?.value as any)?.dataChangeRequestType as QueryStatus | undefined;
        const dataChangeRequestTitleAction = 
            dataChangeRequestType === QueryStatus.Issued ? "Submission Failed" : 
            dataChangeRequestType === QueryStatus.Cancelled ? "Cancellation Failed" : 
            dataChangeRequestType === QueryStatus.Closed ? "Close Failed" : 
            dataChangeRequestType === QueryStatus.Responded ? "Response Failed" : 
            "";

        const dataChangeRequestAction = 
            dataChangeRequestType === QueryStatus.Issued ? "submit" : 
            dataChangeRequestType === QueryStatus.Cancelled ? "cancel" : 
            dataChangeRequestType === QueryStatus.Closed ? "close" : 
            dataChangeRequestType === QueryStatus.Responded ? "respond to" : 
            "";
        formActions?.setFieldValue('isDataChangeRequest', undefined, false, false, false, false);
        formActions?.setFieldValue('dataChangeRequestType', undefined, false, false, false, false);

        formActions?.setFieldValue('status', typedForm?.status, false, false, false, false);

        if (onSubmitFailed) {
            return onSubmitFailed(formState, formActions, null);
        }

        formActions.reset(true);

        const entityTitleCase = titleCase(entityName);
        const entityLowerCase = entityName?.toLowerCase();


        
        if (isDataChangeRequest) {
            enqueueSnackbar(
                <>
                    <AlertTitle>
                        Data Change Request {dataChangeRequestTitleAction}
                    </AlertTitle>
                    An error occurred while attempting to {dataChangeRequestAction} the data change request.
                </>,
                { variant: 'error-critical' }
            );
        }
        else if (singleForm) {
            enqueueSnackbar(
                <>
                    <AlertTitle>
                        Participant eCRF Not Approved
                    </AlertTitle>
                    An error occurred while attempting to save the participant eCRF.
                </>,
                { variant: 'error-critical' }
            );
        }
        else {
            enqueueSnackbar(
                <>
                    <AlertTitle>
                        {entityTitleCase} Not Saved
                    </AlertTitle>
                    An error occurred while attempting to save the {entityLowerCase}.
                </>,
                { variant: 'error-critical' }
            );
        }
    }, [onSubmitFailed, entityName, typedForm, enqueueSnackbar, singleForm]);
    
    const handleSubmit: IFormSubmit<TData, IValidationError> = useCallback(async (formState, formActions) => {
        if (onSubmit) {
            return onSubmit(formState, formActions);
        }

        const { value, errors } = formState;
        const { submitType, ...form } = value as any;

        if (!save) {
            return {};
        }

        const entityTitleCase = titleCase(entityName);
        const entityLowerCase = entityName?.toLowerCase();

        const response = await save(form, formProps?.metadata, false);

        const allErrors = Object
            .keys(errors)
            .reduce((array: IValidationError[], key: string) => {
                const propertyErrors = errors[key]?.reduce((propertyArray: IValidationError[], e: IValidationError) => {
                    return [...propertyArray, e]
                }, [])

                return [...array, ...propertyErrors]
            }, []);

        const maxErrorType = allErrors.reduce((maxError: ValidationErrorType | undefined, error) => (error.type ?? 0) > (maxError ?? 0) ? error.type : maxError, undefined);


        const isDataChangeRequest = (formState?.value as any)?.isDataChangeRequest as boolean | undefined;
        const dataChangeRequestType = (formState?.value as any)?.dataChangeRequestType as QueryStatus | undefined;
        const dataChangeRequestTitleAction = 
            dataChangeRequestType === QueryStatus.Issued ? "Submitted" : 
            dataChangeRequestType === QueryStatus.Cancelled ? "Cancelled" : 
            dataChangeRequestType === QueryStatus.Closed ? "Closed" : 
            dataChangeRequestType === QueryStatus.Responded ? "Response Sent" : 
            "";

        const dataChangeRequestAction = 
            dataChangeRequestType === QueryStatus.Issued ? "submitted" : 
            dataChangeRequestType === QueryStatus.Cancelled ? "cancelled" : 
            dataChangeRequestType === QueryStatus.Closed ? "closed" : 
            dataChangeRequestType === QueryStatus.Responded ? "responded to" : 
            "";

        formActions?.setFieldValue('isDataChangeRequest', undefined, false, false, false, false);
        formActions?.setFieldValue('dataChangeRequestType', undefined, false, false, false, false);

        if (maxErrorType) {

            const scopedErrors = allErrors.filter(e => e.type === maxErrorType);

            if (isDataChangeRequest) {
                enqueueSnackbar(
                    <>
                        <AlertTitle>
                            Data Change Request {dataChangeRequestTitleAction}
                        </AlertTitle>
                        The data change request was successfully {dataChangeRequestAction} but contained {scopedErrors.length} {pluralize(errorTextMapping[maxErrorType], scopedErrors.length)}.
                    </>,
                    { variant: errorVariantMapping[maxErrorType] }
                );
            }
            else if (singleForm) {
                enqueueSnackbar(
                    <>
                        <AlertTitle>
                            Participant eCRF Successfully Approved
                        </AlertTitle>
                        The participant eCRF was successfully approved but contained {scopedErrors.length} {pluralize(errorTextMapping[maxErrorType], scopedErrors.length)}.
                    </>,
                    { variant: errorVariantMapping[maxErrorType] }
                );
            }
            else {
                enqueueSnackbar(
                    <>
                        <AlertTitle>
                            {entityTitleCase} Saved
                        </AlertTitle>
                        The {entityLowerCase} has been successfully saved but contained {scopedErrors.length} {pluralize(errorTextMapping[maxErrorType], scopedErrors.length)}.
                    </>,
                    { variant: errorVariantMapping[maxErrorType] }
                );
            }
        }
        else {

            if (isDataChangeRequest) {
                enqueueSnackbar(
                    <>
                        <AlertTitle>
                            Data Change Request {dataChangeRequestTitleAction}
                        </AlertTitle>
                        The data change request was successfully {dataChangeRequestAction}.
                    </>,
                    { variant: 'success' }
                );
            }
            else if (singleForm) {
                enqueueSnackbar(
                    <>
                        <AlertTitle>
                            Participant eCRF Successfully Approved
                        </AlertTitle>
                        The Participant eCRF has been successfully approved.
                    </>,
                    { variant: 'success' }
                );
            }
            else {
                enqueueSnackbar(
                    <>
                        <AlertTitle>
                            {entityTitleCase} Saved
                        </AlertTitle>
                        The {entityLowerCase} was successfully saved.
                    </>,
                    { variant: 'success' }
                );
            }
        }

        if (formState.value?.status === PISignOffStatus.Complete) {
            if(piSignOffBatch && piSignOffDefinition){
                navigate(createPISignOffBatchRouteFn(piSignOffBatch, piSignOffDefinition));
            }
        }

        if (onAfterSubmit) {
            return onAfterSubmit({ ...formState, value: response! as any }, formActions);
        }

    }, [onSubmit, save, enqueueSnackbar, entity, formProps?.metadata, onAfterSubmit])

    return (
        <ContextForm
            {...formProps}
            entity={entity}
            onSubmit={handleSubmit}
            onSubmitFailed={onFormSubmitFailure}
            onSubmitValidationFailed={onSubmitValidationFailure}
            onAfterSubmit={onAfterSubmitToUse}
        >
            {children}
            <FieldProvider name="piSignOffStatus" />
            <GroupedErrorDisplay />
            <CompleteButton entityName={entityName} queriesName={queriesName}/>
        </ContextForm>
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default ContextPISignOffForm;