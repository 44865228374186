import { faChevronDown, faChevronUp } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Collapse, darken, lighten, ListItem, ListItemIcon, ListItemText, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { ComponentType, FunctionComponent, useCallback, useMemo } from 'react';
import { IFormDefinition, IFormType, IValidationResult } from '@ngt/forms';
import { IPISignOff, IPISignOffBatch, IPISignOffDefinition, PISignOffStatus } from '../../api/dtos';
import PISignOffSummaryListForm from './PISignOffSummaryListForm';
import { faSlash } from '@fortawesome/pro-regular-svg-icons';
import classNames from 'classnames';
import { IPatient } from '@ngt/forms-trials';

interface IPISignOffSummaryListTypeProps {
    patient?: IPatient;
    piSignOffDefinition?: IPISignOffDefinition;
    piSignOff?: IPISignOff;
    piSignOffBatch?: IPISignOffBatch;
    //reviewer?: IReviewer;
    expanded: boolean;
    toggleExpanded: (id: number) => void;
    forms: Array<{ formDefinition: IFormDefinition; formValidation: IValidationResult, hasMultiple: boolean; }>;
    formType: IFormType;
    action?: ComponentType<IPISignOffSummaryFormTypeActionProps>;
}

export interface IPISignOffSummaryFormTypeActionProps {
    patient?: IPatient;
    piSignOffDefinition?: IPISignOffDefinition;
    piSignOff?: IPISignOff;
    piSignOffBatch?: IPISignOffBatch;
    //reviewer?: IReviewer;
    forms: Array<{ formDefinition: IFormDefinition; formValidation: IValidationResult, hasMultiple: boolean; }>;
    formType: IFormType;
}

const useStyles = makeStyles((theme: Theme) => {
    const getColor = theme.palette.mode === 'light' ? darken : lighten;
    const getBackgroundColor = theme.palette.mode === 'light' ? lighten : darken;
    const getBorderColor = theme.palette.mode === 'light' ? lighten : darken;

    return {
        container: {
            display: 'flex',
            marginTop: -1
        },
        item: {
            paddingTop: 0,
            paddingBottom: 0,
            paddingRight: 0,
            borderTop: `1px solid ${theme.palette.grey[300]}`,
            borderBottom: `1px solid ${theme.palette.grey[300]}`
        },
        reviews: {
            paddingLeft: theme.spacing(6)
        },
        title: {
            display: 'flex',
            alignItems: 'center',
            paddingTop: 4,
            paddingBottom: 4,
        },
        titleMain: {
            marginRight: 'auto'
        },
        titleCount: {
            alignSelf: 'stretch',
            minWidth: 140,
            display: 'flex',
            padding: theme.spacing(0, 2),
            borderWidth: 1,
            borderStyle: 'solid',
            justifyContent: 'space-between',

            borderRadius: 0,

            color: getColor(theme.palette.grey[700], 0.6),
            backgroundColor: getBackgroundColor(theme.palette.grey[700], 0.9),
            borderColor: getBorderColor(theme.palette.grey[700], 0.8),
        },
        new: {
            color: getColor(theme.palette.info.main, 0.6),
            backgroundColor: getBackgroundColor(theme.palette.info.main, 0.9),
            borderColor: getBorderColor(theme.palette.info.main, 0.8),
        },
        inProgress: {
            color: getColor(theme.palette.warning.main, 0.6),
            backgroundColor: getBackgroundColor(theme.palette.warning.main, 0.9),
            borderColor: getBorderColor(theme.palette.warning.main, 0.8),
        },
        complete: {
            color: getColor(theme.palette.success.main, 0.6),
            backgroundColor: getBackgroundColor(theme.palette.success.main, 0.9),
            borderColor: getBorderColor(theme.palette.success.main, 0.8),
        },
        completedForms: {
            maxWidth: 40,
            minWidth: 40,
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
        },
        divider: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
        },
        totalForms: {
            maxWidth: 40,
            minWidth: 40,
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
        },
    }
});


const PISignOffSummaryListType: FunctionComponent<IPISignOffSummaryListTypeProps> = ({
    expanded,
    piSignOffDefinition,
    piSignOff,
    piSignOffBatch,
    //reviewer,
    forms,
    formType,
    toggleExpanded,
    action,
    patient
}) => {
    const classes = useStyles();

    const onClick = useCallback(() => {
        toggleExpanded(formType.id!)

    }, [formType.id, toggleExpanded]);

    const ActionComponent = action;

    const count = useMemo(() => {
        const totalForms = forms.filter(x => x.formValidation?.metadata?.Status !== PISignOffStatus.Cancelled)?.length ?? 0;
        const inProgressForms = forms.filter(x => x.formValidation?.metadata?.Status === PISignOffStatus.InProgress)?.length ?? 0;
        const completeForms = forms.filter(x => x.formValidation?.metadata?.Status === PISignOffStatus.Complete)?.length ?? 0;

        if (totalForms === 0) {
            return null;
        }

        return (
            <div
                className={classNames(
                    classes.titleCount,
                    { [classes.new]: completeForms === 0 },
                    { [classes.inProgress]: inProgressForms > 0 && completeForms !== totalForms },
                    { [classes.complete]: completeForms === totalForms }
                )}
            >
                <div
                    className={classes.completedForms}
                >
                    {completeForms}
                </div>
                <div
                    className={classes.divider}
                >
                    <FontAwesomeIcon fixedWidth icon={faSlash} flip="horizontal" />
                </div>
                <div
                    className={classes.totalForms}
                >
                    {totalForms}
                </div>
            </div>
        )
    }, [forms, classes])

    return (
        <>
            <div
                className={classes.container}
            >
                <ListItem
                    button
                    className={classes.item}
                    onClick={onClick}
                >
                    <ListItemIcon>
                        <FontAwesomeIcon fixedWidth icon={expanded ? faChevronUp : faChevronDown} />
                    </ListItemIcon>
                    <ListItemText
                        primary={(
                            <div className={classes.title}>
                                <Typography variant="subtitle1" className={classes.titleMain}>
                                    <strong>{formType?.name}</strong>
                                </Typography>
                            </div>
                        )}
                    />
                    {count}
                </ListItem>
                {
                    !!ActionComponent && !!formType && (
                        <ActionComponent
                            patient={patient}
                            piSignOffDefinition={piSignOffDefinition}
                            piSignOff={piSignOff}
                            piSignOffBatch={piSignOffBatch}
                            forms={forms}
                            formType={formType}
                            //reviewer={reviewer}
                        />
                    )
                }
            </div>
            <Collapse
                in={expanded}
            >
                <div
                    className={classes.reviews}
                >
                    {
                        !!piSignOffDefinition && !!forms?.length && (
                            forms?.map((form) => {
                                return (
                                    <PISignOffSummaryListForm
                                        key={`${form.formDefinition.id}-${form.formValidation?.metadata?.Id?.toString()}`}
                                        patient={patient}
                                        piSignOffDefinition={piSignOffDefinition}
                                        piSignOff={piSignOff}
                                        piSignOffBatch={piSignOffBatch}
                                        formValidation={form.formValidation}
                                        formDefinition={form.formDefinition}
                                        hasMultiple={form.hasMultiple}
                                        formType={formType}
                                        //reviewer={reviewer}
                                    />
                                );
                            })
                        )
                    }
                </div>
            </Collapse>
        </>
    );
};

export default PISignOffSummaryListType;
