import { useCallback, useContext, useMemo } from 'react';
import useSWR, { SWRConfiguration, mutate } from 'swr';
import { BoundMutator, FormMetadata, Forms, FormsContext, IDataSingleResponse, IValidationResponse, IValidationResult } from '@ngt/forms';
import { IPISignOffForm } from '../../api/dtos';

// @ts-ignore: declared but not used 'url'
const fetcher = async <TPISignOffForm extends IPISignOffForm = IPISignOffForm>(url: string, forms: Forms, formMetadata: FormMetadata, piSignOffId: number, repeat: number, data?: TPISignOffForm, metadata?: Record<string, any>) => {
    if (!piSignOffId || !repeat) {
        return await create<TPISignOffForm>(forms, formMetadata, data, metadata);
    }

    return await get<TPISignOffForm>(forms, formMetadata, piSignOffId, repeat)
}

const create = async <TPISignOffForm extends IPISignOffForm = IPISignOffForm>(forms: Forms, formMetadata: FormMetadata, data?: TPISignOffForm, metadata?: Record<string, any>) => {
    const requestType = forms.dtos[`${formMetadata.name}PostCreate`]

    const response: IDataSingleResponse<TPISignOffForm> = await forms.serviceStackClient.post(new requestType({ data, metadata }));

    return response;
}

const get = async < TPISignOffForm extends IPISignOffForm = IPISignOffForm > (forms: Forms, formMetadata: FormMetadata, piSignOffId: number, repeat: number) => {
    const requestType = forms.dtos[`${formMetadata.name}GetSingleByPISignOffIdAndRepeat`]

    const response: IDataSingleResponse<TPISignOffForm> = await forms.serviceStackClient.get(new requestType({ piSignOffId, repeat }));

    return response;
}

// @ts-ignore: declared but not used 'url'
const save = async <TPISignOffForm extends IPISignOffForm = IPISignOffForm>(forms: Forms, formMetadata: FormMetadata, data: TPISignOffForm, metadata: Record<string, any> | undefined) => {
    const requestType = forms.dtos[`${formMetadata.name}PostSave`]

    const response: IDataSingleResponse<TPISignOffForm> = await forms.serviceStackClient.post(new requestType({ data, metadata }));

    return response;
}

const validate = async <TPISignOffForm extends IPISignOffForm = IPISignOffForm, TValidationResult extends IValidationResult = IValidationResult>(forms: Forms, formMetadata: FormMetadata, data: TPISignOffForm, metadata: Record<string, any> | undefined) => {
    const requestType = forms.dtos[`${formMetadata.name}PostValidate`]

    const response: IValidationResponse<TValidationResult> = await forms.serviceStackClient.post(new requestType({ data, metadata }));

    return response;
}

const update = async <TPISignOffForm extends IPISignOffForm = IPISignOffForm>(forms: Forms, formMetadata: FormMetadata, data: TPISignOffForm, metadata: Record<string, any> | undefined) => {
    const requestType = forms.dtos[`${formMetadata.name}PostUpdateById`]

    const response: IDataSingleResponse<TPISignOffForm> = await forms.serviceStackClient.post(new requestType({ id: data?.id, metadata }));

    return response;
}

const usePISignOffFormByPISignOffIdAndRepeat = <TPISignOffForm extends IPISignOffForm = IPISignOffForm, TValidationResult extends IValidationResult = IValidationResult>(formDefinitionIdentifier?: number | string | null, piSignOffId?: number | null, repeat?: number | null, createData?: TPISignOffForm, createMetadata?: Record<string, any>, configuration?: SWRConfiguration<IDataSingleResponse<TPISignOffForm>, IDataSingleResponse<TPISignOffForm>>) => {
    const forms = useContext(FormsContext);

    const formMetadata = useMemo(() => {
        if (typeof formDefinitionIdentifier === 'number') {
            return forms.formMetadata.find(fm => fm.formDefinitionId === formDefinitionIdentifier);
        }

        return forms.formMetadata.find(fm => fm.formDefinitionCode === formDefinitionIdentifier);
    }, [forms.formMetadata, formDefinitionIdentifier]);

    const cacheKey = useMemo(() => {
        if (!formMetadata) {
            return null;
        }

        if ((!piSignOffId || !repeat) && !createData && !createMetadata) {
            return null;
        }

        if (!piSignOffId || !repeat) {
            return [`${forms.baseRoute}/form/${formMetadata?.formDefinitionCode}/single/pi-signoff-id-repeat`, forms, formMetadata, null, null, createData, createMetadata]
        }

        return [`${forms.baseRoute}/form/${formMetadata?.formDefinitionCode}/single/pi-signoff-id-repeat`, forms, formMetadata, piSignOffId, repeat, null, null]
    }, [forms, piSignOffId, repeat, formMetadata, createData, createMetadata])

    const { data, error } = useSWR<IDataSingleResponse<TPISignOffForm>, IDataSingleResponse<TPISignOffForm>>(cacheKey, fetcher, configuration);

    const boundMutate: BoundMutator<IDataSingleResponse<TPISignOffForm>> = useCallback((newData, shouldRevalidate) => {
        return mutate(cacheKey, newData, shouldRevalidate);
    }, [cacheKey]);

    const boundSave = useCallback(async (saveData: TPISignOffForm, metadata?: Record<string, any>, shouldRevalidate?: boolean) => {
        if (formMetadata) {

            const response = await save<TPISignOffForm>(forms, formMetadata, saveData, metadata);

            if (cacheKey) {
                // !!cacheKey[3] = has an id.
                const revalidate = shouldRevalidate ?? !!cacheKey[3];

                const updated = await boundMutate(response, revalidate);

                return updated?.data;
            }

            return response?.data;
        }

        return null;
    }, [boundMutate, forms, formMetadata, cacheKey]);

    const boundUpdate = useCallback(async (form: TPISignOffForm, metadata?: Record<string, any>, shouldRevalidate?: boolean) => {
        if (formMetadata) {

            const response = await update<TPISignOffForm>(forms, formMetadata, form, metadata);

            if (cacheKey) {
                // !!cacheKey[3] = has an id.
                const revalidate = shouldRevalidate ?? !!cacheKey[3];

                const updated = await boundMutate(response, revalidate);

                return updated?.data;
            }

            return response?.data;
        }

        return null;
    }, [boundMutate, forms, formMetadata, cacheKey]);

    const boundValidate = useCallback(async (validateData: TPISignOffForm, metadata?: Record<string, any>) => {
        if (formMetadata) {
            const response = await validate<TPISignOffForm, TValidationResult>(forms, formMetadata, validateData, metadata);

            return response?.validationResult;
        }

        return null;
    }, [forms, formMetadata]);


    const result = useMemo(() => {
        return {
            data: data?.data as TPISignOffForm | undefined | null,
            error: error?.responseStatus,
            loading: data === undefined && error === undefined,
            mutate: boundMutate,
            save: boundSave,
            update: boundUpdate,
            validate: boundValidate
        };
    }, [boundMutate, data, error, boundValidate, boundSave])

    return result;
}

export default usePISignOffFormByPISignOffIdAndRepeat;