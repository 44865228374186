import { faCheckCircle, faChevronDown, faChevronUp, faCircle, faCommentAltLines, faExclamationCircle, faMinusCircle, faTimesCircle } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Collapse, darken, lighten, ListItem, ListItemButton, ListItemIcon, ListItemText, Theme, Tooltip, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { ComponentType, FunctionComponent, useCallback, useContext, useMemo } from 'react';
import { IFormDefinition, IFormType, IValidationResult } from '@ngt/forms';
import { IPISignOff, IPISignOffBatch, IPISignOffDefinition, PISignOffStatus } from '../../api/dtos';
import { faSlash } from '@fortawesome/pro-regular-svg-icons';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import PISignOffExtensionContext from '../../contexts/PISignOffExtensionContext';
import { IPatient } from '@ngt/forms-trials';

interface IPISignOffSummaryListQueriesProps {
    patient?: IPatient;
    piSignOffDefinition?: IPISignOffDefinition;
    piSignOffBatch?: IPISignOffBatch;
    piSignOff?: IPISignOff;
    //reviewer?: IReviewer;
    expanded: boolean;
    toggleExpanded: (id: number) => void;
    forms: Array<{ formDefinition: IFormDefinition; formValidation: IValidationResult }>;
    formTypes: IFormType[];
    action?: ComponentType<IPISignOffSummaryQueryActionProps>;
}

export interface IPISignOffSummaryQueryActionProps {
    piSignOffBatch?: IPISignOffBatch;
    patient?: IPatient;
    piSignOffDefinition?: IPISignOffDefinition;
    piSignOff?: IPISignOff;
    //reviewer?: IReviewer;
    forms: Array<{ formDefinition: IFormDefinition; formValidation: IValidationResult }>;
    formTypes: IFormType[];
}

const useStyles = makeStyles((theme: Theme) => {
    const getColor = theme.palette.mode === 'light' ? darken : lighten;
    const getBackgroundColor = theme.palette.mode === 'light' ? lighten : darken;
    const getBorderColor = theme.palette.mode === 'light' ? lighten : darken;

    return {
        container: {
            display: 'flex',
            marginTop: -1
        },
        item: {
            paddingTop: 0,
            paddingBottom: 0,
            paddingRight: 0,
            borderTop: `1px solid ${theme.palette.grey[300]}`,
            borderBottom: `1px solid ${theme.palette.grey[300]}`
        },
        reviews: {
            paddingLeft: theme.spacing(6)
        },
        title: {
            display: 'flex',
            alignItems: 'center',
            paddingTop: 4,
            paddingBottom: 4,
        },
        titleMain: {
            marginRight: 'auto'
        },
        titleCount: {
            alignSelf: 'stretch',
            minWidth: 140,
            display: 'flex',
            padding: theme.spacing(0, 2),
            borderWidth: 1,
            borderStyle: 'solid',
            justifyContent: 'space-between',

            borderRadius: 0,

            color: getColor(theme.palette.grey[700], 0.6),
            backgroundColor: getBackgroundColor(theme.palette.grey[700], 0.9),
            borderColor: getBorderColor(theme.palette.grey[700], 0.8),
        },
        new: {
            color: getColor(theme.palette.info.main, 0.6),
            backgroundColor: getBackgroundColor(theme.palette.info.main, 0.9),
            borderColor: getBorderColor(theme.palette.info.main, 0.8),
        },
        inProgress: {
            color: getColor(theme.palette.warning.main, 0.6),
            backgroundColor: getBackgroundColor(theme.palette.warning.main, 0.9),
            borderColor: getBorderColor(theme.palette.warning.main, 0.8),
        },
        complete: {
            color: getColor(theme.palette.success.main, 0.6),
            backgroundColor: getBackgroundColor(theme.palette.success.main, 0.9),
            borderColor: getBorderColor(theme.palette.success.main, 0.8),
        },
        queryItem: {
            paddingTop: 0,
            paddingBottom: 0,
            paddingRight: 0,
            marginTop: -1,
            borderLeft: `1px solid ${theme.palette.grey[300]}`,
            borderTop: `1px solid ${theme.palette.grey[300]}`,
            borderBottom: `1px solid ${theme.palette.grey[300]}`
        } as any,
        queryNew: {
            color: theme.palette.info.main
        },
        queryInProgress: {
            color: theme.palette.warning.main
        },
        queryComplete: {
            color: theme.palette.success.main
        },
        queryCancelled: {
            color: theme.palette.grey[500]
        },
        queryUnknown: {
            color: theme.palette.error.main
        },
        completedForms: {
            maxWidth: 40,
            minWidth: 40,
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
        },
        divider: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
        },
        totalForms: {
            maxWidth: 40,
            minWidth: 40,
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
        },
        icon: {
            borderRight: `1px solid ${theme.palette.grey[300]}`,
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(1)
        },
        text: {
            margin: 0,
            paddingLeft: theme.spacing(2)
        },
        queryIcon: {
            maxWidth: 40,
            minWidth: 40,
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
        },
        queryCount: {
            maxWidth: 40,
            minWidth: 40,
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
        }
    }
});


const PISignOffSummaryListQueries: FunctionComponent<IPISignOffSummaryListQueriesProps> = ({
    expanded,
    piSignOffDefinition,
    piSignOff,
    piSignOffBatch,
    //reviewer,
    forms,
    formTypes,
    toggleExpanded,
    action,
    patient
}) => {
    const classes = useStyles();

    const piSignOffContext = useContext(PISignOffExtensionContext);

    const onClick = useCallback(() => {
        toggleExpanded(-1)

    }, [toggleExpanded]);

    const ActionComponent = action;

    const querySummary = useMemo(() => {
        let total = 0;
        let issued = 0;
        let responded = 0; // eslint-disable-line
        let closed = 0;
        let cancelled = 0;

        forms.forEach(form => {
            total += (form.formValidation?.metadata?.Queries as unknown as number) ?? 0;
            issued += (form.formValidation?.metadata?.IssuedQueries as unknown as number) ?? 0;
            responded += (form.formValidation?.metadata?.RespondedQueries as unknown as number) ?? 0; // eslint-disable-line
            closed += (form.formValidation?.metadata?.ClosedQueries as unknown as number) ?? 0;
            cancelled += (form.formValidation?.metadata?.CancelledQueries as unknown as number) ?? 0;
        });

        total = total - cancelled;

        let icon = (
            <Tooltip title="Unknown">
                <div>
                    <FontAwesomeIcon fixedWidth icon={faExclamationCircle} className={classes.queryUnknown} size="2x" />
                </div>
            </Tooltip>
        );

        if (piSignOff?.status === PISignOffStatus.Cancelled) {
            icon = (
                <Tooltip title="Cancelled">
                    <div>
                        <FontAwesomeIcon fixedWidth icon={faTimesCircle} className={classes.queryCancelled} size="2x" />
                    </div>
                </Tooltip>
            )
        }
        else if (piSignOff?.status === PISignOffStatus.New) {
            icon = (
                <Tooltip title="New">
                    <div>
                        <FontAwesomeIcon fixedWidth icon={faCircle} className={classes.queryNew} size="2x" />
                    </div>
                </Tooltip>
            )
        }
        else if (total !== closed) {
            icon = (
                <Tooltip title="In Progress">
                    <div>
                        <FontAwesomeIcon fixedWidth icon={faMinusCircle} className={classes.queryInProgress} size="2x" />
                    </div>
                </Tooltip>
            );
        }
        else if (total === closed) {
            icon = (
                <Tooltip title="Complete">
                    <div>
                        <FontAwesomeIcon fixedWidth icon={faCheckCircle} className={classes.queryComplete} size="2x" />
                    </div>
                </Tooltip>
            );
        }

        if (issued === 0) {
            return {
                icon,
                queries: null,
                count: null
            };
        }

        return {
            icon,
            queries: (
                <div className={classes.titleCount}>
                    <div className={classes.queryIcon}>
                        <span>
                            <FontAwesomeIcon icon={faCommentAltLines} size="lg" />
                        </span>
                    </div>
                    <div className={classes.divider}>
                    </div>
                    <div className={classes.queryCount}>
                        {issued}
                    </div>
                </div>
            ),
            count: (
                <div
                    className={classNames(
                        classes.titleCount,
                        { [classes.new]: total === 0 },
                        { [classes.inProgress]: closed !== total },
                        { [classes.complete]: closed === total }
                    )}
                >
                    <div
                        className={classes.completedForms}
                    >
                        {closed}
                    </div>
                    <div
                        className={classes.divider}
                    >
                        <FontAwesomeIcon fixedWidth icon={faSlash} flip="horizontal" />
                    </div>
                    <div
                        className={classes.totalForms}
                    >
                        {total}
                    </div>
                </div>
            )
        };
    }, [forms, piSignOff, classes])

    return (
        <>
            <div
                className={classes.container}
            >
                <ListItem
                    button
                    className={classes.item}
                    onClick={onClick}
                >
                    <ListItemIcon>
                        <FontAwesomeIcon fixedWidth icon={expanded ? faChevronUp : faChevronDown} />
                    </ListItemIcon>
                    <ListItemText
                        primary={(
                            <div className={classes.title}>
                                <Typography variant="subtitle1" className={classes.titleMain}>
                                    <strong>Queries</strong>
                                </Typography>
                            </div>
                        )}
                    />
                    {querySummary.count}
                </ListItem>
                {
                    !!ActionComponent && (
                        <ActionComponent
                            patient={patient}
                            piSignOffDefinition={piSignOffDefinition}
                            piSignOff={piSignOff}
                            piSignOffBatch={piSignOffBatch}
                            forms={forms}
                            formTypes={formTypes}
                            //reviewer={reviewer}
                        />
                    )
                }
            </div>
            <Collapse
                in={expanded}
            >
                <div
                    className={classes.reviews}
                >
                    <ListItemButton
                        className={classes.queryItem}
                        component={Link}
                        to={piSignOffContext?.createPISignOffQueriesRouteFn(piSignOffBatch!, piSignOffDefinition!, patient!, piSignOff!)}
                    >
                        <ListItemIcon
                            className={classes.icon}
                        >
                            {querySummary.icon}
                        </ListItemIcon>
                        <ListItemText
                            className={classes.text}
                            primary={
                                <div className={classes.title}>
                                    <Typography variant="subtitle1" className={classes.titleMain}>
                                        Query Summary
                                    </Typography>
                                </div>
                            }
                        />
                        {querySummary.queries}
                    </ListItemButton>
                </div>
            </Collapse>
        </>
    );
};

export default PISignOffSummaryListQueries;
