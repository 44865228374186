/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the context for the currently selected form.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a context.
 */
import * as React from 'react';
import { FormContext, FormDefinitionContext, FormTypeContext, FormDefinitionSingleResponse, IDataSingleResponse, LookupCollectionResponse, LookupsContext, RouteLoading, useFormDefinitionByCode, useLookupsByFormDefinitionId, FormTypeSingleResponse, useFormTypeById } from '@ngt/forms';
import { IPISignOffForm, PISignOffDefinitionSingleResponse, PISignOffSingleResponse, InvestigatorSingleResponse, PISignOffBatchSingleResponse, InvestigatorCollectionResponse, UpdatedFormPropertyCollectionResponse, QueryStatus } from '../../api/dtos';
import { SWRConfiguration } from 'swr/dist/types';
import {
    CoordinatingGroupContext,
    CoordinatingGroupSingleResponse,
    CountryContext,
    CountrySingleResponse,
    InstitutionContext,
    InstitutionSingleResponse,
    MasterGroupSingleResponse,
    PatientContext,
    PatientSingleResponse,
    useCoordinatingGroupById,
    useCountryById,
    useInstitutionById,
    useMasterGroupById,
    usePatientByStudyNumber,
    MasterGroupContext
} from '@ngt/forms-trials';
import useInvestigatorById from '../../hooks/data/useInvestigatorById';
import usePISignOffFormByPISignOffIdAndRepeat from '../../hooks/data/usePISignOffFormByPISignOffIdAndRepeat';
import usePISignOffByPISignOffBatchIdAndPatientId from '../../hooks/data/usePISignOffByPISignOffBatchIdAndPatientId';
import usePISignOffBatchByPISignOffDefinitionIdAndRepeat from '../../hooks/data/usePISignOffBatchByPISignOffDefinitionIdAndRepeat';
import { useNavigate } from 'react-router-dom';
import usePISignOffDefinitionByCode from '../../hooks/configuration/usePISignOffDefinitionByCode';
import PISignOffBatchContext from '../../contexts/data/PISignOffBatchContext';
import PISignOffContext from '../../contexts/data/PISignOffContext';
import InvestigatorContext from '../../contexts/data/InvestigatorContext';
import PISignOffDefinitionContext from '../../contexts/configuration/PISignOffDefinitionContext';
import { IPISignOffFormMapping } from '../../utilities/IPISignOffFormMapping';
import { useMemo, useState } from 'react';
import usePISignOffBatchById from '../../hooks/data/usePISignOffBatchById';
import InvestigatorsContext from '../../contexts/data/InvestigatorsContext';
import useInvestigators from '../../hooks/data/useInvestigators';
import useUpdatedFormPropertiesByFromDefinitionIdAndFormId from '../../hooks/data/useUpdatedFormPropertiesByFromDefinitionIdAndFormId';
import UpdatedFormPropertiesContext from '../../contexts/data/UpdatedFormPropertiesContext';
import QueryDialogContext from '../../contexts/utility/QueryDialogContext';
import { DialogState } from '../query/QueryDialog';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */


/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

export interface IPISignOffFormResolverProps {
    formDefinitionCode: string;
    piSignOffDefinitionCode: string | null;
    piSignOffRepeat: number | null;
    patientStudyNumber: string;
    formRepeat: number | null;
    resolveBeforeLoad?: boolean | null;
    formMapping?: Record<string, IPISignOffFormMapping>;

    patientConfiguration?: Partial<SWRConfiguration<PatientSingleResponse, PatientSingleResponse>>;
    institutionConfiguration?: Partial<SWRConfiguration<InstitutionSingleResponse, InstitutionSingleResponse>>;
    countryConfiguration?: Partial<SWRConfiguration<CountrySingleResponse, CountrySingleResponse>>;
    coordinatingGroupConfiguration?: Partial<SWRConfiguration<CoordinatingGroupSingleResponse, CoordinatingGroupSingleResponse>>;
    masterGroupConfiguration?: Partial<SWRConfiguration<MasterGroupSingleResponse, MasterGroupSingleResponse>>;
    piSignOffBatchConfiguration?: Partial<SWRConfiguration<PISignOffBatchSingleResponse, PISignOffBatchSingleResponse>>;
    piSignOffConfiguration?: Partial<SWRConfiguration<PISignOffSingleResponse, PISignOffSingleResponse>>;
    formDefinitionConfiguration?: Partial<SWRConfiguration<FormDefinitionSingleResponse, FormDefinitionSingleResponse>>;
    lookupConfiguration?: Partial<SWRConfiguration<LookupCollectionResponse, LookupCollectionResponse>>;
    formConfiguration?: Partial<SWRConfiguration<IDataSingleResponse<IPISignOffForm>, IDataSingleResponse<IPISignOffForm>>>;
    investigatorConfiguration?: Partial<SWRConfiguration<InvestigatorSingleResponse, InvestigatorSingleResponse>>;
    piSignOffDefinitionConfiguration?: Partial<SWRConfiguration<PISignOffDefinitionSingleResponse, PISignOffDefinitionSingleResponse>>;
    formTypeConfiguration?: Partial<SWRConfiguration<FormTypeSingleResponse, FormTypeSingleResponse>>;
    investigatorsConfiguration?: Partial<SWRConfiguration<InvestigatorCollectionResponse, InvestigatorCollectionResponse>>;
    updatedFormPropertiesConfiguration?: Partial<SWRConfiguration<UpdatedFormPropertyCollectionResponse, UpdatedFormPropertyCollectionResponse>>;
}
/*
 * ---------------------------------------------------------------------------------
 * Constants
 * ---------------------------------------------------------------------------------
 */

const PISignOffFormResolver: React.FunctionComponent<IPISignOffFormResolverProps> = ({
    formDefinitionCode,
    piSignOffDefinitionCode,
    piSignOffRepeat,
    patientStudyNumber,
    formRepeat,
    resolveBeforeLoad,
    formMapping,
    children,

    patientConfiguration,
    institutionConfiguration,
    countryConfiguration,
    coordinatingGroupConfiguration,
    masterGroupConfiguration,
    formConfiguration,
    lookupConfiguration,
    formDefinitionConfiguration,
    piSignOffBatchConfiguration,
    piSignOffConfiguration,
    piSignOffDefinitionConfiguration,
    investigatorConfiguration,
    formTypeConfiguration,
    investigatorsConfiguration,
    updatedFormPropertiesConfiguration
}) => {

    const navigate = useNavigate();

    const piSignOffDefinitionContext = usePISignOffDefinitionByCode(piSignOffDefinitionCode ?? undefined, piSignOffDefinitionConfiguration);

    const formDefinitionContext = useFormDefinitionByCode(formDefinitionCode, formDefinitionConfiguration);

    const formDefCode = useMemo(() => formDefinitionContext?.data?.code ?? '', [formDefinitionContext?.data?.code]);

    const currentFormMapping = useMemo(() => {
        return formMapping?.[formDefCode]
    }, [formDefCode, formMapping])

    const patientContext = usePatientByStudyNumber(patientStudyNumber, undefined, undefined, patientConfiguration);

    const institutionContext = useInstitutionById(patientContext?.data?.institutionId, undefined, undefined, institutionConfiguration);

    const countryContext = useCountryById(institutionContext?.data?.countryId, undefined, undefined, countryConfiguration);

    const coordinatingGroupContext = useCoordinatingGroupById(institutionContext?.data?.coordinatingGroupId, undefined, undefined, coordinatingGroupConfiguration);

    const masterGroupContext = useMasterGroupById(institutionContext?.data?.masterGroupId, undefined, undefined, masterGroupConfiguration);

    const piSignOffBatchContext = usePISignOffBatchByPISignOffDefinitionIdAndRepeat(piSignOffDefinitionContext?.data?.id, piSignOffRepeat ?? undefined, undefined, undefined, piSignOffBatchConfiguration);

    const piSignOffContext = usePISignOffByPISignOffBatchIdAndPatientId(piSignOffBatchContext?.data?.id, patientContext?.data?.id, undefined, undefined, piSignOffConfiguration);

    const formTypeContext = useFormTypeById(formDefinitionContext?.data?.type, formTypeConfiguration);

    const investigatorContext = useInvestigatorById(piSignOffContext?.data?.investigatorId, undefined, undefined, investigatorConfiguration);
    const investigatorsContext = useInvestigators(investigatorsConfiguration);

    const formContext = usePISignOffFormByPISignOffIdAndRepeat(formDefinitionCode, piSignOffContext?.data?.id, formRepeat ?? 1, currentFormMapping?.createData, currentFormMapping?.createMetadata, formConfiguration);

    const lookupsContext = useLookupsByFormDefinitionId(formDefinitionContext?.data?.id, lookupConfiguration);

    const updatedFormPropertiesContext = useUpdatedFormPropertiesByFromDefinitionIdAndFormId(formDefinitionContext?.data?.id, formContext?.data?.id, updatedFormPropertiesConfiguration);

    const [queryDialogState, setQueryDialogState] = useState<DialogState>({
        mode: QueryStatus.Issued,
        dialogOpen: false,
        formDefinition: undefined,
        formId: undefined,
        formType: undefined,
        propertyName: undefined,
        query: undefined
    });

    let conditionalContexts: React.ReactNode =
        !!formMapping && formDefCode && !!formMapping[formDefCode]?.components ?
            formMapping[formDefCode]?.components :
            children;

    const loading =
        (formDefinitionCode && formDefinitionContext?.loading) ||
        (patientStudyNumber && patientContext?.loading) ||
        (patientContext?.data?.id && institutionContext?.loading) ||
        (institutionContext?.data?.countryId && countryContext?.loading) ||
        (institutionContext?.data?.coordinatingGroupId && coordinatingGroupContext?.loading) ||
        (institutionContext?.data?.masterGroupId && masterGroupContext?.loading) ||
        (piSignOffBatchContext.data?.id && piSignOffRepeat && piSignOffBatchContext?.loading) ||
        (patientContext?.data?.id && piSignOffBatchContext?.data?.id && piSignOffContext?.loading) ||
        (formDefinitionCode && piSignOffContext?.data?.id && formContext?.loading) ||
        (formDefinitionContext?.data?.id && lookupsContext?.loading) ||
        (piSignOffBatchContext?.data?.piSignOffDefinitionId && piSignOffDefinitionContext?.loading) ||
        (piSignOffContext?.data?.investigatorId && investigatorContext?.loading) ||
        (investigatorsContext?.loading) ||
        (formDefinitionContext?.data?.type && formTypeContext?.loading) ||
        (formDefinitionContext?.data?.id && formContext?.data?.id && updatedFormPropertiesContext?.loading);

    const hasError = !!(
        !piSignOffDefinitionCode ||
        !piSignOffRepeat ||
        !patientStudyNumber ||
        !formDefinitionCode ||
        formDefinitionContext?.error ||
        patientContext?.error ||
        institutionContext?.error ||
        countryContext?.error ||
        coordinatingGroupContext?.error ||
        masterGroupContext?.error ||
        piSignOffBatchContext?.error ||
        piSignOffContext?.error ||
        formContext?.error ||
        lookupsContext?.error ||
        piSignOffDefinitionContext?.error ||
        investigatorContext?.error ||
        investigatorsContext?.error ||
        formTypeContext?.error ||
        updatedFormPropertiesContext?.error
    );

    if (resolveBeforeLoad && hasError) {
        navigate("/error");
    }

    return (
        <MasterGroupContext.Provider value= { masterGroupContext } >
            <CoordinatingGroupContext.Provider value={ coordinatingGroupContext }>
                <CountryContext.Provider value={ countryContext }>
                    <InstitutionContext.Provider value={ institutionContext }>
                        <PatientContext.Provider value={patientContext}>
                            <PISignOffBatchContext.Provider value={piSignOffBatchContext}>
                                <PISignOffContext.Provider value={ piSignOffContext }>
                                    <PISignOffDefinitionContext.Provider value={ piSignOffDefinitionContext }>
                                        <FormTypeContext.Provider value={ formTypeContext }>
                                            <FormDefinitionContext.Provider value={ formDefinitionContext }>
                                                <FormContext.Provider value={ formContext }>
                                                    <LookupsContext.Provider value={ lookupsContext }>
                                                        <InvestigatorContext.Provider value={investigatorContext}>
                                                            <InvestigatorsContext.Provider value={investigatorsContext}>
                                                                <UpdatedFormPropertiesContext.Provider value={updatedFormPropertiesContext}>
                                                                    <QueryDialogContext.Provider value={{
                                                                        contextQueryDialogState: queryDialogState,
                                                                        setContextQueryDialogState: setQueryDialogState
                                                                    }}>
                                                                    {
                                                                        !resolveBeforeLoad || !loading ?
                                                                            conditionalContexts :
                                                                            <RouteLoading />
                                                                    }
                                                                    </QueryDialogContext.Provider>
                                                                </UpdatedFormPropertiesContext.Provider>
                                                            </InvestigatorsContext.Provider>
                                                        </InvestigatorContext.Provider>
                                                    </LookupsContext.Provider>
                                                </FormContext.Provider>
                                            </FormDefinitionContext.Provider>
                                        </FormTypeContext.Provider>
                                    </PISignOffDefinitionContext.Provider>
                                </PISignOffContext.Provider>
                            </PISignOffBatchContext.Provider>
                        </PatientContext.Provider>
                    </InstitutionContext.Provider>
                </CountryContext.Provider>
            </CoordinatingGroupContext.Provider>
        </MasterGroupContext.Provider>
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default PISignOffFormResolver;
