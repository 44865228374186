/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/*
 * Required to use React components.
 */
import * as React from 'react';
import { useContext, useMemo, FunctionComponent, useCallback } from 'react';
import { IInstitution, IPatient, BasicAlert } from '@ngt/forms-trials';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Form, IFormSubmit, IFormSubmitFailed } from '@ngt/forms-core';
import { SubmitButton, IValidationError, FormsContext, useSnackbar, IFormDefinition } from '@ngt/forms';
import { faExclamationTriangle } from '@fortawesome/pro-light-svg-icons';
import { useNavigate } from 'react-router-dom';
import { AlertTitle } from '@mui/lab';


/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */
import {
    IPISignOffDefinition,
    IInvestigator,
    PISignOffBatchPostCreateSignOff,

    PISignOffStatus
} from '../../api/dtos';
import { PISignOffExtensionContext } from '../..';
import useInvestigators from '../../hooks/data/useInvestigators';
import usePISignOffs from '../../hooks/data/usePISignOffs';
import usePISignOffBatches from '../../hooks/data/usePISignOffBatches';



/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

/**
 * This interface defines the properties for the PISignOffBreadcrumbs component.
 */
interface ICreatePISignOffBatchDialogProps {
    piSignOffDefinitions?: IPISignOffDefinition[];
    formDefinitions?: IFormDefinition[];
    investigators?: IInvestigator[];
    institutions?: IInstitution[];
    open: boolean;
    onClose: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
    content: {
        padding: theme.spacing(5,3)
        //'&:first-child': {
        //    padding: 0
        //}
    },
    settingsContainer: {
        margin: 0,
        borderLeft: 'none',
        borderRight: 'none',
        backgroundColor: 'transparent',

        '&.Mui-expanded': {
            margin: 0
        }
    },
    settingsSummary: {
        '& > .MuiAccordionSummary-content': {
            justifyContent: 'space-between'
        },
        '&.Mui-expanded': {
            minHeight: 48,

            '& > div.MuiAccordionSummary-content': {
                margin: '12px 0'
            }
        }
    },
    settingsTitle: {
        padding: theme.spacing(0)
    },
    settingsContent: {
        display: 'block',
        padding: 0
    }
}));

const CreatePISignOffBatchDialog: FunctionComponent<ICreatePISignOffBatchDialogProps> = ({
    piSignOffDefinitions,
    formDefinitions,
    investigators,
    institutions,
    open,
    onClose,
}) => {
    const classes = useStyles();

    const navigate = useNavigate();
    const forms = useContext(FormsContext);
    const piSignOffContext = useContext(PISignOffExtensionContext);

    const { enqueueSnackbar } = useSnackbar();

    const { mutate: piSignOffBatchesMutate } = usePISignOffBatches();

    const initialValues: PISignOffBatchPostCreateSignOff = useMemo(() => {
        const piSignOffDefinition = piSignOffDefinitions?.find(x => true);

        return {
            data: {
                id: undefined,
                enteredBy: undefined,
                enteredDate: undefined,
                modifiedBy: undefined,
                modifiedDate: undefined,
                status: PISignOffStatus.Pending,
                piSignOffDefinitionId: piSignOffDefinition?.id
            },
            metadata: {},
            options: {
                formDefinitionIds: piSignOffDefinition?.defaultFormDefinitionIds ?? [],
            }
        } as unknown as PISignOffBatchPostCreateSignOff

    }, [piSignOffDefinitions]);

    const onSubmit: IFormSubmit<PISignOffBatchPostCreateSignOff, IValidationError> = useCallback(async (formState, formActions) => {
        if(!formState.value)
            return;
        
        try{
            await forms.serviceStackClient.post(new PISignOffBatchPostCreateSignOff(formState.value));
            await piSignOffBatchesMutate();

            enqueueSnackbar(
                <>
                    <AlertTitle>
                        PI Sign-Off Batch Created
                    </AlertTitle>
                    The PI Sign-Off Batch was successfully created.
                </>,
                { variant: 'success' }
            );

            onClose();
        }
        catch{

        }

    }, [forms, enqueueSnackbar, history, piSignOffContext, onClose, piSignOffBatchesMutate]);

    const onSubmitFailed: IFormSubmitFailed<PISignOffBatchPostCreateSignOff, IValidationError> = useCallback(async (formState, formActions) => {
        enqueueSnackbar(
            <>
                <AlertTitle>
                    PI Sign-Off Batch Not Created
                </AlertTitle>
                An error occurred while attempting to create the PI Sign-Off Batch.
            </>,
            { variant: 'error-critical' }
        );
    }, [enqueueSnackbar]);

    const getDefaultFormDefinitionIds = useCallback((piSignOffDefinitionId?: number) => {
        const piSignOffDefinition = piSignOffDefinitions?.find(x => x.id === piSignOffDefinitionId);

        return piSignOffDefinition?.defaultFormDefinitionIds
    }, [piSignOffDefinitions])

    const noDefinitions = !piSignOffDefinitions?.length;

    return (
        <>
            <Dialog open={open} onClose={onClose} fullWidth scroll="body" maxWidth="xs">
                <DialogTitle>
                    Create PI Sign-Off Batch
                </DialogTitle>
                {
                    !noDefinitions && (
                        <Form
                            initialValues={initialValues}
                            onSubmit={onSubmit}
                            onSubmitFailed={onSubmitFailed}
                        >
                            <DialogContent className={classes.content} dividers>
                                <Typography>
                                    A Principal Investigator Sign-off will be created for every patient.
                                </Typography>
                            </DialogContent>
                            <DialogActions>
                                <Button autoFocus onClick={onClose} color="secondary">
                                    Cancel
                                </Button>
                                <SubmitButton color="primary">
                                    Ok
                                </SubmitButton>
                            </DialogActions>
                        </Form>
                    )
                }

                {
                    (noDefinitions) && (
                        <>
                            <DialogContent className={classes.content} dividers>
                                <BasicAlert
                                    title="No PI Sign-Off Definition"
                                    description="There has been no PI sign-off definition setup for this trial."
                                    icon={faExclamationTriangle}
                                    severity="warning"
                                />
                            </DialogContent>
                            <DialogActions>
                                <Button autoFocus onClick={onClose} color="secondary">
                                    Cancel
                                </Button>
                            </DialogActions>
                        </>

                    )
                }
            </Dialog>
        </>
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default CreatePISignOffBatchDialog;