import { Theme, Tooltip, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { FunctionComponent, useCallback, useContext, useMemo, useState } from 'react';
import ContextPatientInformation from '../components/patient/ContextPatientInformation';
import { FormDefinitionsContext, FormTypesContext, ProgressButton, useSnackbar } from '@ngt/forms';
import PISignOffSummaryList from '../components/piSignOff/PISignOffSummaryList';
import PISignOffContext from '../contexts/data/PISignOffContext';
import PISignOffBatchContext from '../contexts/data/PISignOffBatchContext';
import PISignOffValidationsContext from '../contexts/data/PISignOffValidationsContext';
import PISignOffDefinitionContext from '../contexts/configuration/PISignOffDefinitionContext';
//import ReviewerContext from '../contexts/data/ReviewerContext';
import ContextPISignOffBreadcrumbs from '../components/breadcrumbs/ContextPISignOffBreadcrumbs';
import { PISignOffPermission, PISignOffStatus } from '../api/dtos';
import { AlertTitle } from '@mui/lab';
import CompletePISignOffDialog from '../components/dialogs/CompletePISignOffDialog';
import UpdatePISignOffDialog from '../components/dialogs/UpdatePISignOffDialog';
import AssignPISignOffDialog from '../components/dialogs/AssignPISignOffDialog';
import { DateTime } from 'luxon';
import CancelPISignOffDialog from '../components/dialogs/CancelPISignOffDialog';
import useContextPermissions from '../hooks/utility/useContextPermissions';
import { InstitutionContext, PatientContext } from '@ngt/forms-trials';
import PISignOffExtensionContext from '../contexts/PISignOffExtensionContext';
import { Navigate, useNavigate } from 'react-router-dom';

interface IPISignOffProps {
}

const useStyles = makeStyles((theme: Theme) => ({
    title: {
        padding: theme.spacing(2, 2, 0)
    },
    subtitle: {
        padding: theme.spacing(0, 2, 2)
    },
    patientInformation: {
        padding: theme.spacing(2, 2, 0)
    },
    buttonGroup: {
        padding: theme.spacing(2),
        textAlign: 'right'
    },
    margin: {
        marginLeft: theme.spacing(2)
    }
}));

const PISignOff: FunctionComponent<IPISignOffProps> = () => {
    const classes = useStyles();
    const navigate = useNavigate();

    const [processing, setProcessing] = useState(false);
    const [assignOpen, setAssignOpen] = useState(false);
    const [closeOpen, setCloseOpen] = useState(false);
    const [completeOpen, setCompleteOpen] = useState(false);
    const [updateOpen, setUpdateOpen] = useState(false);

    const onCloseDialogOpen = useCallback(() => {
        setCloseOpen(true);
    }, [setCloseOpen]);

    const onCloseDialogClose = useCallback(() => {
        setCloseOpen(false);
    }, [setCloseOpen]);

    const onAssignDialogOpen = useCallback(() => {
        setAssignOpen(true);
    }, [setAssignOpen]);

    const onAssignDialogClose = useCallback(() => {
        setAssignOpen(false);
    }, [setAssignOpen]);

    const onCompleteDialogOpen = useCallback(() => {
        setCompleteOpen(true);
    }, [setCompleteOpen]);

    const onCompleteDialogClose = useCallback(() => {
        setCompleteOpen(false);
    }, [setCompleteOpen]);

    const onUpdateDialogOpen = useCallback(() => {
        setUpdateOpen(true);
    }, [setUpdateOpen]);

    const onUpdateDialogClose = useCallback(() => {
        setUpdateOpen(false);
    }, [setUpdateOpen]);

    const { enqueueSnackbar } = useSnackbar();

    const { createPISignOffFormRouteFn } = useContext(PISignOffExtensionContext);

    const { data: patient } = useContext(PatientContext);
    const { data: institution } = useContext(InstitutionContext);
    const { data: piSignOff, save: savePISignOff } = useContext(PISignOffContext);
    const { data: piSignOffBatch } = useContext(PISignOffBatchContext);
    const { data: formValidations, mutate: formValidationsMutate } = useContext(PISignOffValidationsContext);
    const { data: piSignOffDefinition } = useContext(PISignOffDefinitionContext);
    //const { data: reviewer } = useContext(ReviewerContext);
    const { data: formTypes } = useContext(FormTypesContext);
    const { data: formDefinitions } = useContext(FormDefinitionsContext);

    const { data: [canCompletePISignOff, canCancelPISignOff, canUpdatePISignOff] } = useContextPermissions([PISignOffPermission.CompletePISignOff, PISignOffPermission.CancelPISignOff, PISignOffPermission.UpdatePISignOff]);

    const canMarkReviewComplete = useMemo(() => {
        if (formValidations?.some(f => f.metadata.Status !== PISignOffStatus.Complete)) {
            return false;
        }

        return canCompletePISignOff;
    }, [formValidations, canCompletePISignOff]);

    const canTriggerReviewUpdate = useMemo(() => {
        if (piSignOff?.status !== PISignOffStatus.New && piSignOff?.status !== PISignOffStatus.InProgress) {
            return false;
        }

        return canUpdatePISignOff;
    }, [piSignOff, canUpdatePISignOff]);

    const seeMarkReviewComplete = useMemo(() => {
        return piSignOff?.status !== PISignOffStatus.Complete && piSignOff?.status !== PISignOffStatus.Cancelled && canCompletePISignOff;
    }, [piSignOff, canCompletePISignOff]);

    const onFormMarkedComplete = useCallback(async () => {
        setProcessing(true);

        if (piSignOff) {
            try {
                await savePISignOff({ ...piSignOff, status: PISignOffStatus.Complete });

                enqueueSnackbar(
                    <>
                        <AlertTitle>
                            PI Sign-Off Saved
                        </AlertTitle>
                        The PI sign-off has been successfully completed.
                    </>,
                    { variant: 'success' }
                );
            }
            catch {
                enqueueSnackbar(
                    <>
                        <AlertTitle>
                            PI Sign-Off Not Saved
                        </AlertTitle>
                        An error occurred while attempting to save the PI sign-off.
                    </>,
                    { variant: 'error-critical' }
                );
            }
        }

        setProcessing(false);
    }, [piSignOff, savePISignOff, setProcessing, enqueueSnackbar]);


    const onFormMarkedClosed = useCallback(async () => {
        setProcessing(true);

        if (piSignOff) {
            try {
                await savePISignOff({ ...piSignOff, status: PISignOffStatus.Cancelled });
                await formValidationsMutate();

                enqueueSnackbar(
                    <>
                        <AlertTitle>
                            PI Sign-Off Saved
                        </AlertTitle>
                        The PI sign-off has been successfully completed.
                    </>,
                    { variant: 'success' }
                );
            }
            catch {
                enqueueSnackbar(
                    <>
                        <AlertTitle>
                            PI Sign-Off Not Saved
                        </AlertTitle>
                        An error occurred while attempting to save the PI sign-off.
                    </>,
                    { variant: 'error-critical' }
                );
            }
        }

        setProcessing(false);
    }, [piSignOff, savePISignOff, setProcessing, enqueueSnackbar, formValidationsMutate]);

    const onUpdate = useCallback(async () => {
        setProcessing(true);

        if (piSignOff) {
            await formValidationsMutate();
        }

        setProcessing(false);
    }, [piSignOff, savePISignOff, setProcessing, enqueueSnackbar, formValidationsMutate]);

    if (formValidations?.length == 1) {
        const formDefinition = formDefinitions?.find(x => x.id == formValidations[0].metadata.FormDefinitionId);

        <Navigate to={createPISignOffFormRouteFn(piSignOffBatch!, piSignOffDefinition!, patient!, piSignOff!, formDefinition!, formValidations[0])} />
    }

    return (
        <>
            <ContextPISignOffBreadcrumbs />
            <div className={classes.patientInformation}>
                <ContextPatientInformation />
            </div>
            <Typography variant="h1" className={classes.title}>
                {piSignOffDefinition?.name}
            </Typography>
            <Typography variant="h2" className={classes.subtitle}>
                {DateTime.fromISO(piSignOff?.enteredDate!).toFormat('dd/MM/yyyy')}
            </Typography>
            <PISignOffSummaryList
                formDefinitions={formDefinitions}
                formValidations={formValidations}
                piSignOffBatch={piSignOffBatch ?? undefined}
                piSignOff={piSignOff ?? undefined}
                piSignOffDefinition={piSignOffDefinition ?? undefined}
                formTypes={formTypes}
                patient={patient ?? undefined}
                // reviewer={reviewer ?? undefined}
            />
            <UpdatePISignOffDialog
                open={updateOpen}
                onClose={onUpdateDialogClose}
                piSignOffDefinition={piSignOffDefinition}
                piSignOff={piSignOff!}
                piSignOffBatch={piSignOffBatch!}
                formDefinitions={formDefinitions}
                formTypes={formTypes}
                institution={institution!}
                patient={patient!}
                onFormUpdated={onUpdate}
            />
            <AssignPISignOffDialog
                onClose={onAssignDialogClose}
                open={assignOpen}
            />
            <CompletePISignOffDialog
                onClose={onCompleteDialogClose}
                onSubmit={onFormMarkedComplete}
                piSignOff={piSignOff!}
                open={completeOpen}
                institution={institution!}
                patient={patient!}
                // reviewer={reviewer!}
                piSignOffDefinition={piSignOffDefinition!}
            />
            <CancelPISignOffDialog
                onClose={onCloseDialogClose}
                onSubmit={onFormMarkedClosed}
                piSignOff={piSignOff!}
                open={closeOpen}
                institution={institution!}
                patient={patient!}
                // reviewer={reviewer!}
                piSignOffDefinition={piSignOffDefinition!}
            />
            <div className={classes.buttonGroup}>
                {
                    canTriggerReviewUpdate &&  (
                        <ProgressButton variant="contained" color="secondary" loading={processing} onClick={onAssignDialogOpen}>
                            Assign
                        </ProgressButton>
                    )
                }
                {
                    canTriggerReviewUpdate &&  (
                        <ProgressButton className={classes.margin}  variant="contained" color="secondary" loading={processing} onClick={onUpdateDialogOpen}>
                            Update
                        </ProgressButton>
                    )
                }
                {/* {
                    canCancelPISignOff && piSignOff?.status !== PISignOffStatus.Complete && piSignOff?.status !== PISignOffStatus.Cancelled && (
                        <ProgressButton variant="contained" color="secondary" loading={processing} onClick={onCloseDialogOpen}>
                            Close Review
                        </ProgressButton>
                    )
                } */}
                {
                    seeMarkReviewComplete && !canMarkReviewComplete && (
                        <Tooltip title="All reports must be marked as complete to be able to sign-off">
                            <span>
                                <ProgressButton className={classes.margin} variant="contained" color="primary" disabled={true}>
                                    Sign-Off
                                </ProgressButton>
                            </span>
                        </Tooltip>
                    )
                }
                {
                    seeMarkReviewComplete && canMarkReviewComplete && (
                        <ProgressButton className={classes.margin}  variant="contained" color="primary" loading={processing} onClick={onCompleteDialogOpen}>
                            Sign-Off
                        </ProgressButton>
                    )
                }
            </div>
        </>
    );
};

export default PISignOff;
