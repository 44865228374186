/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the context for the currently selected form.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a context.
 */
import * as React from 'react';
import { 
    FormDefinitionCollectionResponse, 
    RouteLoading, 
    useFormDefinitions,
    FormDefinitionsContext, 
} from '@ngt/forms';
import { InvestigatorCollectionResponse, PISignOffBatchSingleResponse, PISignOffCollectionResponse, PISignOffDefinitionSingleResponse } from '../../api/dtos';
import { SWRConfiguration } from 'swr/dist/types';
import { useNavigate } from 'react-router-dom';
import PISignOffDefinitionsContext from '../../contexts/configuration/PISignOffDefinitionsContext';
import usePISignOffBatches from '../../hooks/data/usePISignOffBatches';
import usePISignOffDefinitions from '../../hooks/configuration/usePISignOffDefinitions';
import PISignOffBatchesContext from '../../contexts/data/PISignOffBatchesContext';
import { 
    CoordinatingGroupCollectionResponse, 
    CountryCollectionResponse, 
    InstitutionCollectionResponse, 
    MasterGroupCollectionResponse, 
    PatientCollectionResponse, 
    useCoordinatingGroups, 
    useCountries, 
    useInstitutions, 
    useMasterGroups, 
    usePatients,
    MasterGroupsContext,
    CoordinatingGroupsContext,
    InstitutionsContext,
    CountriesContext,
    PatientsContext,
    
} from '@ngt/forms-trials';
import PISignOffBatchContext from '../../contexts/data/PISignOffBatchContext';
import PISignOffDefinitionContext from '../../contexts/configuration/PISignOffDefinitionContext';
import usePISignOffBatchByPISignOffDefinitionIdAndRepeat from '../../hooks/data/usePISignOffBatchByPISignOffDefinitionIdAndRepeat';
import usePISignOffDefinitionByCode from '../../hooks/configuration/usePISignOffDefinitionByCode';
import usePISignOffsByPISignOffBatchId from '../../hooks/data/usePISignOffsByPISignOffBatchId';
import PISignOffsContext from '../../contexts/data/PISignOffsContext';
import useInvestigators from '../../hooks/data/useInvestigators';
import InvestigatorsContext from '../../contexts/data/InvestigatorsContext';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */


/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

export interface IPISignOffBatchResolverProps {
    piSignOffDefinitionCode: string | null;
    piSignOffRepeat: number | null;
    resolveBeforeLoad?: boolean | null;

    piSignOffBatchConfiguration?: Partial<SWRConfiguration<PISignOffBatchSingleResponse, PISignOffBatchSingleResponse>>;
    piSignOffDefinitionConfiguration?: Partial<SWRConfiguration<PISignOffDefinitionSingleResponse, PISignOffDefinitionSingleResponse>>;

    
    piSignOffsConfiguration?: Partial<SWRConfiguration<PISignOffCollectionResponse, PISignOffCollectionResponse>>;

    patientsConfiguration?: Partial<SWRConfiguration<PatientCollectionResponse, PatientCollectionResponse>>;
    institutionsConfiguration?: Partial<SWRConfiguration<InstitutionCollectionResponse, InstitutionCollectionResponse>>;
    countriesConfiguration?: Partial<SWRConfiguration<CountryCollectionResponse, CountryCollectionResponse>>;
    coordinatingGroupsConfiguration?: Partial<SWRConfiguration<CoordinatingGroupCollectionResponse, CoordinatingGroupCollectionResponse>>;
    masterGroupsConfiguration?: Partial<SWRConfiguration<MasterGroupCollectionResponse, MasterGroupCollectionResponse>>;
    investigatorsConfiguration?: Partial<SWRConfiguration<InvestigatorCollectionResponse, InvestigatorCollectionResponse>>;

}
/*
 * ---------------------------------------------------------------------------------
 * Constants
 * ---------------------------------------------------------------------------------
 */

const PISignOffBatchResolver: React.FunctionComponent<IPISignOffBatchResolverProps> = ({
    
    piSignOffDefinitionCode,
    piSignOffRepeat,
    resolveBeforeLoad,
    children,

    piSignOffBatchConfiguration,
    piSignOffDefinitionConfiguration,
    piSignOffsConfiguration,

    coordinatingGroupsConfiguration,
    countriesConfiguration,
    institutionsConfiguration,
    masterGroupsConfiguration,
    patientsConfiguration,
    investigatorsConfiguration
}) => {

    const navigate = useNavigate();
    const piSignOffDefinitionsContext = usePISignOffDefinitionByCode(piSignOffDefinitionCode ?? undefined, piSignOffDefinitionConfiguration);
    const piSignOffBatchContext = usePISignOffBatchByPISignOffDefinitionIdAndRepeat(piSignOffDefinitionsContext?.data?.id, piSignOffRepeat ?? undefined, undefined, undefined, piSignOffBatchConfiguration);


    const piSignOffsContext = usePISignOffsByPISignOffBatchId(piSignOffBatchContext?.data?.id, piSignOffsConfiguration);

    const masterGroupsContext = useMasterGroups(masterGroupsConfiguration);
    const coordinatingGroupsContext = useCoordinatingGroups(coordinatingGroupsConfiguration);
    const countriesContext = useCountries(countriesConfiguration);
    const institutionsContext = useInstitutions(institutionsConfiguration);
    const patientsContext = usePatients(patientsConfiguration);
    const investigatorsContext = useInvestigators(investigatorsConfiguration);

    const loading = (piSignOffBatchContext?.data?.id && piSignOffsContext?.loading) || 
        (piSignOffDefinitionsContext?.data?.id && piSignOffBatchContext?.loading) || 
        piSignOffDefinitionsContext?.loading || 
        masterGroupsContext?.loading || 
        coordinatingGroupsContext?.loading || 
        countriesContext?.loading || 
        institutionsContext?.loading || 
        patientsContext?.loading ||
        investigatorsContext?.loading;

    const hasError = !!(piSignOffsContext?.error || 
        piSignOffBatchContext?.error || 
        piSignOffDefinitionsContext?.error || 
        masterGroupsContext?.error || 
        coordinatingGroupsContext?.error || 
        countriesContext?.error || 
        institutionsContext?.error || 
        patientsContext?.error || 
        investigatorsContext?.error);

    if (resolveBeforeLoad && hasError) {
        navigate("/error");
    }

    return (
        <PISignOffBatchContext.Provider value={piSignOffBatchContext}>
            <PISignOffDefinitionContext.Provider value={piSignOffDefinitionsContext}>
                <MasterGroupsContext.Provider value={masterGroupsContext }>
                    <CoordinatingGroupsContext.Provider value={ coordinatingGroupsContext}>
                        <InstitutionsContext.Provider value={institutionsContext}>
                            <CountriesContext.Provider value={countriesContext }>
                                <PatientsContext.Provider value={patientsContext}>
                                    <PISignOffsContext.Provider value={piSignOffsContext}>
                                        <InvestigatorsContext.Provider value={investigatorsContext}>
                                            {
                                                !resolveBeforeLoad || !loading ?
                                                    children :
                                                    <RouteLoading />
                                            }
                                        </InvestigatorsContext.Provider>
                                    </PISignOffsContext.Provider>
                                </PatientsContext.Provider>
                            </CountriesContext.Provider>
                        </InstitutionsContext.Provider>
                    </CoordinatingGroupsContext.Provider>
                </MasterGroupsContext.Provider>
            </PISignOffDefinitionContext.Provider>
        </PISignOffBatchContext.Provider>
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default PISignOffBatchResolver;
