import React, { FunctionComponent, useContext } from 'react';
import { FormDefinitionContext, FormTypeContext, FormContext } from '@ngt/forms';
import { DateTime } from 'luxon';

interface IContextFormDataDateProps {
}



const ContextFormDataDate: FunctionComponent<IContextFormDataDateProps> = () => {
    const { data: formType } = useContext(FormTypeContext);
    const { data: form } = useContext(FormContext);
    const { data: formDefinition } = useContext(FormDefinitionContext);

    return (
        <>
            {
                //{ formType?.name} &ndash;
            }
            {(form as any)?.dataDate ? 'Data as of: ' + DateTime.fromISO((form as any)?.dataDate).toFormat('dd/MM/yyyy') : undefined}
        </>
    );
};

export default ContextFormDataDate;
