import { useCallback, useContext, useMemo } from 'react';
import useSWR, { SWRConfiguration, mutate } from 'swr';
import { BoundMutator, Forms, FormsContext } from '@ngt/forms';
import {
    IPISignOff,
    PISignOffGetSingleByPISignOffBatchIdAndPatientId,
    PISignOffPostCreate,
    PISignOffPostSave,
    PISignOffPostValidate,
    PISignOffSingleResponse
} from '../../api/dtos';

// @ts-ignore: declared but not used 'url'
const fetcher = async <TPISignOff extends IPISignOff = IPISignOff>(url: string, forms: Forms, piSignOffBatchId?: number, patientId?: number, data?: TPISignOff, metadata?: Record<string, any>) => {
    if (!piSignOffBatchId || !patientId ) {
        return await create<TPISignOff>(forms, data, metadata);
    }

    return await get(forms, piSignOffBatchId, patientId)
}

const create = async <TPISignOff extends IPISignOff = IPISignOff>(forms: Forms, data?: TPISignOff, metadata?: Record<string, any>) => {

    const response = await forms.serviceStackClient.post(new PISignOffPostCreate({ data, metadata }));

    return response;
}

const get = async (forms: Forms, piSignOffBatchId: number, patientId: number) => {
    const response = await forms.serviceStackClient.get(new PISignOffGetSingleByPISignOffBatchIdAndPatientId({ piSignOffBatchId, patientId }));

    return response;
}

// @ts-ignore: declared but not used 'url'
const save = async <TPISignOff extends IPISignOff = IPISignOff>(forms: Forms, data: TPISignOff, metadata: Record<string, any> | undefined) => {
    const response = await forms.serviceStackClient.post(new PISignOffPostSave({ data, metadata }));

    return response;
}

const validate = async <TPISignOff extends IPISignOff = IPISignOff>(forms: Forms, data: TPISignOff, metadata: Record<string, any> | undefined) => {

    const response = await forms.serviceStackClient.post(new PISignOffPostValidate({ data, metadata }));

    return response;
}

const usePISignOffByPISignOffBatchIdAndPatientId = <TPISignOff extends IPISignOff = IPISignOff>(piSignOffBatchId?: number | null, patientId?: number | null, createData?: TPISignOff, createMetadata?: Record<string, any>, configuration?: SWRConfiguration<PISignOffSingleResponse, PISignOffSingleResponse>) => {
    const forms = useContext(FormsContext);

    const cacheKey = useMemo(() => {
        if ((!piSignOffBatchId || !patientId) && !createData && !createMetadata) {
            return null;
        }

        if (!piSignOffBatchId || !patientId) {
            return [`${forms.baseRoute}/pi-signoff/single/pi-signoff-batch-id-patient-id`, forms, null, null, createData, createMetadata]
        }

        return [`${forms.baseRoute}/pi-signoff/single/pi-signoff-batch-id-patient-id`, forms, piSignOffBatchId, patientId, null, null]
    }, [forms, piSignOffBatchId, patientId, createData, createMetadata])

    const { data, error } = useSWR<PISignOffSingleResponse, PISignOffSingleResponse>(cacheKey, fetcher, configuration);

    const boundMutate: BoundMutator<PISignOffSingleResponse> = useCallback((newData, shouldRevalidate) => {
        return mutate(cacheKey, newData, shouldRevalidate);
    }, [cacheKey]);

    const boundSave = useCallback(async (saveData: TPISignOff, metadata?: Record<string, any>, shouldRevalidate?: boolean) => {
        const response = await save<TPISignOff>(forms, saveData, metadata);

        if (cacheKey) {
            // !!cacheKey[2] = has an id.
            const revalidate = shouldRevalidate ?? !!cacheKey[2];

            const updated = await boundMutate(response, revalidate);

            return updated?.data;
        }

        return response?.data;
    }, [boundMutate, forms, cacheKey]);

    const boundValidate = useCallback(async (validateData: TPISignOff, metadata?: Record<string, any>) => {
        const response = await validate<TPISignOff>(forms, validateData, metadata);

        return response?.validationResult;
    }, [forms]);


    const result = useMemo(() => {
        return {
            data: data?.data as TPISignOff | undefined,
            error: error?.responseStatus,
            loading: data === undefined && error === undefined,
            mutate: boundMutate,
            save: boundSave,
            validate: boundValidate
        };
    }, [boundMutate, data, error, boundValidate, boundSave])

    return result;
}

export default usePISignOffByPISignOffBatchIdAndPatientId;