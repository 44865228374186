import { faBuilding, faHeartbeat, faUser } from '@fortawesome/pro-duotone-svg-icons';
import { Grid, Theme, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { FunctionComponent } from 'react';
import { IInstitution, InformationCard, IPatient } from '@ngt/forms-trials';
import { IPISignOffPatient, IPISignOff, IInvestigator } from '../../api/dtos';
import { convertPISignOffStatusToString } from '../../utilities/PISignOffStatus';

interface IPatientInformationProps {
    patient?: IPatient | null;
    institution?: IInstitution | null;
    piSignOff?: IPISignOff | null;
    investigator?: IInvestigator | null;
}


const useStyles = makeStyles((theme: Theme) => ({
    item: {
        display: 'flex',

        '& > *': {
            flex: '1 1 auto'
        }
    }
}));


const PatientInformation: FunctionComponent<IPatientInformationProps> = ({
    patient,
    institution,
    investigator,
    piSignOff
}) => {
    const classes = useStyles();
    const theme = useTheme();

    const state = convertPISignOffStatusToString(piSignOff?.status);

    return (
        <Grid
            container
            spacing={3}
        >
            <Grid
                item
                xs={12}
                md={4}
                className={classes.item}
            >
                <InformationCard
                    color={theme.palette.primary.main}
                    icon={faUser}
                    title="Patient"
                    text={patient?.studyNumber}
                    caption={(patient as IPISignOffPatient)?.treatmentArm}
                />
            </Grid>
            <Grid
                item
                xs={12}
                md={4}
                className={classes.item}
            >
                <InformationCard
                    color={theme.palette.secondary.main}
                    icon={faBuilding}
                    title="Institution"
                    text={institution?.code}
                    caption={institution?.name}
                />
            </Grid>
            <Grid
                item
                xs={12}
                md={4}
                className={classes.item}
            >
                <InformationCard
                    color="#76c6d7"
                    icon={faHeartbeat}
                    title="Status"
                    text={state}
                    caption={investigator?.name}
                />
            </Grid>
        </Grid>
    );
};

export default PatientInformation;
