/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the component that provides the basis for all routes.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a context.
 */
import * as React from 'react';

/*
 * Used to type properties and get access to routes. 
 */
import { RouteProps } from 'react-router';


/*
 * Used to get access to application routing based on paths.
 */
import { Route, useParams } from 'react-router-dom';
import { FormDefinitionSingleResponse, FormsContext, FormTypeSingleResponse, IDataSingleResponse, LookupCollectionResponse } from '@ngt/forms';
import PISignOffFormResolver from '../resolver/PISignOffFormResolver';
import { SWRConfiguration } from 'swr/dist/types';
import { IPISignOffForm, PISignOffDefinitionSingleResponse, PISignOffSingleResponse, InvestigatorSingleResponse, PISignOffBatchSingleResponse, UpdatedFormPropertyCollectionResponse, InvestigatorCollectionResponse } from '../../api/dtos';
import PISignOffExtensionContext from '../../contexts/PISignOffExtensionContext';
import { useContext } from 'react';
import { IPISignOffFormMapping } from '../../utilities/IPISignOffFormMapping';
import { CoordinatingGroupSingleResponse, CountrySingleResponse, InstitutionSingleResponse, MasterGroupSingleResponse, PatientSingleResponse, TrialsExtensionContext } from '@ngt/forms-trials';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */


/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

/**
 * This interface defines the properties for the OpmsFormByIdRoute component.
 */
export interface IPISignOffFormRouteProps {
    formDefinitionCode?: string;
    piSignOffDefinitionCode?: string;
    piSignOffRepeat?: number;
    patientStudyNumber?: string;
    formRepeat?: number;

    /**
     * This property determines whether the associated data should be loaded before loading the route.
     */
    resolveBeforeLoad?: boolean | null;

    formMapping?: Record<string, IPISignOffFormMapping>;

    patientConfiguration?: Partial<SWRConfiguration<PatientSingleResponse, PatientSingleResponse>>;
    institutionConfiguration?: Partial<SWRConfiguration<InstitutionSingleResponse, InstitutionSingleResponse>>;
    countryConfiguration?: Partial<SWRConfiguration<CountrySingleResponse, CountrySingleResponse>>;
    coordinatingGroupConfiguration?: Partial<SWRConfiguration<CoordinatingGroupSingleResponse, CoordinatingGroupSingleResponse>>;
    masterGroupConfiguration?: Partial<SWRConfiguration<MasterGroupSingleResponse, MasterGroupSingleResponse>>;
    piSignOffBatchConfiguration?: Partial<SWRConfiguration<PISignOffBatchSingleResponse, PISignOffBatchSingleResponse>>;
    piSignOffConfiguration?: Partial<SWRConfiguration<PISignOffSingleResponse, PISignOffSingleResponse>>;
    formDefinitionConfiguration?: Partial<SWRConfiguration<FormDefinitionSingleResponse, FormDefinitionSingleResponse>>;
    lookupConfiguration?: Partial<SWRConfiguration<LookupCollectionResponse, LookupCollectionResponse>>;
    formConfiguration?: Partial<SWRConfiguration<IDataSingleResponse<IPISignOffForm>, IDataSingleResponse<IPISignOffForm>>>;
    investigatorConfiguration?: Partial<SWRConfiguration<InvestigatorSingleResponse, InvestigatorSingleResponse>>;
    piSignOffDefinitionConfiguration?: Partial<SWRConfiguration<PISignOffDefinitionSingleResponse, PISignOffDefinitionSingleResponse>>;
    formTypeConfiguration?: Partial<SWRConfiguration<FormTypeSingleResponse, FormTypeSingleResponse>>;
    investigatorsConfiguration?: Partial<SWRConfiguration<InvestigatorCollectionResponse, InvestigatorCollectionResponse>>;
    updatedFormPropertiesConfiguration?: Partial<SWRConfiguration<UpdatedFormPropertyCollectionResponse, UpdatedFormPropertyCollectionResponse>>;
}

/*
 * ---------------------------------------------------------------------------------
 * Components
 * ---------------------------------------------------------------------------------
 */

/**
 * This component provides context for the form management system.
 * @param param0 component properties.
 */
const PISignOffFormRoute: React.FunctionComponent<IPISignOffFormRouteProps> = ({
    formDefinitionCode,
    piSignOffDefinitionCode,
    piSignOffRepeat,
    patientStudyNumber,
    formRepeat,
    resolveBeforeLoad,
    children,
    formMapping,

    patientConfiguration,
    institutionConfiguration,
    countryConfiguration,
    coordinatingGroupConfiguration,
    masterGroupConfiguration,
    formConfiguration,
    lookupConfiguration,
    formDefinitionConfiguration,
    piSignOffBatchConfiguration,
    piSignOffConfiguration,
    piSignOffDefinitionConfiguration,
    investigatorConfiguration,
    formTypeConfiguration,
    updatedFormPropertiesConfiguration,
    investigatorsConfiguration,

    ...routeProps
}) => {
    const params = useParams<Record<string, string>>();
    const formsContext = useContext(FormsContext);
    const trialsExtensionContext = useContext(TrialsExtensionContext);
    const piSignOffExtensionContext = useContext(PISignOffExtensionContext);
    const paramFormRepeatStr = params[formsContext.routeParameters.formRepeat];
    const paramPISignOffDefinitionCode = params[piSignOffExtensionContext.routeParameters.piSignOffDefinitionCode];
    const paramPISignOffRepeatStr = params[piSignOffExtensionContext.routeParameters.piSignOffRepeat];
    const paramPatientStudyNumber = params[trialsExtensionContext.routeParameters.patientStudyNumber];
    const paramFormDefCode = params[formsContext.routeParameters.formDefinitionCode];
    let paramFormRepeat = null;
    let paramPISignOffDefinitionId = null;
    let paramPISignOffRepeat = null;

    try {
        if (paramFormRepeatStr) {
            paramFormRepeat = parseInt(paramFormRepeatStr);
        }
    }
    catch (error) {
        console.error(`Invalid Form Repeat in route: ${paramFormRepeatStr}`)
    }


    try {
        if (paramPISignOffRepeatStr) {
            paramPISignOffRepeat = parseInt(paramPISignOffRepeatStr);
        }
    }
    catch (error) {
        console.error(`Invalid PI Sign-Off Repeat in route: ${paramPISignOffRepeatStr}`)
    }

    return (
        <PISignOffFormResolver
            formDefinitionCode={paramFormDefCode ?? formDefinitionCode ?? ""}
            piSignOffDefinitionCode={paramPISignOffDefinitionCode ?? piSignOffDefinitionCode ?? null}
            piSignOffRepeat={paramPISignOffRepeat ?? piSignOffRepeat ?? null}
            patientStudyNumber={paramPatientStudyNumber ?? patientStudyNumber ?? ""}
            formRepeat={paramFormRepeat ?? formRepeat ?? null}
            resolveBeforeLoad={resolveBeforeLoad}
            formMapping={formMapping}
            coordinatingGroupConfiguration={coordinatingGroupConfiguration}
            countryConfiguration={countryConfiguration}
            formConfiguration={formConfiguration}
            formDefinitionConfiguration={formDefinitionConfiguration}
            formTypeConfiguration={formTypeConfiguration}
            institutionConfiguration={institutionConfiguration}
            lookupConfiguration={lookupConfiguration}
            masterGroupConfiguration={masterGroupConfiguration}
            piSignOffBatchConfiguration={piSignOffBatchConfiguration}
            piSignOffConfiguration={piSignOffConfiguration}
            piSignOffDefinitionConfiguration={piSignOffDefinitionConfiguration}
            patientConfiguration={patientConfiguration}
            investigatorConfiguration={investigatorConfiguration}
            investigatorsConfiguration={investigatorsConfiguration}
        >
            {children}
        </PISignOffFormResolver>
    );
}

/*
    * ---------------------------------------------------------------------------------
    * Default Export
    * ---------------------------------------------------------------------------------
    */

export default PISignOffFormRoute;