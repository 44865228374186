import { useCallback, useContext, useMemo } from 'react';
import useSWR, { SWRConfiguration, mutate } from 'swr';
import { FormsContext, BoundMutator } from '@ngt/forms';
import { PISignOffGetCollectionByPISignOffBatchId, PISignOffCollectionResponse } from '../../api/dtos';

// @ts-ignore: declared but not used 'url'
const fetcher = async (url: string, forms: Forms, piSignOffBatchId: number) => {
    const response = await forms.serviceStackClient.get(new PISignOffGetCollectionByPISignOffBatchId({ piSignOffBatchId }));

    return response;
}

const usePISignOffsByPISignOffBatchId = (piSignOffBatchId?: number, configuration?: SWRConfiguration<PISignOffCollectionResponse, PISignOffCollectionResponse>) => {
    const forms = useContext(FormsContext);

    const cacheKey = useMemo(() => {
        if (!piSignOffBatchId) {
            return null;
        }

        return [`${forms.baseRoute}/pi-sign-off/collection/pi-signoff-batch-id`, forms, piSignOffBatchId];
    }, [forms, piSignOffBatchId])

    const { data, error } = useSWR<PISignOffCollectionResponse, PISignOffCollectionResponse>(cacheKey, fetcher, configuration);

    const boundMutate: BoundMutator<PISignOffCollectionResponse> = useCallback((data, shouldRevalidate) => {
        return mutate(cacheKey, data, shouldRevalidate);
    }, [forms, cacheKey])

    const result = useMemo(() => {
        return {
            data: data?.data,
            error: error?.responseStatus,
            loading: data === undefined && error === undefined,
            mutate: boundMutate
        };
    }, [boundMutate, data, error])

    return result;
}

export default usePISignOffsByPISignOffBatchId;