/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the component that provides the basis for all routes.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a context.
 */
import * as React from 'react';

/*
 * Used to type properties and get access to routes. 
 */
import { RouteProps } from 'react-router';


/*
 * Used to get access to application routing based on paths.
 */
import { Route, useParams, useMatch } from 'react-router-dom';
import { FormDefinitionCollectionResponse, FormTypeCollectionResponse } from '@ngt/forms';
import { SWRConfiguration } from 'swr/dist/types';
import { PISignOffDefinitionSingleResponse, PISignOffSingleResponse, InvestigatorSingleResponse, PISignOffBatchSingleResponse, PISignOffValidationCollectionResponse, InvestigatorCollectionResponse } from '../../api/dtos';
import PISignOffExtensionContext from '../../contexts/PISignOffExtensionContext';
import { useContext } from 'react';
import { CoordinatingGroupSingleResponse, CountrySingleResponse, InstitutionSingleResponse, MasterGroupSingleResponse, PatientSingleResponse, TrialsExtensionContext } from '@ngt/forms-trials';
import PISignOffResolver from '../resolver/PISignOffResolver';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */


/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

/*
 * This interface defines the properties for the OpmsFormByIdRoute component.
 */
export interface IPISignOffRouteProps {
    piSignOffDefinitionCode?: string;
    piSignOffRepeat?: number;
    patientStudyNumber?: string;

    /**
     * This property determines whether the associated data should be loaded before loading the route.
     */
    resolveBeforeLoad?: boolean | null;

    patientConfiguration?: Partial<SWRConfiguration<PatientSingleResponse, PatientSingleResponse>>;
    institutionConfiguration?: Partial<SWRConfiguration<InstitutionSingleResponse, InstitutionSingleResponse>>;
    countryConfiguration?: Partial<SWRConfiguration<CountrySingleResponse, CountrySingleResponse>>;
    coordinatingGroupConfiguration?: Partial<SWRConfiguration<CoordinatingGroupSingleResponse, CoordinatingGroupSingleResponse>>;
    masterGroupConfiguration?: Partial<SWRConfiguration<MasterGroupSingleResponse, MasterGroupSingleResponse>>;
    piSignOffBatchConfiguration?: Partial<SWRConfiguration<PISignOffBatchSingleResponse, PISignOffBatchSingleResponse>>;
    piSignOffConfiguration?: Partial<SWRConfiguration<PISignOffSingleResponse, PISignOffSingleResponse>>;
    formDefinitionsConfiguration?: Partial<SWRConfiguration<FormDefinitionCollectionResponse, FormDefinitionCollectionResponse>>;
    investigatorConfiguration?: Partial<SWRConfiguration<InvestigatorSingleResponse, InvestigatorSingleResponse>>;
    piSignOffDefinitionConfiguration?: Partial<SWRConfiguration<PISignOffDefinitionSingleResponse, PISignOffDefinitionSingleResponse>>;
    formTypesConfiguration?: Partial<SWRConfiguration<FormTypeCollectionResponse, FormTypeCollectionResponse>>;
    piSignOffFormValidationsConfiguration?: Partial<SWRConfiguration<PISignOffValidationCollectionResponse, PISignOffValidationCollectionResponse>>;
    investigatorsConfiguration?: Partial<SWRConfiguration<InvestigatorCollectionResponse, InvestigatorCollectionResponse>>;
}

/*
 * ---------------------------------------------------------------------------------
 * Components
 * ---------------------------------------------------------------------------------
 */

/**
 * This component provides context for the form management system.
 * @param param0 component properties.
 */
const PISignOffRoute: React.FunctionComponent<IPISignOffRouteProps> = ({
    piSignOffDefinitionCode,
    piSignOffRepeat,
    patientStudyNumber,
    resolveBeforeLoad,
    children,

    patientConfiguration,
    institutionConfiguration,
    countryConfiguration,
    coordinatingGroupConfiguration,
    masterGroupConfiguration,
    piSignOffBatchConfiguration,
    piSignOffConfiguration,
    piSignOffDefinitionConfiguration,
    investigatorConfiguration,
    formDefinitionsConfiguration,
    formTypesConfiguration,
    piSignOffFormValidationsConfiguration,
    investigatorsConfiguration,

    ...routeProps
}) => {
    const params = useParams<Record<string, string>>();
    const trialsExtensionContext = useContext(TrialsExtensionContext);
    const piSignOffExtensionContext = useContext(PISignOffExtensionContext);
    const paramPISignOffDefinitionCode = params[piSignOffExtensionContext.routeParameters.piSignOffDefinitionCode];
    const paramPISignOffRepeatStr = params[piSignOffExtensionContext.routeParameters.piSignOffRepeat];
    const paramPatientStudyNumber = params[trialsExtensionContext.routeParameters.patientStudyNumber];
    let paramPISignOffRepeat = null;


    try {
        if (paramPISignOffRepeatStr) {
            paramPISignOffRepeat = parseInt(paramPISignOffRepeatStr);
        }
    }
    catch (error) {
        console.error(`Invalid PI Sign-Off Repeat in route: ${paramPISignOffRepeatStr}`)
    }

    return (
        <PISignOffResolver
            piSignOffDefinitionCode={paramPISignOffDefinitionCode ?? piSignOffDefinitionCode ?? null}
            piSignOffRepeat={paramPISignOffRepeat ?? piSignOffRepeat ?? null}
            patientStudyNumber={paramPatientStudyNumber ?? patientStudyNumber ?? ""}
            resolveBeforeLoad={resolveBeforeLoad}
            coordinatingGroupConfiguration={coordinatingGroupConfiguration}
            countryConfiguration={countryConfiguration}
            formDefinitionsConfiguration={formDefinitionsConfiguration}
            formTypesConfiguration={formTypesConfiguration}
            institutionConfiguration={institutionConfiguration}
            masterGroupConfiguration={masterGroupConfiguration}
            piSignOffBatchConfiguration={piSignOffBatchConfiguration}
            piSignOffConfiguration={piSignOffConfiguration}
            piSignOffDefinitionConfiguration={piSignOffDefinitionConfiguration}
            patientConfiguration={patientConfiguration}
            investigatorConfiguration={investigatorConfiguration}
            investigatorsConfiguration={investigatorsConfiguration}
        >
            {children}
        </PISignOffResolver>
    );
}

/*
    * ---------------------------------------------------------------------------------
    * Default Export
    * ---------------------------------------------------------------------------------
    */

export default PISignOffRoute;