/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/*
 * Required to use React components.
 */
import * as React from 'react';
import { useContext, useMemo } from 'react';
import PISignOffExtensionContext from '../../contexts/PISignOffExtensionContext';
import {
    faCircle,
    faMinusCircle,
    faCheckCircle,
    faTimesCircle,
    faQuestionCircle,
    faUserCircle
} from '@fortawesome/pro-duotone-svg-icons';
import { Breadcrumbs, IBreadcrumb, PatientContext } from '@ngt/forms-trials';
import { useParams } from 'react-router-dom';
import usePISignOffBatchById from '../../hooks/data/usePISignOffBatchById';
import usePISignOffDefinitions from '../../hooks/configuration/usePISignOffDefinitions';
import { DateTime } from 'luxon';
import { PISignOffStatus } from '../../api/dtos';
import PISignOffDefinitionContext from '../../contexts/configuration/PISignOffDefinitionContext';
import PISignOffBatchContext from '../../contexts/data/PISignOffBatchContext';
import PISignOffContext from '../../contexts/data/PISignOffContext';


/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

/**
 * This interface defines the properties for the PISignOffBreadcrumbs component.
 */
export interface IContextPISignOffBreadcrumbsProps {
}


/*
 * ---------------------------------------------------------------------------------
 * Components
 * ---------------------------------------------------------------------------------
 */

/**
 * This component provides context for the patient management system.
 * @param param0 component properties.
 */
const ContextPISignOffBreadcrumbs: React.FunctionComponent<IContextPISignOffBreadcrumbsProps> = () => {

    const { createPISignOffBatchRouteFn, createPISignOffRouteFn } = useContext(PISignOffExtensionContext);
    const { data: piSignOffBatch, loading: piSignOffBatchLoading } = useContext(PISignOffBatchContext);
    const { data: piSignOffDefinition, loading: piSignOffDefinitionLoading } = useContext(PISignOffDefinitionContext);
    const { data: piSignOff, loading: piSignOffLoading } = useContext(PISignOffContext);
    const { data: patient, loading: patientLoading } = useContext(PatientContext);

    const breadcrumbs = useMemo(() => {
        const b: IBreadcrumb[] = [];

        if (piSignOffBatch && piSignOffDefinition) {

            let icon = faQuestionCircle;

            switch (piSignOffBatch.status) {
                case PISignOffStatus.New:
                    icon = faCircle;
                    break;
                case PISignOffStatus.InProgress:
                    icon = faMinusCircle;
                    break;
                case PISignOffStatus.Complete:
                    icon = faCheckCircle;
                    break;
                case PISignOffStatus.Cancelled:
                    icon = faTimesCircle;
                    break;
                default:
                    icon = faQuestionCircle;
                    break;
            }

            b.push({
                icon: icon,
                label: <>{piSignOffDefinition.name} &nbsp;&ndash;&nbsp; {(piSignOffBatch.enteredDate ? DateTime.fromISO(piSignOffBatch.enteredDate).toFormat('dd/MM/yyyy') : undefined)}</>,
                to: createPISignOffBatchRouteFn(piSignOffBatch, piSignOffDefinition)
            });
        }
        else {
            b.push({
                icon: faQuestionCircle,
                label: 'Unknown',
                to: '#'
            });
        }

        if (piSignOffBatch && patient && piSignOff && piSignOffDefinition) {
            let icon = faQuestionCircle;

            switch (piSignOff.status) {
                case PISignOffStatus.New:
                    icon = faCircle;
                    break;
                case PISignOffStatus.InProgress:
                    icon = faMinusCircle;
                    break;
                case PISignOffStatus.Complete:
                    icon = faCheckCircle;
                    break;
                case PISignOffStatus.Cancelled:
                    icon = faTimesCircle;
                    break;
                default:
                    icon = faQuestionCircle;
                    break;
            }

            b.push({
                icon: faUserCircle,
                label: patient.studyNumber,
                to: createPISignOffRouteFn(piSignOffBatch, piSignOffDefinition, patient, piSignOff)
            });
        }
        else {
            b.push({
                icon: faQuestionCircle,
                label: 'Unknown',
                to: '#'
            });
        }

        return b;
    }, [piSignOffBatch, piSignOffDefinition, piSignOff, patient, createPISignOffRouteFn, createPISignOffBatchRouteFn]);

    return (
        <Breadcrumbs breadcrumbs={breadcrumbs} />
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default ContextPISignOffBreadcrumbs;