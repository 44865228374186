/* Options:
Date: 2023-02-28 12:41:55
Version: 6.10
Tip: To override a DTO option, remove "//" prefix before updating
BaseUrl: https://localhost:44800

//GlobalNamespace: 
//MakePropertiesOptional: False
AddServiceStackTypes: True
//AddResponseStatus: False
//AddImplicitVersion: 
//AddDescriptionAsComments: True
IncludeTypes: PISignOffBatchCreationOptions,PISignOffUpdateOptions,PISignOffRequestContext,PostHasPermissions,NewtonGreen.ServiceStack.PISignOff.Interfaces/*,NewtonGreen.ServiceStack.PISignOff.Enumerations/*,__PISignOff.MasterGroup/*,__PISignOff.CoordinatingGroup/*,__PISignOff.Country/*,__PISignOff.Institution/*,__PISignOff.Patient/*,__PISignOff.PISignOff/*,__PISignOff.PISignOffBatch/*,__PISignOff.UpdatedFormProperty/*,__PISignOff.Investigator/*,__PISignOff.Query/*
//ExcludeTypes: 
DefaultImports: IPatient:@ngt/forms-trials,IHasPatientId:@ngt/forms-trials,IForm:@ngt/forms,IDataModel:@ngt/forms,IReturn:@ngt/forms,IGet:@ngt/forms,IHasFormDefinitionId:@ngt/forms,IHasFormId:@ngt/forms,IPost:@ngt/forms,IConfigurationModel:@ngt/forms,IConfigurationGetSingleById:@ngt/forms,IConfigurationGetCollection:@ngt/forms,IConfigurationGetSingleByCode:@ngt/forms,IHasCode:@ngt/forms,IDataGetSingleById:@ngt/forms,IDataGetCollection:@ngt/forms,ResponseStatus:@ngt/forms,PISignOffBatch:./piSignOffBatch,PISignOff:./piSignOff,UpdatedFormProperty:./updatedFormProperty,ValidationResult:./validationResult,Investigator:./investigator,PISignOffDefinition:./piSignOffDefinition,Query:./query
*/

import { IPatient } from "@ngt/forms-trials";
import { IHasPatientId } from "@ngt/forms-trials";
import { IForm } from "@ngt/forms";
import { IDataModel } from "@ngt/forms";
import { IReturn } from "@ngt/forms";
import { IGet } from "@ngt/forms";
import { IHasFormDefinitionId } from "@ngt/forms";
import { IHasFormId } from "@ngt/forms";
import { IPost } from "@ngt/forms";
import { IConfigurationModel } from "@ngt/forms";
import { IConfigurationGetSingleById } from "@ngt/forms";
import { IConfigurationGetCollection } from "@ngt/forms";
import { IConfigurationGetSingleByCode } from "@ngt/forms";
import { IHasCode } from "@ngt/forms";
import { IDataGetSingleById } from "@ngt/forms";
import { IDataGetCollection } from "@ngt/forms";
import { ResponseStatus } from "@ngt/forms";
import { PISignOffBatch } from "./piSignOffBatch";
import { PISignOff } from "./piSignOff";
import { UpdatedFormProperty } from "./updatedFormProperty";
import { ValidationResult } from "./validationResult";
import { Investigator } from "./investigator";
import { PISignOffDefinition } from "./piSignOffDefinition";
import { Query } from "./query";

export class PISignOffBatchCreationOptions
{
    public formDefinitionIds: number[];
    public completeUnchangedForms?: boolean;
    public compareFormsAcrossPISignOffDefinitions?: boolean;

    public constructor(init?: Partial<PISignOffBatchCreationOptions>) { (Object as any).assign(this, init); }
}

export interface IFormGetSingleByPISignOffIdAndRepeat extends IGet
{
    piSignOffId?: number;
    repeat?: number;
}

export interface IFormPostUpdateById extends IPost
{
    id?: number;
    metadata: { [index: string]: Object; };
}

export class PISignOffRequestContext
{
    public masterGroupId?: number;
    public coordinatingGroupId?: number;
    public countryId?: number;
    public institutionId?: number;
    public patientId?: number;
    public investigatorIds: number[];
    public piSignOffId?: number;
    public investigatorId?: number;
    public formDefinitionId?: number;
    public formId?: number;
    public fileId?: number;
    public queryId?: number;

    public constructor(init?: Partial<PISignOffRequestContext>) { (Object as any).assign(this, init); }
}

export enum PISignOffPermission
{
    Access = 1,
    ViewMasterGroup = 2,
    ViewCoordinatingGroup = 3,
    ViewCountry = 4,
    ViewInstitution = 5,
    ViewPatient = 6,
    ViewPISignOff = 7,
    ViewPISignOffForm = 8,
    ViewQuery = 9,
    CreatePISignOff = 10,
    CompletePISignOff = 11,
    CancelPISignOff = 12,
    CompletePISignOffForm = 13,
    IssueQuery = 14,
    RespondToQuery = 15,
    CloseQuery = 16,
    CancelQuery = 17,
    ViewAllPatients = 18,
    UpdatePISignOff = 19,
}

export interface IPISignOffGetCollectionByPISignOffBatchId extends IGet
{
    piSignOffBatchId?: number;
}

export interface IPISignOffGetSingleByPISignOffBatchIdAndPatientId extends IGet
{
    piSignOffBatchId?: number;
    patientId?: number;
}

export class PISignOffUpdateOptions extends PISignOffBatchCreationOptions
{
    public addNewForms?: boolean;
    public deleteOldForms?: boolean;

    public constructor(init?: Partial<PISignOffUpdateOptions>) { super(init); (Object as any).assign(this, init); }
}

export interface IPISignOffPostUpdateSignOff extends IPost
{
    metadata: { [index: string]: Object; };
    piSignOffId?: number;
    options: PISignOffUpdateOptions;
}

export interface IPISignOffPostFormValidationCollectionById extends IPost
{
    id?: number;
    metadata: { [index: string]: Object; };
}

export interface IPISignOffBatchGetSingleByPISignOffDefinitionIdAndRepeat extends IGet
{
    piSignOffDefinitionId?: number;
    repeat?: number;
}

export interface IQueryGetCollectionByPISignOffId extends IGet
{
    piSignOffId?: number;
}

export interface IUpdatedFormPropertyGetCollectionByFormDefinitionIdAndFormId extends IGet
{
    formDefinitionId?: number;
    formId?: number;
}

export enum PISignOffStatus
{
    New = 1,
    InProgress = 2,
    Complete = 3,
    Cancelled = 4,
    Pending = 5,
    Failed = 6,
}

export interface IPISignOffForm extends IForm, IHasPatientId
{
    piSignOffId?: number;
    status?: PISignOffStatus;
    dataDate?: string;
}

export interface IPISignOffPatient extends IPatient
{
    treatmentArm: string;
    investigatorIds: number[];
}

export enum InvestigatorType
{
    PrincipalInvestigator = 1,
    CoInvestigator = 2,
}

export class InvestigatorContext
{
    public active?: boolean;
    public type?: InvestigatorType;

    public constructor(init?: Partial<InvestigatorContext>) { (Object as any).assign(this, init); }
}

export interface IInvestigator extends IDataModel
{
    name: string;
    usernames: string[];
    email: string;
    onTrial: InvestigatorContext[];
    masterGroups: { [index: number]: InvestigatorContext[]; };
    coordinatingGroups: { [index: number]: InvestigatorContext[]; };
    institutions: { [index: number]: InvestigatorContext[]; };
}

export interface IPISignOff extends IDataModel
{
    patientId?: number;
    piSignOffBatchId?: number;
    formCount?: number;
    investigatorId?: number;
    status?: PISignOffStatus;
    completeDate?: string;
    cancelDate?: string;
    dataDate?: string;
}

export interface IPISignOffBatch extends IDataModel
{
    piSignOffDefinitionId?: number;
    status?: PISignOffStatus;
    repeat?: number;
    count?: number;
    created?: number;
    formDefinitionIds: number[];
    completeDate?: string;
    cancelDate?: string;
}

export enum QueryStatus
{
    Issued = 1,
    Responded = 2,
    Closed = 3,
    Cancelled = 4,
}

export interface IQuery extends IDataModel, IHasFormDefinitionId, IHasFormId
{
    piSignOffId?: number;
    propertyName: string;
    parentQueryId?: number;
    repeat?: number;
    question: string;
    response: string;
    comment: string;
    status?: QueryStatus;
}

export enum UpdatedFormPropertyType
{
    Normal = 1,
    DataChange = 2,
}

export interface IUpdatedFormProperty extends IDataModel
{
    formDefinitionId?: number;
    formId?: number;
    propertyPath: string;
    type?: UpdatedFormPropertyType;
}

export interface IPISignOffDefinition extends IConfigurationModel, IHasCode
{
    name: string;
    availableFormDefinitionIds: number[];
    defaultFormDefinitionIds: number[];
}

export class PostHasPermissionsResponse
{
    public responseStatus: ResponseStatus;
    public permissions: { [index: string]: boolean; };

    public constructor(init?: Partial<PostHasPermissionsResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PISignOffDefinitionSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved pi sign off definition.
    */
    // @DataMember(Order=2)
    public configuration: PISignOffDefinition;

    public constructor(init?: Partial<PISignOffDefinitionSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PISignOffDefinitionCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved pi sign off definitions.
    */
    // @DataMember(Order=2)
    public configuration: PISignOffDefinition[];

    public constructor(init?: Partial<PISignOffDefinitionCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class InvestigatorSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved investigator.
    */
    // @DataMember(Order=2)
    public data: Investigator;

    public constructor(init?: Partial<InvestigatorSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class InvestigatorCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved investigators.
    */
    // @DataMember(Order=2)
    public data: Investigator[];

    public constructor(init?: Partial<InvestigatorCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class InvestigatorValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<InvestigatorValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PISignOffSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved pi sign off.
    */
    // @DataMember(Order=2)
    public data: PISignOff;

    public constructor(init?: Partial<PISignOffSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PISignOffCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved pi sign offs.
    */
    // @DataMember(Order=2)
    public data: PISignOff[];

    public constructor(init?: Partial<PISignOffCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PISignOffValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<PISignOffValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PISignOffValidationCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult[];

    public constructor(init?: Partial<PISignOffValidationCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PISignOffBatchSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved pi sign off batch.
    */
    // @DataMember(Order=2)
    public data: PISignOffBatch;

    public constructor(init?: Partial<PISignOffBatchSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PISignOffBatchCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved pi sign off batchs.
    */
    // @DataMember(Order=2)
    public data: PISignOffBatch[];

    public constructor(init?: Partial<PISignOffBatchCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PISignOffBatchValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<PISignOffBatchValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PISignOffBatchCreationSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved pi sign off batch.
    */
    // @DataMember(Order=2)
    public data: PISignOffBatch;

    public constructor(init?: Partial<PISignOffBatchCreationSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class QuerySingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved query.
    */
    // @DataMember(Order=2)
    public data: Query;

    public constructor(init?: Partial<QuerySingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class QueryCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved querys.
    */
    // @DataMember(Order=2)
    public data: Query[];

    public constructor(init?: Partial<QueryCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class QueryValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<QueryValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class UpdatedFormPropertySingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved updatedformproperty.
    */
    // @DataMember(Order=2)
    public data: UpdatedFormProperty;

    public constructor(init?: Partial<UpdatedFormPropertySingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class UpdatedFormPropertyCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved updatedformpropertys.
    */
    // @DataMember(Order=2)
    public data: UpdatedFormProperty[];

    public constructor(init?: Partial<UpdatedFormPropertyCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class UpdatedFormPropertyValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<UpdatedFormPropertyValidationResponse>) { (Object as any).assign(this, init); }
}

// @Route("/pi-signoff/permissions", "POST")
export class PostHasPermissions implements IReturn<PostHasPermissionsResponse>, IPost
{
    public context: PISignOffRequestContext;
    public permissions: PISignOffPermission[];

    public constructor(init?: Partial<PostHasPermissions>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PostHasPermissions'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PostHasPermissionsResponse(); }
}

/**
* PI Sign-off Definition
*/
// @Route("/pi-signoff/pi-sign-off-definition/single/id/{Id}", "GET")
// @Api(Description="PI Sign-off Definition")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__PISignOff.PISignOffDefinition.PISignOffDefinitionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__PISignOff.PISignOffDefinition.PISignOffDefinitionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__PISignOff.PISignOffDefinition.PISignOffDefinitionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__PISignOff.PISignOffDefinition.PISignOffDefinitionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__PISignOff.PISignOffDefinition.PISignOffDefinitionSingleResponse)", StatusCode=500)
// @DataContract
export class PISignOffDefinitionGetSingleById implements IReturn<PISignOffDefinitionSingleResponse>, IConfigurationGetSingleById
{
    /**
    * The ID of the pi sign off definition to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<PISignOffDefinitionGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PISignOffDefinitionGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PISignOffDefinitionSingleResponse(); }
}

/**
* PI Sign-off Definition
*/
// @Route("/pi-signoff/pi-sign-off-definition/collection", "GET")
// @Api(Description="PI Sign-off Definition")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__PISignOff.PISignOffDefinition.PISignOffDefinitionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__PISignOff.PISignOffDefinition.PISignOffDefinitionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__PISignOff.PISignOffDefinition.PISignOffDefinitionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__PISignOff.PISignOffDefinition.PISignOffDefinitionCollectionResponse)", StatusCode=500)
// @DataContract
export class PISignOffDefinitionGetCollection implements IReturn<PISignOffDefinitionCollectionResponse>, IConfigurationGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<PISignOffDefinitionGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PISignOffDefinitionGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PISignOffDefinitionCollectionResponse(); }
}

/**
* PI Sign-off Definition
*/
// @Route("/pi-signoff/pi-sign-off-definition/single/code/{Code}", "GET")
// @Api(Description="PI Sign-off Definition")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__PISignOff.PISignOffDefinition.PISignOffDefinitionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__PISignOff.PISignOffDefinition.PISignOffDefinitionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__PISignOff.PISignOffDefinition.PISignOffDefinitionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__PISignOff.PISignOffDefinition.PISignOffDefinitionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__PISignOff.PISignOffDefinition.PISignOffDefinitionSingleResponse)", StatusCode=500)
// @DataContract
export class PISignOffDefinitionGetSingleByCode implements IReturn<PISignOffDefinitionSingleResponse>, IConfigurationGetSingleByCode
{
    /**
    * The ID of the pi sign off definition to retrieve.
    */
    // @DataMember(Order=1)
    public code: string;

    public constructor(init?: Partial<PISignOffDefinitionGetSingleByCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PISignOffDefinitionGetSingleByCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PISignOffDefinitionSingleResponse(); }
}

/**
* Investigator
*/
// @Route("/pi-signoff/investigator/single/id/{Id}", "GET")
// @Api(Description="Investigator")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__PISignOff.Investigator.InvestigatorSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__PISignOff.Investigator.InvestigatorSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__PISignOff.Investigator.InvestigatorSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__PISignOff.Investigator.InvestigatorSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__PISignOff.Investigator.InvestigatorSingleResponse)", StatusCode=500)
// @DataContract
export class InvestigatorGetSingleById implements IReturn<InvestigatorSingleResponse>, IDataGetSingleById
{
    /**
    * The ID of the investigator to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<InvestigatorGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'InvestigatorGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new InvestigatorSingleResponse(); }
}

/**
* Investigator
*/
// @Route("/pi-signoff/investigator/collection", "GET")
// @Api(Description="Investigator")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__PISignOff.Investigator.InvestigatorCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__PISignOff.Investigator.InvestigatorCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__PISignOff.Investigator.InvestigatorCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__PISignOff.Investigator.InvestigatorCollectionResponse)", StatusCode=500)
// @DataContract
export class InvestigatorGetCollection implements IReturn<InvestigatorCollectionResponse>, IDataGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<InvestigatorGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'InvestigatorGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new InvestigatorCollectionResponse(); }
}

/**
* Investigator
*/
// @Route("/pi-signoff/investigator/create", "POST")
// @Api(Description="Investigator")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__PISignOff.Investigator.InvestigatorSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__PISignOff.Investigator.InvestigatorSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__PISignOff.Investigator.InvestigatorSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__PISignOff.Investigator.InvestigatorSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__PISignOff.Investigator.InvestigatorSingleResponse)", StatusCode=500)
// @DataContract
export class InvestigatorPostCreate implements IReturn<InvestigatorSingleResponse>
{
    /**
    * The metadata to use when creating the investigator.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the investigator.
    */
    // @DataMember(Order=2)
    public data: Investigator;

    public constructor(init?: Partial<InvestigatorPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'InvestigatorPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new InvestigatorSingleResponse(); }
}

/**
* Investigator
*/
// @Route("/pi-signoff/investigator/save", "POST")
// @Api(Description="Investigator")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__PISignOff.Investigator.InvestigatorSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__PISignOff.Investigator.InvestigatorSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__PISignOff.Investigator.InvestigatorSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__PISignOff.Investigator.InvestigatorSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__PISignOff.Investigator.InvestigatorSingleResponse)", StatusCode=500)
// @DataContract
export class InvestigatorPostSave implements IReturn<InvestigatorSingleResponse>
{
    /**
    * The metadata to use when creating the investigator.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The investigator to save.
    */
    // @DataMember(Order=2)
    public data: Investigator;

    public constructor(init?: Partial<InvestigatorPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'InvestigatorPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new InvestigatorSingleResponse(); }
}

/**
* Investigator
*/
// @Route("/pi-signoff/investigator/validate", "POST")
// @Api(Description="Investigator")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__PISignOff.Investigator.InvestigatorValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__PISignOff.Investigator.InvestigatorValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__PISignOff.Investigator.InvestigatorValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__PISignOff.Investigator.InvestigatorValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__PISignOff.Investigator.InvestigatorValidationResponse)", StatusCode=500)
// @DataContract
export class InvestigatorPostValidate implements IReturn<InvestigatorValidationResponse>
{
    /**
    * The metadata to use when validating the investigator.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The investigator to validate.
    */
    // @DataMember(Order=2)
    public data: Investigator;

    public constructor(init?: Partial<InvestigatorPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'InvestigatorPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new InvestigatorValidationResponse(); }
}

/**
* PI Sign-off
*/
// @Route("/pi-signoff/pi-sign-off/single/id/{Id}", "GET")
// @Api(Description="PI Sign-off")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffSingleResponse)", StatusCode=500)
// @DataContract
export class PISignOffGetSingleById implements IReturn<PISignOffSingleResponse>, IDataGetSingleById
{
    /**
    * The ID of the pi sign off to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<PISignOffGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PISignOffGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PISignOffSingleResponse(); }
}

/**
* PI Sign-off
*/
// @Route("/pi-signoff/pi-sign-off/collection", "GET")
// @Api(Description="PI Sign-off")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffCollectionResponse)", StatusCode=500)
// @DataContract
export class PISignOffGetCollection implements IReturn<PISignOffCollectionResponse>, IDataGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<PISignOffGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PISignOffGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PISignOffCollectionResponse(); }
}

/**
* PI Sign-off
*/
// @Route("/pi-signoff/pi-sign-off/create", "POST")
// @Api(Description="PI Sign-off")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffSingleResponse)", StatusCode=500)
// @DataContract
export class PISignOffPostCreate implements IReturn<PISignOffSingleResponse>
{
    /**
    * The metadata to use when creating the pi sign off.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the pi sign off.
    */
    // @DataMember(Order=2)
    public data: PISignOff;

    public constructor(init?: Partial<PISignOffPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PISignOffPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PISignOffSingleResponse(); }
}

/**
* PI Sign-off
*/
// @Route("/pi-signoff/pi-sign-off/save", "POST")
// @Api(Description="PI Sign-off")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffSingleResponse)", StatusCode=500)
// @DataContract
export class PISignOffPostSave implements IReturn<PISignOffSingleResponse>
{
    /**
    * The metadata to use when creating the pi sign off.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The pi sign off to save.
    */
    // @DataMember(Order=2)
    public data: PISignOff;

    public constructor(init?: Partial<PISignOffPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PISignOffPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PISignOffSingleResponse(); }
}

/**
* PI Sign-off
*/
// @Route("/pi-signoff/pi-sign-off/validate", "POST")
// @Api(Description="PI Sign-off")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffValidationResponse)", StatusCode=500)
// @DataContract
export class PISignOffPostValidate implements IReturn<PISignOffValidationResponse>
{
    /**
    * The metadata to use when validating the pi sign off.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The pi sign off to validate.
    */
    // @DataMember(Order=2)
    public data: PISignOff;

    public constructor(init?: Partial<PISignOffPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PISignOffPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PISignOffValidationResponse(); }
}

/**
* PI Sign-off
*/
// @Route("/pi-signoff/pi-sign-off/collection/pi-signoff-batch-id/{PISignOffBatchId}", "GET")
// @Api(Description="PI Sign-off")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffCollectionResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffCollectionResponse)", StatusCode=500)
// @DataContract
export class PISignOffGetCollectionByPISignOffBatchId implements IReturn<PISignOffCollectionResponse>, IPISignOffGetCollectionByPISignOffBatchId
{
    /**
    * The PI Sign-Off Batch ID of the pi sign off to retrieve.
    */
    // @DataMember(Order=1)
    public piSignOffBatchId?: number;

    public constructor(init?: Partial<PISignOffGetCollectionByPISignOffBatchId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PISignOffGetCollectionByPISignOffBatchId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PISignOffCollectionResponse(); }
}

/**
* PI Sign-off
*/
// @Route("/pi-signoff/pi-sign-off/single/pi-signoff-batch-id-patient-id/{PISignOffBatchId}/{PatientId}", "GET")
// @Api(Description="PI Sign-off")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffSingleResponse)", StatusCode=500)
// @DataContract
export class PISignOffGetSingleByPISignOffBatchIdAndPatientId implements IReturn<PISignOffSingleResponse>, IPISignOffGetSingleByPISignOffBatchIdAndPatientId
{
    /**
    * The ID of the related Patient.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The ID of the related PI Sign-Off Batch.
    */
    // @DataMember(Order=2)
    public piSignOffBatchId?: number;

    public constructor(init?: Partial<PISignOffGetSingleByPISignOffBatchIdAndPatientId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PISignOffGetSingleByPISignOffBatchIdAndPatientId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PISignOffSingleResponse(); }
}

/**
* PI Sign-off
*/
// @Route("/pi-signoff/pi-sign-off/update-signoff", "POST")
// @Api(Description="PI Sign-off")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffSingleResponse)", StatusCode=500)
// @DataContract
export class PISignOffPostUpdateSignOff implements IReturn<PISignOffSingleResponse>, IPISignOffPostUpdateSignOff
{
    /**
    * The metadata to use when creating the pi sign off.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The Id of the pi sign off.
    */
    // @DataMember(Order=2)
    public piSignOffId?: number;

    /**
    * The options to use when creating the pi sign off.
    */
    // @DataMember(Order=2)
    public options: PISignOffUpdateOptions;

    public constructor(init?: Partial<PISignOffPostUpdateSignOff>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PISignOffPostUpdateSignOff'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PISignOffSingleResponse(); }
}

/**
* PI Sign-off
*/
// @Route("/pi-signoff/pi-sign-off/collection/validate-forms", "GET")
// @Api(Description="PI Sign-off")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffValidationCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffValidationCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffValidationCollectionResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffValidationCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffValidationCollectionResponse)", StatusCode=500)
// @DataContract
export class PISignOffPostFormValidationCollectionById implements IReturn<PISignOffValidationCollectionResponse>, IPISignOffPostFormValidationCollectionById
{
    /**
    * The metadata to use when validating the pi sign off forms.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The Id of the pi sign off to medical review.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<PISignOffPostFormValidationCollectionById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PISignOffPostFormValidationCollectionById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PISignOffValidationCollectionResponse(); }
}

/**
* PI Sign-Off Batch
*/
// @Route("/pi-signoff/pi-sign-off-batch/single/id/{Id}", "GET")
// @Api(Description="PI Sign-Off Batch")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__PISignOff.PISignOffBatch.PISignOffBatchSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__PISignOff.PISignOffBatch.PISignOffBatchSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__PISignOff.PISignOffBatch.PISignOffBatchSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__PISignOff.PISignOffBatch.PISignOffBatchSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__PISignOff.PISignOffBatch.PISignOffBatchSingleResponse)", StatusCode=500)
// @DataContract
export class PISignOffBatchGetSingleById implements IReturn<PISignOffBatchSingleResponse>, IDataGetSingleById
{
    /**
    * The ID of the pi sign off batch to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<PISignOffBatchGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PISignOffBatchGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PISignOffBatchSingleResponse(); }
}

/**
* PI Sign-Off Batch
*/
// @Route("/pi-signoff/pi-sign-off-batch/collection", "GET")
// @Api(Description="PI Sign-Off Batch")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__PISignOff.PISignOffBatch.PISignOffBatchCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__PISignOff.PISignOffBatch.PISignOffBatchCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__PISignOff.PISignOffBatch.PISignOffBatchCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__PISignOff.PISignOffBatch.PISignOffBatchCollectionResponse)", StatusCode=500)
// @DataContract
export class PISignOffBatchGetCollection implements IReturn<PISignOffBatchCollectionResponse>, IDataGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<PISignOffBatchGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PISignOffBatchGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PISignOffBatchCollectionResponse(); }
}

/**
* PI Sign-Off Batch
*/
// @Route("/pi-signoff/pi-sign-off-batch/create", "POST")
// @Api(Description="PI Sign-Off Batch")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__PISignOff.PISignOffBatch.PISignOffBatchSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__PISignOff.PISignOffBatch.PISignOffBatchSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__PISignOff.PISignOffBatch.PISignOffBatchSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__PISignOff.PISignOffBatch.PISignOffBatchSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__PISignOff.PISignOffBatch.PISignOffBatchSingleResponse)", StatusCode=500)
// @DataContract
export class PISignOffBatchPostCreate implements IReturn<PISignOffBatchSingleResponse>
{
    /**
    * The metadata to use when creating the pi sign off batch.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the pi sign off batch.
    */
    // @DataMember(Order=2)
    public data: PISignOffBatch;

    public constructor(init?: Partial<PISignOffBatchPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PISignOffBatchPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PISignOffBatchSingleResponse(); }
}

/**
* PI Sign-Off Batch
*/
// @Route("/pi-signoff/pi-sign-off-batch/save", "POST")
// @Api(Description="PI Sign-Off Batch")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__PISignOff.PISignOffBatch.PISignOffBatchSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__PISignOff.PISignOffBatch.PISignOffBatchSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__PISignOff.PISignOffBatch.PISignOffBatchSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__PISignOff.PISignOffBatch.PISignOffBatchSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__PISignOff.PISignOffBatch.PISignOffBatchSingleResponse)", StatusCode=500)
// @DataContract
export class PISignOffBatchPostSave implements IReturn<PISignOffBatchSingleResponse>
{
    /**
    * The metadata to use when creating the pi sign off batch.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The pi sign off batch to save.
    */
    // @DataMember(Order=2)
    public data: PISignOffBatch;

    public constructor(init?: Partial<PISignOffBatchPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PISignOffBatchPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PISignOffBatchSingleResponse(); }
}

/**
* PI Sign-Off Batch
*/
// @Route("/pi-signoff/pi-sign-off-batch/validate", "POST")
// @Api(Description="PI Sign-Off Batch")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__PISignOff.PISignOffBatch.PISignOffBatchValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__PISignOff.PISignOffBatch.PISignOffBatchValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__PISignOff.PISignOffBatch.PISignOffBatchValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__PISignOff.PISignOffBatch.PISignOffBatchValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__PISignOff.PISignOffBatch.PISignOffBatchValidationResponse)", StatusCode=500)
// @DataContract
export class PISignOffBatchPostValidate implements IReturn<PISignOffBatchValidationResponse>
{
    /**
    * The metadata to use when validating the pi sign off batch.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The pi sign off batch to validate.
    */
    // @DataMember(Order=2)
    public data: PISignOffBatch;

    public constructor(init?: Partial<PISignOffBatchPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PISignOffBatchPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PISignOffBatchValidationResponse(); }
}

/**
* PI Sign-Off Batch
*/
// @Route("/pi-signoff/pi-sign-off-batch/create-signoff", "POST")
// @Api(Description="PI Sign-Off Batch")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__PISignOff.PISignOffBatch.PISignOffBatchCreationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__PISignOff.PISignOffBatch.PISignOffBatchCreationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__PISignOff.PISignOffBatch.PISignOffBatchCreationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__PISignOff.PISignOffBatch.PISignOffBatchCreationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__PISignOff.PISignOffBatch.PISignOffBatchCreationSingleResponse)", StatusCode=500)
// @DataContract
export class PISignOffBatchPostCreateSignOff implements IReturn<PISignOffBatchCreationSingleResponse>
{
    /**
    * The metadata to use when creating the pi sign off batch.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the pi sign off batch.
    */
    // @DataMember(Order=2)
    public data: PISignOffBatch;

    /**
    * The options to use when creating the pi sign off batch.
    */
    // @DataMember(Order=2)
    public options: PISignOffBatchCreationOptions;

    public constructor(init?: Partial<PISignOffBatchPostCreateSignOff>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PISignOffBatchPostCreateSignOff'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PISignOffBatchCreationSingleResponse(); }
}

/**
* PI Sign-Off Batch
*/
// @Route("/pi-signoff/pi-sign-off-batch/single/pi-signoff-batch-id-patient-id/{PISignOffDefinitionId}/{Repeat}", "GET")
// @Api(Description="PI Sign-Off Batch")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__PISignOff.PISignOffBatch.PISignOffBatchSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__PISignOff.PISignOffBatch.PISignOffBatchSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__PISignOff.PISignOffBatch.PISignOffBatchSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__PISignOff.PISignOffBatch.PISignOffBatchSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__PISignOff.PISignOffBatch.PISignOffBatchSingleResponse)", StatusCode=500)
// @DataContract
export class PISignOffBatchGetSingleByPISignOffDefinitionIdAndRepeat implements IReturn<PISignOffBatchSingleResponse>, IPISignOffBatchGetSingleByPISignOffDefinitionIdAndRepeat
{
    /**
    * The Definition ID of the PI Sign-Off Batch.
    */
    // @DataMember(Order=1)
    public piSignOffDefinitionId?: number;

    /**
    * The Repeat of the related PI Sign-Off Batch.
    */
    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<PISignOffBatchGetSingleByPISignOffDefinitionIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PISignOffBatchGetSingleByPISignOffDefinitionIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PISignOffBatchSingleResponse(); }
}

/**
* Query
*/
// @Route("/pi-signoff/query/single/id/{Id}", "GET")
// @Api(Description="Query")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__PISignOff.Query.QuerySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__PISignOff.Query.QuerySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__PISignOff.Query.QuerySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__PISignOff.Query.QuerySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__PISignOff.Query.QuerySingleResponse)", StatusCode=500)
// @DataContract
export class QueryGetSingleById implements IReturn<QuerySingleResponse>, IDataGetSingleById
{
    /**
    * The ID of the query to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<QueryGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'QueryGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QuerySingleResponse(); }
}

/**
* Query
*/
// @Route("/pi-signoff/query/collection", "GET")
// @Api(Description="Query")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__PISignOff.Query.QueryCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__PISignOff.Query.QueryCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__PISignOff.Query.QueryCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__PISignOff.Query.QueryCollectionResponse)", StatusCode=500)
// @DataContract
export class QueryGetCollection implements IReturn<QueryCollectionResponse>, IDataGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<QueryGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'QueryGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryCollectionResponse(); }
}

/**
* Query
*/
// @Route("/pi-signoff/query/create", "POST")
// @Api(Description="Query")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__PISignOff.Query.QuerySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__PISignOff.Query.QuerySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__PISignOff.Query.QuerySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__PISignOff.Query.QuerySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__PISignOff.Query.QuerySingleResponse)", StatusCode=500)
// @DataContract
export class QueryPostCreate implements IReturn<QuerySingleResponse>
{
    /**
    * The metadata to use when creating the query.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the query.
    */
    // @DataMember(Order=2)
    public data: Query;

    public constructor(init?: Partial<QueryPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'QueryPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QuerySingleResponse(); }
}

/**
* Query
*/
// @Route("/pi-signoff/query/save", "POST")
// @Api(Description="Query")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__PISignOff.Query.QuerySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__PISignOff.Query.QuerySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__PISignOff.Query.QuerySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__PISignOff.Query.QuerySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__PISignOff.Query.QuerySingleResponse)", StatusCode=500)
// @DataContract
export class QueryPostSave implements IReturn<QuerySingleResponse>
{
    /**
    * The metadata to use when creating the query.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The query to save.
    */
    // @DataMember(Order=2)
    public data: Query;

    public constructor(init?: Partial<QueryPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'QueryPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new QuerySingleResponse(); }
}

/**
* Query
*/
// @Route("/pi-signoff/query/validate", "POST")
// @Api(Description="Query")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__PISignOff.Query.QueryValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__PISignOff.Query.QueryValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__PISignOff.Query.QueryValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__PISignOff.Query.QueryValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__PISignOff.Query.QueryValidationResponse)", StatusCode=500)
// @DataContract
export class QueryPostValidate implements IReturn<QueryValidationResponse>
{
    /**
    * The metadata to use when validating the query.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The query to validate.
    */
    // @DataMember(Order=2)
    public data: Query;

    public constructor(init?: Partial<QueryPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'QueryPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new QueryValidationResponse(); }
}

/**
* Query
*/
// @Route("/pi-signoff/query/collection/pi-signoff-id/{PISignOffId}", "GET")
// @Api(Description="Query")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__PISignOff.Query.QueryCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__PISignOff.Query.QueryCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__PISignOff.Query.QueryCollectionResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__PISignOff.Query.QueryCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__PISignOff.Query.QueryCollectionResponse)", StatusCode=500)
// @DataContract
export class QueryGetCollectionByPISignOffId implements IReturn<QueryCollectionResponse>, IQueryGetCollectionByPISignOffId
{
    /**
    * The ID of the PI sign-off to retrieve query for.
    */
    // @DataMember(Order=1)
    public piSignOffId?: number;

    public constructor(init?: Partial<QueryGetCollectionByPISignOffId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'QueryGetCollectionByPISignOffId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryCollectionResponse(); }
}

/**
* Query
*/
// @Route("/pi-signoff/query/save/collection", "POST")
// @Api(Description="Query")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__PISignOff.Query.QueryCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__PISignOff.Query.QueryCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__PISignOff.Query.QueryCollectionResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__PISignOff.Query.QueryCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__PISignOff.Query.QueryCollectionResponse)", StatusCode=500)
// @DataContract
export class QueryPostSaveCollection implements IReturn<QueryCollectionResponse>
{
    /**
    * The metadata to use when creating the query.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The query to save.
    */
    // @DataMember(Order=2)
    public data: Query[];

    public constructor(init?: Partial<QueryPostSaveCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'QueryPostSaveCollection'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new QueryCollectionResponse(); }
}

/**
* UpdatedFormProperty
*/
// @Route("/pi-signoff/updatedformproperty/single/id/{Id}", "GET")
// @Api(Description="UpdatedFormProperty")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__PISignOff.UpdatedFormProperty.UpdatedFormPropertySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__PISignOff.UpdatedFormProperty.UpdatedFormPropertySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__PISignOff.UpdatedFormProperty.UpdatedFormPropertySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__PISignOff.UpdatedFormProperty.UpdatedFormPropertySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__PISignOff.UpdatedFormProperty.UpdatedFormPropertySingleResponse)", StatusCode=500)
// @DataContract
export class UpdatedFormPropertyGetSingleById implements IReturn<UpdatedFormPropertySingleResponse>, IDataGetSingleById
{
    /**
    * The ID of the updatedformproperty to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<UpdatedFormPropertyGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdatedFormPropertyGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new UpdatedFormPropertySingleResponse(); }
}

/**
* UpdatedFormProperty
*/
// @Route("/pi-signoff/updatedformproperty/collection", "GET")
// @Api(Description="UpdatedFormProperty")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__PISignOff.UpdatedFormProperty.UpdatedFormPropertyCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__PISignOff.UpdatedFormProperty.UpdatedFormPropertyCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__PISignOff.UpdatedFormProperty.UpdatedFormPropertyCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__PISignOff.UpdatedFormProperty.UpdatedFormPropertyCollectionResponse)", StatusCode=500)
// @DataContract
export class UpdatedFormPropertyGetCollection implements IReturn<UpdatedFormPropertyCollectionResponse>, IDataGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<UpdatedFormPropertyGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdatedFormPropertyGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new UpdatedFormPropertyCollectionResponse(); }
}

/**
* UpdatedFormProperty
*/
// @Route("/pi-signoff/updatedformproperty/create", "POST")
// @Api(Description="UpdatedFormProperty")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__PISignOff.UpdatedFormProperty.UpdatedFormPropertySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__PISignOff.UpdatedFormProperty.UpdatedFormPropertySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__PISignOff.UpdatedFormProperty.UpdatedFormPropertySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__PISignOff.UpdatedFormProperty.UpdatedFormPropertySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__PISignOff.UpdatedFormProperty.UpdatedFormPropertySingleResponse)", StatusCode=500)
// @DataContract
export class UpdatedFormPropertyPostCreate implements IReturn<UpdatedFormPropertySingleResponse>
{
    /**
    * The metadata to use when creating the updatedformproperty.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the updatedformproperty.
    */
    // @DataMember(Order=2)
    public data: UpdatedFormProperty;

    public constructor(init?: Partial<UpdatedFormPropertyPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdatedFormPropertyPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new UpdatedFormPropertySingleResponse(); }
}

/**
* UpdatedFormProperty
*/
// @Route("/pi-signoff/updatedformproperty/save", "POST")
// @Api(Description="UpdatedFormProperty")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__PISignOff.UpdatedFormProperty.UpdatedFormPropertySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__PISignOff.UpdatedFormProperty.UpdatedFormPropertySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__PISignOff.UpdatedFormProperty.UpdatedFormPropertySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__PISignOff.UpdatedFormProperty.UpdatedFormPropertySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__PISignOff.UpdatedFormProperty.UpdatedFormPropertySingleResponse)", StatusCode=500)
// @DataContract
export class UpdatedFormPropertyPostSave implements IReturn<UpdatedFormPropertySingleResponse>
{
    /**
    * The metadata to use when creating the updatedformproperty.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The updatedformproperty to save.
    */
    // @DataMember(Order=2)
    public data: UpdatedFormProperty;

    public constructor(init?: Partial<UpdatedFormPropertyPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdatedFormPropertyPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new UpdatedFormPropertySingleResponse(); }
}

/**
* UpdatedFormProperty
*/
// @Route("/pi-signoff/updatedformproperty/validate", "POST")
// @Api(Description="UpdatedFormProperty")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__PISignOff.UpdatedFormProperty.UpdatedFormPropertyValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__PISignOff.UpdatedFormProperty.UpdatedFormPropertyValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__PISignOff.UpdatedFormProperty.UpdatedFormPropertyValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__PISignOff.UpdatedFormProperty.UpdatedFormPropertyValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__PISignOff.UpdatedFormProperty.UpdatedFormPropertyValidationResponse)", StatusCode=500)
// @DataContract
export class UpdatedFormPropertyPostValidate implements IReturn<UpdatedFormPropertyValidationResponse>
{
    /**
    * The metadata to use when validating the updatedformproperty.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The updatedformproperty to validate.
    */
    // @DataMember(Order=2)
    public data: UpdatedFormProperty;

    public constructor(init?: Partial<UpdatedFormPropertyPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdatedFormPropertyPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new UpdatedFormPropertyValidationResponse(); }
}

/**
* UpdatedFormProperty
*/
// @Route("/pi-signoff/updatedformproperty/collection/form-definition-id-form-id/{FormDefinitionId}/{FormId}", "GET")
// @Api(Description="UpdatedFormProperty")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__PISignOff.UpdatedFormProperty.UpdatedFormPropertyCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__PISignOff.UpdatedFormProperty.UpdatedFormPropertyCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__PISignOff.UpdatedFormProperty.UpdatedFormPropertyCollectionResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__PISignOff.UpdatedFormProperty.UpdatedFormPropertyCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__PISignOff.UpdatedFormProperty.UpdatedFormPropertyCollectionResponse)", StatusCode=500)
// @DataContract
export class UpdatedFormPropertyGetCollectionByFormDefinitionIdAndFormId implements IReturn<UpdatedFormPropertyCollectionResponse>, IUpdatedFormPropertyGetCollectionByFormDefinitionIdAndFormId
{
    /**
    * The Form Definition ID of the updatedformproperty to retrieve.
    */
    // @DataMember(Order=1)
    public formDefinitionId?: number;

    /**
    * The Form ID of the updatedformproperty to retrieve.
    */
    // @DataMember(Order=1)
    public formId?: number;

    public constructor(init?: Partial<UpdatedFormPropertyGetCollectionByFormDefinitionIdAndFormId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdatedFormPropertyGetCollectionByFormDefinitionIdAndFormId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new UpdatedFormPropertyCollectionResponse(); }
}

