import { faCheckCircle, faCircle, faCommentAltLines, faExclamationCircle, faMinusCircle, faTimesCircle } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { darken, lighten, ListItem, ListItemButton, ListItemIcon, ListItemText, Theme, Tooltip, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { FunctionComponent, useContext, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { IFormDefinition, IFormType, IValidationResult } from '@ngt/forms';
import { IPISignOff, IPISignOffBatch, IPISignOffDefinition, PISignOffStatus } from '../../api/dtos';
import PISignOffExtensionContext from '../../contexts/PISignOffExtensionContext';
import { IPatient } from '@ngt/forms-trials';

interface IPISignOffSummaryListFormProps {
    patient?: IPatient;
    piSignOffDefinition?: IPISignOffDefinition;
    piSignOff?: IPISignOff;
    piSignOffBatch?: IPISignOffBatch;
    //reviewer?: IReviewer;
    formType: IFormType;
    formDefinition: IFormDefinition;
    formValidation: IValidationResult,
    hasMultiple: boolean;
}

const useStyles = makeStyles((theme: Theme) => {
    const getColor = theme.palette.mode === 'light' ? darken : lighten;
    const getBackgroundColor = theme.palette.mode === 'light' ? lighten : darken;
    const getBorderColor = theme.palette.mode === 'light' ? lighten : darken;

    return {
        item: {
            paddingTop: 0,
            paddingBottom: 0,
            paddingRight: 0,
            marginTop: -1,
            borderLeft: `1px solid ${theme.palette.grey[300]}`,
            borderTop: `1px solid ${theme.palette.grey[300]}`,
            borderBottom: `1px solid ${theme.palette.grey[300]}`
        } as any,
        icon: {
            borderRight: `1px solid ${theme.palette.grey[300]}`,
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(1)
        },
        text: {
            margin: 0,
            paddingLeft: theme.spacing(2)
        },
        title: {
            display: 'flex',
            alignItems: 'center'
        },
        titleMain: {
            marginRight: 'auto'
        },
        titleCount: {
            alignSelf: 'stretch',
            minWidth: 140,
            display: 'flex',
            padding: theme.spacing(0, 2),
            borderWidth: 1,
            borderStyle: 'solid',
            justifyContent: 'space-between',

            borderRadius: 0,

            color: getColor(theme.palette.grey[700], 0.6),
            backgroundColor: getBackgroundColor(theme.palette.grey[700], 0.9),
            borderColor: getBorderColor(theme.palette.grey[700], 0.8),
        },
        new: {
            color: theme.palette.info.main
        },
        inProgress: {
            color: theme.palette.warning.main
        },
        complete: {
            color: theme.palette.success.main
        },
        cancelled: {
            color: theme.palette.grey[500]
        },
        unknown: {
            color: theme.palette.error.main
        },
        queryIcon: {
            maxWidth: 40,
            minWidth: 40,
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
        },
        divider: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
        },
        queryCount: {
            maxWidth: 40,
            minWidth: 40,
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
        },
    }
});


const PISignOffSummaryListForm: FunctionComponent<IPISignOffSummaryListFormProps> = ({
    patient,
    piSignOff,
    formDefinition,
    formType,
    formValidation,
    hasMultiple,
    piSignOffDefinition,
    piSignOffBatch
    //reviewer
}) => {
    const classes = useStyles();

    const piSignOffContext = useContext(PISignOffExtensionContext);

    const title = useMemo(() => {
        const repeat = formValidation.metadata?.Repeat

        return (
            <div className={classes.title}>
                <Typography variant="subtitle1" className={classes.titleMain}>
                    {formDefinition?.name} {hasMultiple && !!repeat && `(${repeat})`}
                </Typography>
            </div>
        )
    }, [formDefinition, formValidation, hasMultiple, classes])

    const icon = useMemo(() => {
        switch (formValidation.metadata?.Status) {
            case PISignOffStatus.New:
                return <Tooltip title="New">
                    <div>
                        <FontAwesomeIcon fixedWidth icon={faCircle} className={classes.new} size="2x" />
                    </div>
                </Tooltip>;
            case PISignOffStatus.InProgress:
                return <Tooltip title="In Progress">
                    <div>
                        <FontAwesomeIcon fixedWidth icon={faMinusCircle} className={classes.inProgress} size="2x" />
                    </div>
                </Tooltip>;
            case PISignOffStatus.Complete:
                return <Tooltip title="Complete">
                    <div>
                        <FontAwesomeIcon fixedWidth icon={faCheckCircle} className={classes.complete} size="2x" />
                    </div>
                </Tooltip>;
            case PISignOffStatus.Cancelled:
                return <Tooltip title="Cancelled">
                    <div>
                        <FontAwesomeIcon fixedWidth icon={faTimesCircle} className={classes.cancelled} size="2x" />
                    </div>
                </Tooltip>;
            default:
                return <Tooltip title="Unknown">
                    <div>
                        <FontAwesomeIcon fixedWidth icon={faExclamationCircle} className={classes.unknown} size="2x" />
                    </div>
                </Tooltip>;
        }
    }, [formValidation.metadata?.Status, classes])

    const queries = useMemo(() => {
        //const total = formValidation.metadata?.Queries ?? 0;
        const issued = formValidation.metadata?.IssuedQueries ?? 0;
        //const responded = formValidation.metadata?.RespondedQueries ?? 0;
        //const closed = formValidation.metadata?.ClosedQueries ?? 0;

        if (issued === 0) {
            return null;
        }

        return (
            <div className={classes.titleCount}>
                <div className={classes.queryIcon}>
                    <span>
                        <FontAwesomeIcon icon={faCommentAltLines} size="lg" />
                    </span>
                </div>
                <div className={classes.divider}>
                </div>
                <div className={classes.queryCount}>
                    {issued}
                </div>
            </div>
        );
    }, [formValidation.metadata, classes])

    return (
        <ListItemButton
            className={classes.item}
            component={Link}
            to={piSignOffContext?.createPISignOffFormRouteFn(piSignOffBatch!, piSignOffDefinition!, patient!, piSignOff!, formDefinition!, formValidation!)}
        >
            <ListItemIcon
                className={classes.icon}
            >
                {icon}
            </ListItemIcon>
            <ListItemText
                className={classes.text}
                primary={title}
            />
            {queries}
        </ListItemButton>
    );
};

export default PISignOffSummaryListForm;
